<template>
    <div v-if="item">
        {{ convertDate(item) }}

    </div>
</template>

<script>
import * as moment from "moment";
export default {
    data: () => ({
        group: null,
        item: null,
    }),
    mounted() {
        this.item = this.params.value;
        if (this.params.node && this.params.node.group && this.params.node.key) {
            this.group = this.params.value;
        }
    },
    methods: {
        convertDate(date) {
            return moment(date).format("DD/MM/YYYY HH:mm");
        },
    }
};
</script>

<style></style>