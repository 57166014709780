<template>
  <div>
    <v-card flat style="background-color: var(--v-background-base) !important; height: calc(100vh - 25px);">
      <v-toolbar
        :class="
          !$vuetify.breakpoint.mobile ? 'toolbarHead' : 'toolbarHeadMobile'
        "
        color="greyBase"
        dark
        flat
        dense
        height="30px"
      >
        <v-toolbar-title
          v-if="!$vuetify.breakpoint.mobile"
          style="font-size: 16px"
        >
          <b class="mr-3">Bookings</b>
        </v-toolbar-title>
      </v-toolbar>
      <v-tabs height="30px" v-model="currentTab" class="tabby">
        <v-tab
          v-for="tab in tabs"
          :key="tab.value"
          :value="tab.value"
          :href="`#${tab.value}`"
          style="font-size: 12px"
        >
          <v-icon small color="grey" class="mr-2">{{ tab.icon }}</v-icon>
          <span>{{ tab.name }}</span>
          <v-chip class="ml-2" small v-if="tab.value == 'bookingRequests'">{{
            bookingRequestCount.count
          }}</v-chip>
        </v-tab>
      </v-tabs>
      <div class="contents">
        <div class="mainContentWrap">
          <div class="mainContent">
            <v-tabs-items
              v-model="currentTab"
              style="height: 100%; width: 100%"
            >
              <v-tab-item
                style="height: 100%; width: 100%"
                value="bookings"
                class="px-0"
              >
                <Bookings />
              </v-tab-item>
              <v-tab-item
                style="height: 100%; width: 100%"
                value="intermodal"
                class="px-1"
              >
                <InterModal/>
              </v-tab-item>
              <v-tab-item
                style="height: 100%; width: 100%"
                value="files"
                class="px-1"
              >
                <Files />
              </v-tab-item>
              <v-tab-item
                style="height: inherit; width: 100%"
                value="bookingRequests"
                class="px-1"
              >
                <BookingRequest />
              </v-tab-item>
              <v-tab-item
                style="height: inherit; width: 100%"
                value="containers"
                class="px-1"
              >
                <Containers />
              </v-tab-item>
              <v-tab-item
                style="height: inherit; width: 100%"
                value="exceptions"
                class="px-1"
              >
                <Exceptions />
              </v-tab-item>
            </v-tabs-items>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import Bookings from "../components/BookingTables/Bookings.vue";
import InterModal from "../components/BookingTables/InterModal.vue";
import BookingRequest from "../components/BookingTables/BookingRequests.vue";
import Containers from "../components/BookingTables/Containers.vue";
import Exceptions from "../components/BookingTables/Exceptions.vue";
import Files from "../components/BookingTables/Files.vue";
export default {
  components: {
    Bookings,
    InterModal,
    BookingRequest,
    Containers,
    Exceptions,
    Files,
  },
  data: () => ({
    bookingRequestCount: {
      count: 0,
    },
    currentTab: "bookings",
    tabs: [
      { name: "Bookings", value: "bookings", icon: "confirmation_number" },
      { name: "Intermodal(New)", value: "intermodal", icon: "confirmation_number" },
      { name: "Booking Requests", value: "bookingRequests", icon: "verified" },
      { name: "Containers", value: "containers", icon: "widgets" },
      { name: "Files", value: "files", icon: "description" },
      { name: "Exceptions", value: "exceptions", icon: "warning" },
    ],
    toggleView: 0,
  }),
  watch: {
    currentTab(newVal) {
      if (newVal) {
        window.location.hash = `#${newVal}`;
      }
    },
  },
  async created() {},
  async mounted() {
    this.bookingRequestCount = await this.$API.getBookingRequestCount();
    if (window.location.hash) {
            let component = decodeURI(window.location.hash).split('#')[1];
            this.currentTab = component
        } else {
            this.$router.push({
                hash: '#bookings'
            })
        }
  },
  methods: {},
};
</script>
<style scoped>
* {
  --gutters: 0.1rem;
  --toolBarHeight: 36px;
  --tabHeight: 30px;
  --darkLayer: var(--v-greyDarker-base) !important;
}
.mainContentTabs {
  height: var(--tabHeight) !important;
}
.mainContent {
  height: calc(100vh - 66px);
  width: 100%;
}
.contents {
  display: flex;
  width: 100%;
  height: calc(100vh - var(--toolBarHeight) - 55px);
  background: var(--v-greyRaised-base) !important;
}
.mainContentWrap {
  background: var(--v-greyRaised-base) !important;
  height: 100%;
  min-height: 100%;
  width: 100%;
  min-width: 50%;
  max-width: 100%;
  position: relative;
}
.tabby ::v-deep.theme--dark.v-tabs .v-tabs-bar,
.tabby ::v-deep .v-tabs-bar__content,
.tabby ::v-deep .v-tab {
  background: var(--v-greyDarker-base) !important;
  border-radius: 0px;
  height: var(--tabHeight) !important;
}
</style>
