<template>
    <div v-if="items && items.length > 0">
      <v-btn
        icon
        outlined
        x-small
        :color="item.color"
        class="mx-1"
        v-for="(item, index) in items"
        :key="index"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon x-small v-on="on">{{ item.icon }}</v-icon>
          </template>
          <span style="white-space: pre-line"
            >{{ item.name }}
          </span>
        </v-tooltip>
      </v-btn>
    </div>
  </template>
      
      <script>
  export default {
    data: () => ({
      items: null,
      group: null
    }),
    mounted() {
      this.items = this.params.value;
      if (this.params.node && this.params.node.group && this.params.node.key) {
        this.group = this.params.value;
      }
    },
    methods: {
      getTextValue(status) {
        if (status) {
          let statusText = this.temporaryProducts.find(
            (x) => x.text.toLowerCase() === status.toLowerCase()
          );
          return statusText ? statusText.text : status;
        } else {
          return status;
        }
      },
      statusColor(status) {
        if (status) {
          let color = this.temporaryProducts.find(
            (x) => x.text.toLowerCase() === status.toLowerCase()
          );
          return color ? color.color : "grey";
        } else {
          return "grey";
        }
      },
    },
  };
  </script>
      
      <style>
  </style>