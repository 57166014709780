<template>
  <div style="height: 100%">
    <v-card
      style="
        background: rgba(0, 0, 0, 0.5) !important;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(20px);
        -webkit-backdrop-filter: blur(20px);
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 0px;
        height: 100%;
        max-height: 100%;
        overflow-y: auto;
        width: 100%;
      "
    >
      <v-toolbar flat dense>
        <v-toolbar-title> Create Booking </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-menu
          v-if="
            (booking.movementType === 'LOCAL' &&
              (!booking.etd || !booking.eta || !booking.customerId)) ||
            (booking.movementType !== 'LOCAL' &&
              (!booking.customerId ||
                !booking.etd ||
                !booking.contractPartyId ||
                (booking.modeOfTransport == 'OCEAN' && !booking.vesselId) ||
                (booking.bookingContainerItems.length > 0 &&
                  booking.bookingContainerItems.some(
                    (container) =>
                      !container.isCarrierHaulage &&
                      !container.isRailage &&
                      (!container.transportCoordinator ||
                        !container.transportCoordinator.name)
                  ))))
          "
          open-on-hover
          bottom
          left
          offset-x
        >
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon color="grey">
              <v-icon small>save</v-icon>
            </v-btn>
          </template>
          <v-list
            dense
            subheader
            style="
              background: var(--v-component-base) !important;
              background-color: var(--v-component-base) !important;
            "
          >
            <v-subheader style="font-size: 14px">Validation Errors</v-subheader>
            <v-divider></v-divider>
            <v-list-item v-if="!booking.customerId" style="height: 30px">
              <v-list-item-action class="mr-1">
                <v-icon small color="redPop">fiber_manual_record</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title style="font-size: 12px"
                  >Customer is required</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="!booking.etd" style="height: 30px">
              <v-list-item-action class="mr-1">
                <v-icon small color="redPop">fiber_manual_record</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title style="font-size: 12px"
                  >ETD is required</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="!booking.eta" style="height: 30px">
              <v-list-item-action class="mr-1">
                <v-icon small color="redPop">fiber_manual_record</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title style="font-size: 12px"
                  >ETA is required</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              style="height: 30px"
              v-if="
                booking.movementType != 'LOCAL' &&
                booking.modeOfTransport == 'OCEAN' &&
                !booking.vesselId
              "
            >
              <v-list-item-action class="mr-1">
                <v-icon small color="redPop">fiber_manual_record</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title style="font-size: 12px"
                  >Vessel is required</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              style="height: 30px"
              v-if="booking.movementType != 'LOCAL' && !booking.contractPartyId"
            >
              <v-list-item-action class="mr-1">
                <v-icon small color="redPop">fiber_manual_record</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title style="font-size: 12px"
                  >Contract Owner is required</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              style="height: 30px"
              v-if="
                booking.movementType != 'LOCAL' &&
                booking.bookingContainerItems &&
                booking.bookingContainerItems.length > 0 &&
                booking.bookingContainerItems.some(
                  (container) =>
                    !container.isCarrierHaulage &&
                    !container.isRailage &&
                    (!container.transportCoordinator ||
                      !container.transportCoordinator.name)
                )
              "
            >
              <v-list-item-action class="mr-1">
                <v-icon small color="redPop">fiber_manual_record</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title style="font-size: 12px"
                  >Container(s) missing transporter
                  coordinator</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          v-else
          icon
          @click="saveBooking()"
          :loading="savingBooking"
          color="primary"
          ><v-icon small>save</v-icon></v-btn
        >

        <v-btn icon @click="$emit('close')" color="redPop">
          <v-icon small>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <!-- <v-container style="width: 100vw" class="mt-1 py-0"> -->
        <v-row style="width: 100%" class="pa-1">
          <v-col cols="12" sm="4" md="3" lg="2" class="my-0 py-0">
            <v-list
              dense
              subheader
              class="my-0 py-0"
              style="height: 45vh; overflow-y: auto"
            >
              <v-divider></v-divider>
              <v-subheader style="font-size: 14px">
                <v-icon class="mr-2" small>settings</v-icon>Configuration
              </v-subheader>
              <v-divider></v-divider>
              <v-list-item style="height: 30px" class="mx-0 px-0">
                <v-list-item-action class="mx-0">
                  <v-icon small>import_export</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 13px"
                    >Movement Type</v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-action>
                  <v-menu offset-y rounded>
                    <template v-slot:activator="{ on }">
                      <v-chip v-on="on" small outlined label>
                        <v-icon
                          small
                          left
                          :color="
                            booking.movementType == 'EXPORT'
                              ? 'deep-orange'
                              : booking.movementType == 'IMPORT'
                              ? 'blue darken-2'
                              : 'green darken-2'
                          "
                          >import_export</v-icon
                        >
                        <span style="text-transform: none">
                          {{
                            $Format.capitalizeFirstLetter(booking.movementType)
                          }}
                        </span>
                        <v-icon small right>expand_more</v-icon>
                      </v-chip>
                    </template>
                    <v-list dense subheader>
                      <v-list-item @click="booking.movementType = 'EXPORT'">
                        <v-list-item-content>
                          <v-list-item-title>Export</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item @click="booking.movementType = 'IMPORT'">
                        <v-list-item-content>
                          <v-list-item-title>Import</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item
                        @click="
                          (booking.movementType = 'LOCAL'),
                            (booking.modeOfTransport = 'ROAD')
                        "
                      >
                        <v-list-item-content>
                          <v-list-item-title>Local</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-list-item-action>
              </v-list-item>
              <v-list-item style="height: 30px" class="mx-0 px-0">
                <v-list-item-action class="mx-0">
                  <v-icon small>airline_stops</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 13px"
                    >Cargo Service</v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-action>
                  <v-menu offset-y rounded>
                    <template v-slot:activator="{ on }">
                      <v-chip v-on="on" small outlined label>
                        <v-icon small left color="indigo">import_export</v-icon>
                        <span style="text-transform: none">
                          {{ booking.serviceType }}
                        </span>
                        <v-icon small right>expand_more</v-icon>
                      </v-chip>
                    </template>
                    <v-list dense subheader>
                      <v-list-item @click="booking.serviceType = 'FCL'">
                        <v-list-item-content>
                          <v-list-item-title>FCL</v-list-item-title>
                          <v-list-item-subtitle
                            >Full Container Load</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item @click="booking.serviceType = 'LCL'">
                        <v-list-item-content>
                          <v-list-item-title>LCL</v-list-item-title>
                          <v-list-item-subtitle
                            >Less than Container Load</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item @click="booking.serviceType = 'BREAKBULK'">
                        <v-list-item-content>
                          <v-list-item-title>Breakbulk</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-list-item-action>
              </v-list-item>

              <v-list-item style="height: 30px" class="mx-0 px-0">
                <v-list-item-action class="mx-0">
                  <v-icon small>category</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 13px"
                    >Booking Type</v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-action>
                  <v-menu offset-y rounded>
                    <template v-slot:activator="{ on }">
                      <v-chip v-on="on" small label outlined>
                        <v-icon
                          small
                          left
                          color="teal"
                          v-if="booking.type == 'LOGISTICS'"
                          >mode_of_travel</v-icon
                        >
                        <v-icon
                          small
                          left
                          color="teal"
                          v-else-if="booking.type == 'DOCUMENTATION'"
                          >article</v-icon
                        >
                        <v-icon
                          small
                          left
                          color="teal"
                          v-else-if="booking.type == 'HAULAGE'"
                          >local_shipping</v-icon
                        >
                        <v-icon
                          small
                          left
                          color="teal"
                          v-else-if="booking.type == 'MONITORING'"
                          >thermostat</v-icon
                        >

                        <span style="text-transform: none">
                          {{ $Format.capitalizeFirstLetter(booking.type) }}
                        </span>
                        <v-icon small right>expand_more</v-icon>
                      </v-chip>
                    </template>
                    <v-list dense subheader>
                      <v-list-item @click="booking.type = 'LOGISTICS'">
                        <v-list-item-content>
                          <v-list-item-title>Logistics</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item @click="booking.type = 'DOCUMENTATION'">
                        <v-list-item-content>
                          <v-list-item-title
                            >Documentation Only</v-list-item-title
                          >
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item @click="booking.type = 'HAULAGE'">
                        <v-list-item-content>
                          <v-list-item-title>Transport Only</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item @click="booking.type = 'MONITORING'">
                        <v-list-item-content>
                          <v-list-item-title>Monitoring Only</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-list-item-action>
              </v-list-item>
              <v-list-item style="height: 30px" class="mx-0 px-0">
                <v-list-item-action class="mx-0">
                  <v-icon small>mode_of_travel</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 13px"
                    >Mode of Transport</v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-action>
                  <v-menu offset-y rounded>
                    <template v-slot:activator="{ on }">
                      <v-chip v-on="on" small outlined label>
                        <v-icon
                          small
                          color="blue-grey darken-2"
                          left
                          v-if="booking.modeOfTransport == 'OCEAN'"
                          >directions_boat</v-icon
                        >
                        <v-icon
                          small
                          color="blue-grey darken-2"
                          left
                          v-else-if="booking.modeOfTransport == 'AIR'"
                          >flight</v-icon
                        >
                        <v-icon
                          small
                          color="blue-grey darken-2"
                          left
                          v-else-if="booking.modeOfTransport == 'RAIL'"
                          >train</v-icon
                        >
                        <v-icon
                          small
                          color="blue-grey darken-2"
                          left
                          v-else-if="booking.modeOfTransport == 'ROAD'"
                          >local_shipping</v-icon
                        >

                        <span style="text-transform: none; color: white">
                          {{
                            $Format.capitalizeFirstLetter(
                              booking.modeOfTransport
                            )
                          }}
                        </span>
                        <v-icon small right>expand_more</v-icon>
                      </v-chip>
                    </template>
                    <v-list dense subheader>
                      <v-list-item @click="booking.modeOfTransport = 'OCEAN'">
                        <v-list-item-content>
                          <v-list-item-title>Ocean</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item @click="booking.modeOfTransport = 'AIR'">
                        <v-list-item-content>
                          <v-list-item-title>Air</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item @click="booking.modeOfTransport = 'ROAD'">
                        <v-list-item-content>
                          <v-list-item-title>Road</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item @click="booking.modeOfTransport = 'RAIL'">
                        <v-list-item-content>
                          <v-list-item-title>Rail</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-list-item-action>
              </v-list-item>
            </v-list>
            <v-list
              dense
              subheader
              class="my-0 py-0"
              style="height: 45vh; overflow-y: auto"
            >
              <v-divider></v-divider>

              <v-subheader style="font-size: 14px">
                <v-icon class="mr-2" small>groups</v-icon>
                Parties</v-subheader
              >
              <v-divider></v-divider>
              <v-list-item v-if="booking.customer" style="height: 30px">
                <v-list-item-action class="mr-1">
                  <v-avatar
                    v-if="booking.customer.logo"
                    color="white"
                    size="22"
                  >
                    <v-img :src="booking.customer.logo" contain></v-img>
                  </v-avatar>
                  <v-avatar v-else color="secondary" size="22">
                    <h2>
                      {{
                        booking.customer.name
                          ? booking.customer.name.charAt(0)
                          : ""
                      }}
                    </h2>
                  </v-avatar>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 13px">
                    {{ booking.customer.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle style="font-size: 12px">
                    Selected Customer
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-row justify="center">
                    <v-btn small @click="customerSearchModal = true" icon
                      ><v-icon small>edit</v-icon></v-btn
                    >
                    <v-btn
                      small
                      @click="booking.customer = undefined"
                      color="red"
                      icon
                      ><v-icon small>delete</v-icon></v-btn
                    >
                  </v-row>
                </v-list-item-action>
              </v-list-item>
              <v-list-item
                v-else
                @click="customerSearchModal = true"
                style="height: 30px"
              >
                <v-list-item-action class="mr-1">
                  <v-icon small>payments</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 13px"
                    >Select Customer</v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-action class="mr-1">
                  <v-icon color="primary">add_circle_outline</v-icon>
                </v-list-item-action>
              </v-list-item>

              <v-list-item
                v-if="booking.customer"
                :key="profileKey"
                style="height: 30px"
              >
                <v-list-item-action class="mr-1">
                  <v-icon small>handshake</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title
                    v-if="booking.consigneeProfile"
                    style="font-size: 13px"
                  >
                    {{ booking.consigneeProfile.systemReference }}
                  </v-list-item-title>
                  <v-list-item-title v-else> - </v-list-item-title>
                  <v-list-item-subtitle style="font-size: 12px">
                    Shipment Profile
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-row justify="center" v-if="booking.consigneeProfile">
                    <v-btn small @click="profileModal = true" icon
                      ><v-icon small>edit</v-icon></v-btn
                    >
                    <v-btn small @click="removeProfile()" color="red" icon
                      ><v-icon small>delete</v-icon></v-btn
                    >
                  </v-row>
                  <v-btn
                    icon
                    color="primary"
                    small
                    v-else
                    @click="profileModal = true"
                  >
                    <v-icon>add_circle_outline</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-list-item
                v-if="booking.consigneeProfile"
                @click="shipmentProfileConfig = true"
                style="height: 30px"
              >
                <v-list-item-action class="mr-1">
                  <v-icon v-if="booking.dealTerm" small color="secondary"
                    >settings</v-icon
                  >
                  <v-icon v-else small color="primary"
                    >add_circle_outline</v-icon
                  >
                </v-list-item-action>
                <v-list-item-content v-if="!booking.dealTerm">
                  <v-list-item-title style="font-size: 13px"
                    >Select Profile Configuration</v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-content v-else>
                  <v-list-item-title style="font-size: 13px">
                    {{ booking.dealTerm.incoTerm }}
                  </v-list-item-title>
                  <v-list-item-subtitle style="font-size: 12px">
                    Profile Configuration
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                @click="incoTermModal = true"
                v-if="!booking.consigneeProfile"
                style="height: 30px"
              >
                <v-list-item-action class="mr-1">
                  <v-icon small>swap_horiz</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title
                    v-if="booking.incoTerm"
                    style="font-size: 13px"
                  >
                    {{ booking.incoTerm }}
                  </v-list-item-title>
                  <v-list-item-title v-else>-</v-list-item-title>
                  <v-list-item-subtitle style="font-size: 12px">
                    Inco Term
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <div v-if="booking.customer">
                <v-list-item
                  :disabled="
                    booking.consigneeProfile != undefined ||
                    booking.consigneeProfile != null
                  "
                  style="height: 30px"
                  v-if="booking.movementType !== 'IMPORT'"
                  @click="(partyModal = true), (partyType = 'Shipper')"
                >
                  <v-list-item-action class="mr-1">
                    <v-icon small>directions_boat_filled</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title
                      v-if="booking.shipper"
                      style="font-size: 13px"
                    >
                      {{ booking.shipper.name }}
                    </v-list-item-title>
                    <v-list-item-title v-else> - </v-list-item-title>
                    <v-list-item-subtitle
                      v-if="booking.onBehalfShipper"
                      style="font-size: 12px"
                    >
                      On behalf of
                      {{ booking.onBehalfShipper.name }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle style="font-size: 12px">
                      <v-icon x-small class="mr-1">label</v-icon>
                      Shipper
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  :disabled="
                    booking.consigneeProfile != undefined ||
                    booking.consigneeProfile != null
                  "
                  style="height: 30px"
                  v-else
                >
                  <v-list-item-action class="mr-1">
                    <v-icon small>directions_boat_filled</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title
                      v-if="booking.shipper"
                      style="font-size: 13px"
                    >
                      {{ booking.shipper.name }}
                    </v-list-item-title>
                    <v-list-item-title v-else> - </v-list-item-title>
                    <v-list-item-subtitle
                      v-if="booking.onBehalfShipper"
                      style="font-size: 12px"
                    >
                      On behalf of
                      {{ booking.onBehalfShipper.name }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle style="font-size: 12px">
                      <v-icon x-small class="mr-1">label</v-icon>
                      Shipper
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-row justify="center" v-if="booking.shipper">
                      <v-btn
                        small
                        @click="(partyModal = true), (partyType = 'Shipper')"
                        icon
                        ><v-icon small>edit</v-icon></v-btn
                      >

                      <v-btn
                        small
                        @click="
                          (booking.shipper = null), removeImportShipperParty()
                        "
                        color="red"
                        icon
                        ><v-icon small>delete</v-icon></v-btn
                      >
                    </v-row>
                    <v-btn
                      icon
                      color="primary"
                      small
                      v-else
                      @click="(partyModal = true), (partyType = 'Shipper')"
                    >
                      <v-icon>add_circle_outline</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item
                  :disabled="
                    booking.consigneeProfile != undefined ||
                    booking.consigneeProfile != null
                  "
                  style="height: 30px"
                  @click="(partyModal = true), (partyType = 'Forwarder')"
                >
                  <v-list-item-action class="mr-1">
                    <v-icon small>fast_forward</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title
                      v-if="booking.forwarder"
                      style="font-size: 13px"
                    >
                      {{ booking.forwarder.name }}
                    </v-list-item-title>
                    <v-list-item-title v-else> - </v-list-item-title>
                    <v-list-item-subtitle
                      v-if="booking.onBehalfForwarder"
                      style="font-size: 12px"
                    >
                      On behalf of {{ booking.onBehalfForwarder.name }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle style="font-size: 12px">
                      <v-icon x-small class="mr-1">label</v-icon>
                      Forwarder
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  :disabled="
                    booking.consigneeProfile != undefined ||
                    booking.consigneeProfile != null
                  "
                  style="height: 30px"
                  @click="(partyModal = true), (partyType = 'Consignee')"
                >
                  <v-list-item-action class="mr-1">
                    <v-icon small>call_received</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title
                      v-if="booking.consignee"
                      style="font-size: 13px"
                    >
                      {{ booking.consignee.name }}
                    </v-list-item-title>
                    <v-list-item-title v-else> - </v-list-item-title>
                    <v-list-item-subtitle
                      v-if="booking.onBehalfConsignee"
                      style="font-size: 12px"
                    >
                      On behalf of {{ booking.onBehalfConsignee.name }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle style="font-size: 12px">
                      <v-icon x-small class="mr-1">label</v-icon>
                      Consignee
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  :disabled="
                    booking.consigneeProfile != undefined ||
                    booking.consigneeProfile != null
                  "
                  style="height: 30px"
                  @click="(partyModal = true), (partyType = 'Buyer')"
                >
                  <v-list-item-action class="mr-1">
                    <v-icon small>shopping_cart</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title
                      v-if="booking.buyer"
                      style="font-size: 13px"
                    >
                      {{ booking.buyer.name }}
                    </v-list-item-title>
                    <v-list-item-title v-else> - </v-list-item-title>
                    <v-list-item-subtitle
                      v-if="booking.onBehalfBuyer"
                      style="font-size: 12px"
                    >
                      On behalf of {{ booking.onBehalfBuyer.name }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle style="font-size: 12px">
                      <v-icon x-small class="mr-1">label</v-icon>
                      Buyer
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  @click="(partyModal = true), (partyType = 'Stock Provider')"
                  style="height: 30px"
                >
                  <v-list-item-action class="mr-1">
                    <v-icon small>fact_check</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title
                      v-if="booking.stockProvider"
                      style="font-size: 13px"
                    >
                      {{ booking.stockProvider.name }}
                    </v-list-item-title>
                    <v-list-item-title v-else> - </v-list-item-title>
                    <v-list-item-subtitle style="font-size: 12px">
                      <v-icon x-small class="mr-1">label</v-icon>
                      Stock Provider
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </div>
              <!-- <v-divider></v-divider>
  <v-subheader style="font-size: 14px">
    <v-icon class="mr-2" small>engineering</v-icon>Roles
  </v-subheader>
  <v-divider></v-divider>
  <v-list-item style="height: 30px" class="mx-0 px-0">
    <v-list-item-content class="text-center">
      <span style="font-size: 13px; color: grey"
        >Coming soon</span
      >
    </v-list-item-content>
  </v-list-item> -->
            </v-list>
         
          </v-col>
          <v-col cols="12" sm="4" md="3" lg="2" class="my-0 py-0">
            <v-list
              dense
              subheader
              class="my-0 py-0 mx-0"
              style="height: 45vh; overflow-y: auto"
            >
              <v-divider></v-divider>

              <v-subheader style="font-size: 14px">
                <v-icon class="mr-2" small>location_on</v-icon>
                Locations</v-subheader
              >
              <v-divider></v-divider>
              <v-list-item
                @click="(countryType = 'Origin'), (countryModal = true)"
                :disabled="
                  booking.consigneeProfile != undefined &&
                  booking.consigneeProfile != null
                "
                style="height: 30px"
                class="mx-0 px-0"
              >
                <v-list-item-action class="mr-1">
                  <v-icon small>public</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title
                    v-if="booking.originCountry"
                    style="font-size: 12px"
                  >
                    {{ booking.originCountry.name }}
                  </v-list-item-title>
                  <v-list-item-title v-else>-</v-list-item-title>
                  <v-list-item-subtitle style="font-size: 11px">
                    Origin Country
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-avatar size="24">
                    <v-img
                      contain
                      v-if="booking.originCountry"
                      :src="`https://cdn.loglive.io/flags/4x3/${booking.originCountry.iso2.toLowerCase()}.svg`"
                    ></v-img>
                  </v-avatar>
                </v-list-item-action>
              </v-list-item>

              <v-list-item
                @click="polModal = true"
                style="height: 30px"
                class="mx-0 px-0"
              >
                <v-list-item-action class="mr-1">
                  <v-icon small>anchor</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title
                    v-if="booking.portOfLoadCity"
                    style="font-size: 12px"
                  >
                    {{ booking.portOfLoadCity }}
                  </v-list-item-title>
                  <v-list-item-title v-else>-</v-list-item-title>
                  <v-list-item-subtitle style="font-size: 11px">
                    Port of Load
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-chip
                    v-if="booking.portOfLoadValue"
                    small
                    outlined
                    style="font-size: 12px; border: none"
                  >
                    <v-avatar size="16" left>
                      <v-img
                        contain
                        v-if="booking.portOfLoadValue"
                        :src="`https://cdn.loglive.io/flags/4x3/${booking.portOfLoadValue
                          .substring(0, 2)
                          .toLowerCase()}.svg`"
                      ></v-img>
                    </v-avatar>
                    {{ booking.portOfLoadValue }}
                  </v-chip>
                </v-list-item-action>
              </v-list-item>
              <v-list-item
                @click="podModal = true"
                style="height: 30px"
                class="mx-0 px-0"
              >
                <v-list-item-action class="mr-1">
                  <v-icon small>anchor</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title
                    v-if="booking.portOfDischargeCity"
                    style="font-size: 12px"
                  >
                    {{ booking.portOfDischargeCity }}
                  </v-list-item-title>
                  <v-list-item-title v-else>-</v-list-item-title>
                  <v-list-item-subtitle style="font-size: 11px">
                    Port of Discharge
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-chip
                    v-if="booking.portOfDischargeValue"
                    small
                    outlined
                    style="font-size: 12px; border: none"
                  >
                    <v-avatar size="16" left>
                      <v-img
                        contain
                        v-if="booking.portOfDischargeValue"
                        :src="`https://cdn.loglive.io/flags/4x3/${booking.portOfDischargeValue
                          .substring(0, 2)
                          .toLowerCase()}.svg`"
                      ></v-img>
                    </v-avatar>
                    {{ booking.portOfDischargeValue }}
                  </v-chip>
                </v-list-item-action>
              </v-list-item>
              <v-list-item
                @click="fdModal = true"
                style="height: 30px"
                class="mx-0 px-0"
              >
                <v-list-item-action class="mr-1">
                  <v-icon small>location_on</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title
                    v-if="booking.finalDestinationCity"
                    style="font-size: 12px"
                  >
                    {{ booking.finalDestinationCity }}
                  </v-list-item-title>
                  <v-list-item-title v-else>-</v-list-item-title>
                  <v-list-item-subtitle style="font-size: 11px">
                    Final Destination
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-chip
                    v-if="booking.finalDestinationValue"
                    small
                    outlined
                    style="font-size: 12px; border: none"
                  >
                    <v-avatar size="16" left>
                      <v-img
                        contain
                        v-if="booking.finalDestinationValue"
                        :src="`https://cdn.loglive.io/flags/4x3/${booking.finalDestinationValue
                          .substring(0, 2)
                          .toLowerCase()}.svg`"
                      ></v-img>
                    </v-avatar>
                    {{ booking.finalDestinationValue }}
                  </v-chip>
                </v-list-item-action>
              </v-list-item>
              <v-list-item
                @click="(countryType = 'Destination'), (countryModal = true)"
                :disabled="
                  booking.consigneeProfile != undefined &&
                  booking.consigneeProfile != null
                "
                style="height: 30px"
                class="mx-0 px-0"
              >
                <v-list-item-action class="mr-1">
                  <v-icon small>public</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title
                    v-if="booking.destinationCountry"
                    style="font-size: 12px"
                  >
                    {{ booking.destinationCountry.name }}
                  </v-list-item-title>
                  <v-list-item-title v-else>-</v-list-item-title>
                  <v-list-item-subtitle style="font-size: 11px">
                    Destination Country
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-avatar size="24">
                    <v-img
                      contain
                      v-if="booking.destinationCountry"
                      :src="`https://cdn.loglive.io/flags/4x3/${booking.destinationCountry.iso2.toLowerCase()}.svg`"
                    ></v-img>
                  </v-avatar>
                </v-list-item-action>
              </v-list-item>
              <v-list-item style="height: 30px" class="mx-0 pl-0">
                <v-list-item-action class="mr-1">
                  <v-icon small>not_listed_location</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 12px">
                    On Carriage
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-switch
                    v-model="booking.onCarriage"
                    color="primary"
                    hide-details
                    dense
                  ></v-switch>
                </v-list-item-action>
              </v-list-item>
              <v-list-item
                @click="bookingOfficeModal = true"
                style="height: 30px"
                class="mx-0 px-0"
              >
                <v-list-item-action class="mr-1">
                  <v-icon small>business</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title
                    v-if="booking.bookingOffice"
                    style="font-size: 13px"
                  >
                    {{ booking.bookingOfficeName }}
                  </v-list-item-title>
                  <v-list-item-title v-else>-</v-list-item-title>
                  <v-list-item-subtitle style="font-size: 12px">
                    Carrier Booking Office
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          
            <v-list
              dense
              subheader
              class="my-0 py-0"
              style="height: 45vh; overflow-y: auto"
            >
              <v-divider></v-divider>
              <v-subheader style="font-size: 14px">
                <v-icon class="mr-2" small>tag</v-icon>References
              </v-subheader>
              <v-divider></v-divider>
              <v-text-field
                dense
                outlined
                v-model="booking.carrierReferenceNumber"
                hide-details
                class="my-1"
                clearable
              >
                <template v-slot:label>
                  <span style="font-size: 13px"> Carrier Ref No </span>
                </template>
              </v-text-field>
              <v-text-field
                dense
                outlined
                v-model="booking.shipperReferenceNo"
                hide-details
                class="my-1"
                clearable
              >
                <template v-slot:label>
                  <span style="font-size: 13px"> Shipper Ref No </span>
                </template>
              </v-text-field>
              <v-text-field
                dense
                outlined
                v-model="booking.consigneeReferenceNo"
                hide-details
                class="my-1"
                clearable
              >
                <template v-slot:label>
                  <span style="font-size: 13px"> Consignee Ref No </span>
                </template>
              </v-text-field>
              <v-text-field
                dense
                outlined
                v-model="booking.ucrNo"
                hide-details
                class="my-1"
                clearable
              >
                <template v-slot:label>
                  <span style="font-size: 13px"> UCR No </span>
                </template>
              </v-text-field>
            </v-list>
          </v-col>
          <v-col cols="12" sm="12" md="6" class="my-0 py-0">
            <v-container style="width: 100%">
              <v-row>
                <v-col cols="12" md="4" class="my-0 py-0">
                  <v-list dense subheader class="my-0 py-0">
                    <v-divider></v-divider>
                    <v-subheader style="font-size: 14px"
                      ><v-icon class="mr-2" small>mode_of_travel</v-icon
                      >Transport Details</v-subheader
                    >
                    <v-divider></v-divider>
                    <v-list-item v-if="booking.modeOfTransport == 'OCEAN'">
                      <v-list-item-action>
                        <v-icon v-if="!booking.shippingLine" small
                          >directions_boat</v-icon
                        >
                        <v-avatar
                          :color="
                            booking.shippingLine && booking.shippingLine.logo
                              ? 'white'
                              : 'secondary'
                          "
                          size="32"
                          v-else
                        >
                          <v-img
                            v-if="
                              booking.shippingLine && booking.shippingLine.logo
                            "
                            :src="booking.shippingLine.logo"
                            contain
                          ></v-img>
                          <v-icon v-else small>directions_boat</v-icon>
                        </v-avatar>
                      </v-list-item-action>
                      <v-list-item-content v-if="booking.vessel">
                        <v-list-item-title style="font-size: 12px">
                          {{ booking.vessel.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          <u
                            style="cursor: pointer"
                            @click="voyageNumberModal = true"
                            v-if="booking.mainCarriageConveyanceNumber"
                            >Voyage No:
                            {{ booking.mainCarriageConveyanceNumber }}</u
                          >
                          <u
                            style="cursor: pointer"
                            @click="voyageNumberModal = true"
                            v-else
                            >No Voyage No</u
                          >
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-content v-else>
                        <v-list-item-content>
                          <v-list-item-title style="font-size: 12px"
                            >Add Vessel</v-list-item-title
                          >
                          <v-list-item-subtitle style="font-size: 11px"
                            ><i>(Required)</i></v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-row justify="center">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                v-on="on"
                                icon
                                color="teal"
                                @click="openSailingSchedules()"
                              >
                                <v-icon>search</v-icon></v-btn
                              >
                            </template>
                            <span style="font-size: 12px"
                              >Search Sailing Schedules</span
                            >
                          </v-tooltip>
                        </v-row>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item
                      @click="shippingLineModal = true"
                      v-if="booking.modeOfTransport == 'OCEAN'"
                    >
                      <v-list-item-action>
                        <v-icon v-if="!booking.shippingLine" small
                          >directions_boat</v-icon
                        >
                        <v-avatar
                          v-else
                          :color="
                            booking.shippingLine && booking.shippingLine.logo
                              ? 'white'
                              : 'secondary'
                          "
                          size="32"
                        >
                          <v-img
                            v-if="
                              booking.shippingLine && booking.shippingLine.logo
                            "
                            :src="booking.shippingLine.logo"
                            contain
                          ></v-img>
                          <h3 v-else>
                            {{ booking.shippingLine.name.charAt(0) }}
                          </h3>
                        </v-avatar>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          v-if="booking.shippingLine"
                          style="font-size: 12px"
                          >{{ booking.shippingLine.name }}</v-list-item-title
                        >
                        <v-list-item-title v-else>-</v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px"
                          >Shipping Line</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      @click="shippingLineModal = true"
                      v-else
                    >
                      <v-list-item-action>
                        <v-icon v-if="!booking.carrier && booking.modeOfTransport == 'AIR'" small
                          >flight</v-icon
                        >
                        <v-icon v-else-if="!booking.carrier" small
                          >directions_boat</v-icon
                        >
                        <v-avatar
                          v-else
                          :color="
                            booking.carrier && booking.carrier.logo
                              ? 'white'
                              : 'secondary'
                          "
                          size="32"
                        >
                          <v-img
                            v-if="
                              booking.carrier && booking.carrier.logo
                            "
                            :src="booking.carrier.logo"
                            contain
                          ></v-img>
                          <h3 v-else>
                            {{ booking.carrier.name.charAt(0) }}
                          </h3>
                        </v-avatar>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          v-if="booking.carrier"
                          style="font-size: 12px"
                          >{{ booking.carrier.name }}</v-list-item-title
                        >
                        <v-list-item-title v-else>-</v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px"
                          >Carrier</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="booking.modeOfTransport == 'AIR'"
                    @click="voyageNumberModal = true"
                    
                    >
                    <v-list-item-action>
                        <v-icon small>tag</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                          <v-list-item-title style="font-size: 12px" v-if="booking.mainCarriageConveyanceNumber">
                            {{ booking.mainCarriageConveyanceNumber }}
                          </v-list-item-title>
                          <v-list-item-title style="font-size: 12px" v-else>-
                          </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                         Flight No
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      </v-list-item>
                    <v-list-item
                      @click="editContractNumber()"
                    >
                      <v-list-item-action>
                        <v-icon small>description</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          v-if="booking.contractNumber"
                          style="font-size: 12px"
                        >
                          {{ booking.contractNumber }}
                        </v-list-item-title>
                        <v-list-item-title v-else style="font-size: 12px">
                          No Contract Selected
                        </v-list-item-title>
                        <v-list-item-subtitle
                          v-if="booking.contractParty"
                          style="font-size: 11px"
                        >
                          <v-icon class="mr-2" small color="grey"
                            >groups</v-icon
                          >
                          <span v-if="booking.contractParty.alias">
                            {{ booking.contractParty.alias }}
                          </span><span v-else>
                            {{ booking.contractParty.name }}
                            </span> 
                       
                        </v-list-item-subtitle>
                        <v-list-item-subtitle style="font-size: 11px">
                          Selected Contract
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <div>
                      <v-menu
                        :close-on-content-click="true"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on }">
                          <v-list-item v-on="on">
                            <v-list-item-action>
                              <v-icon small>today</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title
                                v-if="booking.etd"
                                style="font-size: 12px"
                              >
                                {{ $Format.formatDate(booking.etd).isoFormat }}
                              </v-list-item-title>
                              <v-list-item-title v-else> - </v-list-item-title>
                              <v-list-item-subtitle style="font-size: 11px">
                                Estimated Departure <i>(Required)</i>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                        <v-date-picker
                          v-model="booking.etd"
                          no-title
                          scrollable
                          color="primary"
                        >
                        </v-date-picker>
                      </v-menu>
                      <!-- <v-col cols="12" sm="12"> -->
                      <v-menu
                        :close-on-content-click="true"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on }">
                          <v-list-item v-on="on">
                            <v-list-item-action>
                              <v-icon small>today</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title
                                v-if="booking.eta"
                                style="font-size: 12px"
                              >
                                {{ $Format.formatDate(booking.eta).isoFormat }}
                              </v-list-item-title>
                              <v-list-item-title v-else> - </v-list-item-title>
                              <v-list-item-subtitle style="font-size: 11px">
                                Estimated Arrival
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                        <v-date-picker
                          v-model="booking.eta"
                          no-title
                          scrollable
                          color="primary"
                        >
                        </v-date-picker>
                      </v-menu>
                      <!-- </v-col> -->
                    </div>
                  </v-list>
                </v-col>
                <v-col cols="12" md="8" class="my-0 py-0">
                  <v-list
                    dense
                    subheader
                    class="my-0 py-0 mx-0"
                    style="height: 45vh; overflow-y: auto"
                  >
                    <v-divider></v-divider>
                    <v-subheader style="font-size: 14px">
                      <v-icon class="mr-2" small>widgets</v-icon>Cargo Details
                      <v-btn
                        class="ml-2"
                        icon
                        color="primary"
                        v-if="
                          booking.bookingContainerItems &&
                          (booking.serviceType != 'LCL' ||
                            (booking.serviceType == 'LCL' &&
                              booking.bookingContainerItems.length == 0))
                        "
                        @click="addContainerDetails()"
                      >
                        <v-icon>add_circle_outline</v-icon>
                      </v-btn>
                    </v-subheader>
                    <v-divider></v-divider>
                    <v-list-item
                      v-if="
                        booking.bookingContainerItems &&
                        booking.bookingContainerItems.length == 0
                      "
                    >
                      <v-list-item-content class="text-center">
                        <span style="color: grey; font-size: 12px"
                          >No cargo details listed</span
                        >
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      v-for="(
                        container, index
                      ) in booking.bookingContainerItems"
                      :key="container.id"
                    >
                      <v-list-item-action>
                        <v-chip>{{ container.quantity }}</v-chip>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{
                            getContainerDescription(container.containerTypeCode)
                          }}
                        </v-list-item-title>
                        <v-list-item-subtitle
                          v-if="
                            container.bookingCargoItems &&
                            container.bookingCargoItems.length > 0
                          "
                        >
                          <v-icon left small color="grey">category</v-icon
                          >Products:
                          {{
                            container.bookingCargoItems
                              .map((x) => x.product.name)
                              .join(", ")
                          }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-else>
                          <v-icon left small color="grey">category</v-icon>No
                          Products
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          <v-icon left small color="grey"
                            >local_shipping</v-icon
                          >
                          <span v-if="container.isPrivateHaulage">
                            <span v-if="container.transportCoordinator">
                              {{ container.transportCoordinator.name }}</span
                            >
                            <span v-else>Merchant</span>
                          </span>
                          <span v-else-if="container.isRailage">Rail</span>
                          <span v-else-if="container.isCarrierHaulage"
                            >Carrier</span
                          >
                          <span v-else>N/A</span>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle
                          v-if="container.productServiceSales.length > 0"
                        >
                          <!-- <v-row justify="center"> -->
                          <v-tooltip
                            top
                            open-delay="500"
                            v-for="product in container.productServiceSales"
                            :key="product.id"
                          >
                            <template v-slot:activator="{ on }">
                              <v-chip
                                v-on="on"
                                small
                                style="font-size: 11px; border: none"
                                outlined
                              >
                                <v-avatar
                                  left
                                  size="30"
                                  :color="!product.icon ? 'secondary' : ''"
                                >
                                  <v-img
                                    v-if="product.icon"
                                    :src="product.icon"
                                    contain
                                  ></v-img>
                                  <h3 v-else>{{ product.name.charAt(0) }}</h3>
                                </v-avatar>
                                {{ product.name }}
                              </v-chip>
                            </template>
                            {{ product.name }}
                          </v-tooltip>
                          <!-- </v-row> -->
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="text-wrap">
                          {{ container.comment }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-content >
                        <v-container>
                          <v-row justify="center">
                            <v-tooltip top v-for="(feature, featureIndex) in container.containerFeatures" :key="featureIndex">
                              <template v-slot:activator="{ on }">
                                <v-btn class="mx-1" icon outlined small v-on="on" :color="feature.color">
                                  <v-icon small>{{ feature.icon }}</v-icon>
                                  </v-btn>  
                                </template>
                                <span style="font-size: 12px">{{feature.name}}</span>
                            </v-tooltip>
                            </v-row>
                          </v-container>
                        </v-list-item-content>
                      <v-list-item-action class="mx-0 px-0">
                        <v-row justify="center">
                          <v-btn
                            icon
                            @click="
                              editContainerItem(container, index, 'Create')
                            "
                            ><v-icon small>edit</v-icon></v-btn
                          >
                          <v-btn
                            icon
                            @click="deleteContainerItem(index)"
                            color="red"
                            ><v-icon small>delete</v-icon></v-btn
                          >
                        </v-row>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-col>
              
                <v-col cols="12" sm="6" md="4" class="my-0 py-0">
                  <v-list
                    dense
                    subheader
                    class="my-0 py-0 mx-0"
                    style="height: 45vh; overflow-y: auto"
                  >
                    <v-divider></v-divider>
                    <v-subheader style="font-size: 14px">
                      <v-icon class="mr-2" small>payments</v-icon>
                      Payment Terms
                      <v-btn
                        class="ml-1"
                        icon
                        @click="addPayment()"
                        color="primary"
                        ><v-icon>add_circle_outline</v-icon></v-btn
                      >
                    </v-subheader>
                    <v-divider></v-divider>
                    <v-list-item
                      v-if="
                        booking.bookingPaymentDetails &&
                        booking.bookingPaymentDetails.length == 0
                      "
                    >
                      <v-list-item-content class="text-center">
                        <span style="color: grey; font-size: 12px"
                          >No payment details listed</span
                        >
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      v-for="payment in booking.bookingPaymentDetails"
                      :key="payment.index"
                    >
                      <v-list-item-content>
                        <v-list-item-title style="font-size: 12px">
                          {{ findChargeType(payment.chargeType) }}
                        </v-list-item-title>
                        <v-list-item-subtitle v-if="payment.party"
                          class="text-wrap"
                          style="font-size: 11px"
                        >
                        <v-icon small class="mr-1">groups</v-icon> {{ payment.party.name }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle
                          class="text-wrap"
                          style="font-size: 11px"
                        >
                         Term: {{ payment.paymentTerm }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-row justify="center">
                          <v-btn icon small @click="editPaymentItem(payment)"
                            ><v-icon small>edit</v-icon></v-btn
                          >
                          <v-btn
                            icon
                            small
                            @click="deletePaymentItem(payment)"
                            color="red"
                            ><v-icon small>delete</v-icon></v-btn
                          >
                        </v-row>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="my-0 py-0">
                  <v-list
              dense
              class="my-0 py-0"
              subheader
              style="height: 45vh; overflow-y: auto"
            >
              <v-divider></v-divider>
              <v-subheader style="font-size: 14px">
                <v-icon class="mr-2" small>sell</v-icon>Product Sales
                <v-btn
                  icon
                  color="primary"
                  @click="addProductService"
                  v-if="
                    availableShipmentProducts.length > 0 ||
                    availableShipmentServices.length > 0
                  "
                >
                  <v-icon>add_circle_outline</v-icon>
                </v-btn>
              </v-subheader>
              <v-divider></v-divider>
              <v-list-item
                v-if="
                  !booking.productServiceSales ||
                  booking.productServiceSales.length == 0
                "
              >
                <v-list-item-content class="text-center">
                  <span style="color: grey; font-size: 12px"
                    >No products listed</span
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-for="product in booking.productServiceSales"
                :key="product.id"
              >
                <v-list-item-action class="mr-1">
                  <v-avatar
                    class="mr-2"
                    size="30"
                    :color="!product.productService.icon ? 'secondary' : ''"
                  >
                    <v-img
                      v-if="product.productService.icon"
                      :src="product.productService.icon"
                      contain
                    ></v-img>
                    <h3 v-else>{{ product.productService.name.charAt(0) }}</h3>
                  </v-avatar>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title
                    style="font-size: 12px"
                    class="text-wrap"
                    >{{ product.productService.name }}</v-list-item-title
                  >
                  <v-list-item-subtitle
                    style="font-size: 11px"
                    v-if="product.productService.type == 'PRODUCT'"
                  >
                    <v-icon color="grey" class="mr-1 mb-1" small
                      >category</v-icon
                    >
                    Product</v-list-item-subtitle
                  >
                  <v-list-item-subtitle style="font-size: 11px" v-else>
                    <v-icon color="grey" class="mr-1 mb-1" small
                      >engineering</v-icon
                    >
                    Service</v-list-item-subtitle
                  >
                  <v-list-item-subtitle class="text-wrap" style="font-size: 11px" v-if="product.productService.productServiceCategory && product.productService.productServiceSubcategory">
                    {{ product.productService.productServiceCategory.name }} <v-icon x-small>chevron_right</v-icon> {{ product.productService.productServiceSubcategory.name }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-chip small>{{ product.quantity }}</v-chip>
                </v-list-item-action>
              </v-list-item>
            </v-list>
                </v-col>
                <v-col cols="12" sm="6" md="4"  class="my-0 py-0">
                  <v-list
              dense
              class="my-0 py-0"
              subheader
              style="height: 45vh; overflow-y: auto"
            >
              <v-divider></v-divider>
              <v-subheader style="font-size: 14px">
                <v-icon small class="mr-2">shopping_cart</v-icon>
               Purchased Services</v-subheader
              >
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-content class="text-center">
                  <span style="font-size: 12px; color: grey">Coming Soon</span>
                </v-list-item-content>
              </v-list-item>
            </v-list>
                  </v-col>
                <!-- <v-col cols="12" sm="6" class="my-0 py-0">
                  <v-list
                    dense
                    class="my-0 py-0"
                    subheader
                    style="height: 45vh; overflow-y: auto"
                  >
                    <v-divider></v-divider>
                    <v-subheader style="font-size: 14px">
                      <v-icon class="mr-2" small>leaderboard</v-icon>Cargo
                      Summary
                    </v-subheader>
                    <v-divider></v-divider>

                  </v-list>
                </v-col> -->
              </v-row>
            </v-container>
          </v-col>
          <v-col cols="12" sm="12" md="2" class="my-0 py-0">
            <v-list
                    dense
                    class="my-0 py-0"
                    subheader
                    style="height: 45vh; overflow-y: auto"
                  >
                    <v-divider></v-divider>
                    <v-subheader style="font-size: 14px">
                      <v-icon class="mr-2" small>tune</v-icon>Cargo Rules
                    </v-subheader>
                    <v-divider></v-divider>
                    <v-list-item
                      style="height: 30px"
                      v-if="booking.isHazardous"
                    >
                      <v-list-item-action class="mr-1">
                        <v-icon small>warning</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title style="font-size: 13px">
                          Hazardous Content
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-icon class="pulse-hazardous" color="#0e871e"
                          >error</v-icon
                        >
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item @click="caProductModal = true">
                      <v-list-item-action class="mr-1">
                        <v-icon small>thermostat_auto</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          v-if="booking.caProduct"
                          style="font-size: 13px"
                        >
                          {{ booking.caProduct }}
                        </v-list-item-title>
                        <v-list-item-title v-else>-</v-list-item-title>
                        <v-list-item-subtitle
                          style="font-size: 12px"
                          v-if="booking.oxygen || booking.carbonDioxide"
                        >
                          <span v-if="booking.oxygen">
                            <span style="font-weight: bold" class="ml-2"
                              >O²</span
                            >
                            {{ booking.oxygen }}%</span
                          >
                          <span v-if="booking.carbonDioxide">
                            <span style="font-weight: bold" class="ml-2"
                              >CO²</span
                            >
                            {{ booking.carbonDioxide }}%</span
                          >
                        </v-list-item-subtitle>
                        <v-list-item-subtitle style="font-size: 12px">
                          Controlled Atmosphere
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item :key="regimeKey" @click="getRegimeCodes(true)">
                      <v-list-item-action class="mr-1">
                        <v-icon small>code</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          style="font-size: 13px"
                          v-if="booking.regime"
                        >
                          {{ booking.regime.code }}
                        </v-list-item-title>
                        <v-list-item-title v-else> - </v-list-item-title>
                        <v-list-item-subtitle
                          style="font-size: 12px"
                          v-if="booking.regime && booking.regime.setPointTemp"
                        >
                          <v-icon color="#007d5c" x-small class="mr-1"
                            >thermostat</v-icon
                          >
                          Set Temp:
                          {{ `${booking.regime.setPointTemp}°C` }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle
                          style="font-size: 12px"
                          v-if="booking.regime && booking.regime.protocol"
                        >
                          <v-icon color="#9d904a" x-small class="mr-1"
                            >thermostat</v-icon
                          >
                          Protocol:
                          {{ `${booking.regime.protocol}°C` }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle style="font-size: 12px">
                          Regime Code
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      v-if="
                        booking.serviceType != 'BREAKBULK' && booking.regime
                      "
                    >
                      <v-list-item-action class="mr-1">
                        <v-icon small>air</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title style="font-size: 13px">
                          Vent Settings
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-menu
                          offset-y
                          rounded
                          v-if="
                            !booking.caProduct ||
                            (booking.caProduct &&
                              booking.caProduct == 'MAXTEND')
                          "
                        >
                          <template v-slot:activator="{ on }">
                            <v-chip v-on="on" small outlined label>
                              <span style="text-transform: none">
                                {{ booking.vents }}
                              </span>
                              <span v-if="!isNaN(booking.vents)" class="ml-1"
                                >CBM</span
                              >
                              <v-icon small right>expand_more</v-icon>
                            </v-chip>
                          </template>
                          <v-list dense subheader>
                            <v-list-item
                              v-for="(vent, index) in ventSettings"
                              :key="index"
                              @click="booking.vents = vent"
                            >
                              <v-list-item-content>
                                <v-list-item-title>{{
                                  vent
                                }}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                        <v-chip v-else small outlined label> Closed </v-chip>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
         
            <v-list
              dense
              class="my-0 py-0"
              subheader
              style="height: 45vh; overflow-y: auto"
            >
              <v-divider></v-divider>
              <v-subheader style="font-size: 14px">
                <v-icon small class="mr-2">loyalty</v-icon>
               System Services</v-subheader
              >
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-content class="text-center">
                  <span style="font-size: 12px; color: grey">Coming Soon</span>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="profileModal"
      :width="!settingShipmentProfile ? '90vw' : '400px'"
    >
      <SearchShipmentProfiles
        v-if="!settingShipmentProfile"
        :customerId="booking.customerId"
        @close="profileModal = false"
        @selected="setShipmentProfile"
      />
      <v-container style="width: 100%; height: 200px" v-else>
        <v-row justify="center" align="center" style="height: 100%">
          <v-col cols="12" class="text-center">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
            <div class="mt-3">Setting Shipment Profile...</div>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>

    <v-dialog
      v-model="incoTermModal"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
      peristent
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Set Inco Term </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="incoTermModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-list dense style="max-height: 50vh; overflow-y: auto">
            <v-list-item
              v-for="code in incoTerms"
              :key="code.id"
              @click="setIncoTerm(code)"
              style="height: 45px"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ code.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ code.abbreviation }}
                </v-list-item-subtitle>
                <!-- To show shipping line if available -->
                <v-list-item-subtitle v-if="code.shippingLine">
                  <v-icon small class="mr-2">directions_boat</v-icon>
                  {{ code.shippingLine }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="customerSearchModal"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
      persistent
    >
      <v-card>
        <v-card-title>
          Search Customer
          <v-spacer></v-spacer>
          <v-btn text @click="customerSearchModal = false">X</v-btn>
        </v-card-title>
        <v-card-text>
          <v-text-field
            placeholder="Search"
            autofocus
            prepend-inner-icon="search"
            v-model="searchCustomer"
            outlined
            dense
            clearable
            hide-details
          ></v-text-field>
          <v-list style="max-height: 50vh; overflow-y: auto" dense>
            <v-list-item
              v-for="organisation in filterOrganisations"
              :key="organisation.id"
              :disabled="
                organisation.clientStatus == 'ON HOLD' ||
                organisation.clientStatus == 'INACTIVE'
              "
              @click="setCustomer(organisation)"
              style="height: 60px"
            >
              <v-list-item-action>
                <v-avatar
                  size="30"
                  v-if="organisation.relatedOrganisation.logo"
                  color="white"
                >
                  <v-img
                    :src="organisation.relatedOrganisation.logo"
                    contain
                  ></v-img>
                </v-avatar>
                <v-avatar size="30" v-else color="secondary">
                  <h3>
                    {{
                      organisation.relatedOrganisation.name
                        ? organisation.relatedOrganisation.name.charAt(0)
                        : ""
                    }}
                  </h3>
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title style="font-size: 13px">
                  {{ organisation.relatedOrganisation.name }}
                </v-list-item-title>
                <v-list-item-subtitle
                  style="font-size: 12px"
                  v-if="
                    organisation.relatedOrganisation.alias &&
                    organisation.relatedOrganisation.alias !=
                      organisation.relatedOrganisation.name
                  "
                >
                  {{ organisation.relatedOrganisation.alias }}
                </v-list-item-subtitle>
                <v-list-item-subtitle
                  v-if="organisation.clientStatus"
                  style="font-size: 12px"
                >
                  <v-chip x-small outlined style="border: none">
                    <v-icon
                      small
                      left
                      :color="getClientStatusColor(organisation.clientStatus)"
                      >fiber_manual_record</v-icon
                    >
                    {{ organisation.clientStatus }}
                  </v-chip>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="filterOrganisations.length == 0">
              <v-list-item-content class="text-center">
                <span style="font-size: 13px; color: grey"
                  >No organisations found</span
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="partyModal"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
      persistent
    >
      <v-card :loading="loadingLinkedOrganisations">
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Manage {{ partyType }} </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            color="redPop"
            @click="(partyModal = false), (searchOrganisation = null)"
          >
            <v-icon small>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            outlined
            dense
            placeholder="Search"
            prepend-inner-icon="search"
            v-model="searchOrganisation"
            clearable
            hide-details
          ></v-text-field>
          <v-list dense style="max-height: 50vh; overflow-y: auto">
            <v-list-item v-if="filterCustomerOrganisations.length == 0">
              <v-list-item-content class="text-center">
                <span style="font-size: 13px; color: grey"
                  >No organisations found</span
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-for="organisation in filterCustomerOrganisations"
              :key="organisation.id"
              style="height: 45px"
              @click="setParty(organisation.relatedOrganisation)"
            >
              <v-list-item-action>
                <v-avatar
                  v-if="organisation.relatedOrganisation.logo"
                  color="white"
                  size="30"
                >
                  <v-img
                    :src="organisation.relatedOrganisation.logo"
                    contain
                  ></v-img>
                </v-avatar>
                <v-avatar v-else color="secondary" size="30">
                  <h3 style="color: white">
                    {{
                      organisation.relatedOrganisation.name
                        ? organisation.relatedOrganisation.name.charAt(0)
                        : ""
                    }}
                  </h3>
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title style="font-size: 12px">
                  {{ organisation.relatedOrganisation.name }}
                </v-list-item-title>
                <v-list-item-subtitle
                  v-if="organisation.relatedOrganisation.alias"
                  style="font-size: 11px"
                >
                  {{ organisation.relatedOrganisation.alias }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="countryModal"
      width="400px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title> Select {{ countryType }} Country </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="(countryModal = false), (searchCountries = null)"
            >X</v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-text-field
            outlined
            autofocus
            dense
            hide-details
            v-model="searchCountries"
            clearable
            placeholder="Search"
            prepend-inner-icon="search"
          ></v-text-field>
          <v-list dense style="max-height: 50vh; overflow-y: auto">
            <v-list-item
              v-for="country in filterCountries"
              :key="country.id"
              @click="setCountry(country)"
              style="height: 30px"
            >
              <v-list-item-action>
                <v-avatar size="24" left>
                  <v-img
                    contain
                    v-if="country.iso2"
                    :src="`https://cdn.loglive.io/flags/4x3/${country.iso2.toLowerCase()}.svg`"
                  ></v-img>
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title style="font-size: 13px">
                  {{ country.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="filterCountries.length == 0">
              <v-list-item-content class="text-center">
                <span style="color: grey; font-size: 13px"
                  >No countries found</span
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- POL Modal -->
    <v-dialog
      v-model="polModal"
      width="400px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            <v-icon class="mr-2" color="grey">anchor</v-icon> Manage POL
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="polModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            class="my-0 py-0"
            autofocus
            v-if="booking.consigneeProfile"
            prepend-inner-icon="search"
            dense
            placeholder="Search"
            outlined
            clearable
            v-model="searchOrigin"
            hide-details
          ></v-text-field>
          <v-text-field
            class="my-0 py-0"
            autofocus
            v-else
            prepend-inner-icon="search"
            dense
            placeholder="Search"
            outlined
            clearable
            v-model="portSearch"
            hide-details
          ></v-text-field>
          <div
            v-if="
              booking.consigneeProfile &&
              !booking.consigneeProfile.allOriginPorts
            "
          >
            <v-list dense>
              <v-list-item
                v-for="port in loadingPorts"
                :key="port.id"
                @click="setPort('portOfLoad', port)"
              >
                <v-list-item-avatar>
                  <v-avatar size="24" class="mr-1" left>
                    <v-img
                      contain
                      v-if="port.code"
                      :src="`https://cdn.loglive.io/flags/4x3/${port.code
                        .substring(0, 2)
                        .toLowerCase()}.svg`"
                    ></v-img>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 14px">
                    {{ port.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle style="font-size: 13px; color: grey">
                    {{ port.code }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon
                    color="success"
                    v-if="port.code == booking.portOfLoadValue"
                  >
                    check_circle_outline
                  </v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </div>
          <div v-else-if="booking.consigneeProfile">
            <v-progress-linear
              v-if="loadingOriginPorts"
              color="primary"
              indeterminate
            >
            </v-progress-linear>
            <v-expand-transition>
              <v-card flat class="my-0 py-0">
                <v-card-text class="my-0 py-0">
                  <v-virtual-scroll
                    v-if="filterOriginPort.length > 0"
                    class="my-0 py-0"
                    :bench="0"
                    :items="filterOriginPort"
                    :key="originPortKey"
                    height="500"
                    item-height="50"
                  >
                    <template v-slot:default="{ item }">
                      <v-list-item
                        :key="item.id"
                        @click="setPort('portOfLoad', item)"
                      >
                        <v-list-item-avatar>
                          <v-avatar size="24" class="mr-1" left>
                            <v-img
                              contain
                              v-if="item.locode"
                              :src="`https://cdn.loglive.io/flags/4x3/${item.locode
                                .substring(0, 2)
                                .toLowerCase()}.svg`"
                            ></v-img>
                          </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title style="font-size: 14px">
                            {{ item.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            style="font-size: 13px; color: grey"
                          >
                            {{ item.locode }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn
                            :key="originPortKey"
                            icon
                            @click="favouritePort(item, 'MARK', 'Origin')"
                            v-if="!item.organisationPorts[0]"
                            ><v-icon>star_border</v-icon></v-btn
                          >
                          <v-btn
                            :key="originPortKey"
                            icon
                            @click="favouritePort(item, 'UNMARK', 'Origin')"
                            v-if="item.organisationPorts[0]"
                            ><v-icon color="yellow">star</v-icon></v-btn
                          >
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                  </v-virtual-scroll>
                  <v-list-item v-if="filterOriginPort.length == 0">
                    <v-list-item-content class="text-center">
                      <span style="font-size: 13px; color: grey"
                        >No ports found</span
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>
              </v-card>
            </v-expand-transition>
          </div>
          <div v-else>
            <v-progress-linear
              v-if="loadingPortSearch"
              color="primary"
              indeterminate
            ></v-progress-linear>
            <v-expand-transition>
              <v-card flat class="my-0 py-0">
                <v-card-text class="my-0 py-0">
                  <v-virtual-scroll
                    v-if="portResults.length > 0"
                    class="my-0 py-0"
                    :bench="0"
                    :items="portResults"
                    height="250"
                    item-height="50"
                  >
                    <template v-slot:default="{ item }">
                      <v-list-item
                        :key="item.id"
                        @click="setPort('portOfLoad', item)"
                      >
                        <v-list-item-avatar>
                          <v-avatar size="24" class="mr-1" left>
                            <v-img
                              contain
                              v-if="item.locode"
                              :src="`https://cdn.loglive.io/flags/4x3/${item.locode
                                .substring(0, 2)
                                .toLowerCase()}.svg`"
                            ></v-img>
                          </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title style="font-size: 14px">
                            {{ item.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            style="font-size: 13px; color: grey"
                          >
                            {{ item.locode }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-virtual-scroll>
                  <v-list-item v-else>
                    <v-list-item-content class="text-center">
                      <span style="color: grey; font-size: 13px"
                        >No results found</span
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>
              </v-card>
            </v-expand-transition>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- POD Modal -->
    <v-dialog
      v-model="podModal"
      width="400px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            <v-icon class="mr-2" color="grey">anchor</v-icon> Manage POD
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="podModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            class="my-0 py-0"
            autofocus
            v-if="booking.consigneeProfile"
            prepend-inner-icon="search"
            dense
            placeholder="Search"
            outlined
            clearable
            v-model="searchDestination"
            hide-details
          ></v-text-field>
          <v-text-field
            class="my-0 py-0"
            v-else
            autofocus
            prepend-inner-icon="search"
            dense
            placeholder="Search"
            outlined
            clearable
            v-model="portSearch"
            hide-details
          ></v-text-field>
          <div
            v-if="
              booking.consigneeProfile &&
              !booking.consigneeProfile.allDestinationPorts
            "
          >
            <v-list dense>
              <v-list-item
                v-for="port in dischargePorts"
                :key="port.id"
                @click="setPort('portOfDischarge', port)"
              >
                <v-list-item-avatar>
                  <v-avatar size="24" class="mr-1" left>
                    <v-img
                      contain
                      v-if="port.code"
                      :src="`https://cdn.loglive.io/flags/4x3/${port.code
                        .substring(0, 2)
                        .toLowerCase()}.svg`"
                    ></v-img>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 14px">
                    {{ port.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle style="font-size: 13px; color: grey">
                    {{ port.code }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon
                    color="success"
                    v-if="port.code == booking.portOfDischargeValue"
                  >
                    check_circle_outline
                  </v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </div>
          <div v-else-if="booking.consigneeProfile">
            <v-progress-linear
              v-if="loadingDestinationPorts"
              color="primary"
              indeterminate
            >
            </v-progress-linear>
            <v-expand-transition>
              <v-card flat class="my-0 py-0">
                <v-card-text class="my-0 py-0">
                  <v-virtual-scroll
                    v-if="filterDestinationPort.length > 0"
                    class="my-0 py-0"
                    :bench="0"
                    :items="filterDestinationPort"
                    :key="destinationPortKey"
                    height="400"
                    item-height="50"
                  >
                    <template v-slot:default="{ item }">
                      <v-list-item
                        :key="item.id"
                        @click="setPort('portOfDischarge', item)"
                      >
                        <v-list-item-avatar>
                          <v-avatar size="24" class="mr-1" left>
                            <v-img
                              contain
                              v-if="item.locode"
                              :src="`https://cdn.loglive.io/flags/4x3/${item.locode
                                .substring(0, 2)
                                .toLowerCase()}.svg`"
                            ></v-img>
                          </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title style="font-size: 14px">
                            {{ item.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            style="font-size: 13px; color: grey"
                          >
                            {{ item.locode }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn
                            :key="originPortKey"
                            icon
                            @click="favouritePort(item, 'MARK', 'Destination')"
                            v-if="!item.organisationPorts[0]"
                            ><v-icon>star_border</v-icon></v-btn
                          >
                          <v-btn
                            :key="originPortKey"
                            icon
                            @click="
                              favouritePort(item, 'UNMARK', 'Destination')
                            "
                            v-if="item.organisationPorts[0]"
                            ><v-icon color="yellow">star</v-icon></v-btn
                          >
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                  </v-virtual-scroll>
                  <v-list-item v-if="filterDestinationPort.length == 0">
                    <v-list-item-content class="text-center">
                      <span style="font-size: 13px; color: grey"
                        >No ports found</span
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>
              </v-card>
            </v-expand-transition>
          </div>
          <div v-else>
            <v-progress-linear
              v-if="loadingPortSearch"
              color="primary"
              indeterminate
            ></v-progress-linear>
            <v-expand-transition>
              <v-card flat class="my-0 py-0">
                <v-card-text class="my-0 py-0">
                  <v-virtual-scroll
                    v-if="portResults.length > 0"
                    class="my-0 py-0"
                    :bench="0"
                    :items="portResults"
                    height="400"
                    item-height="50"
                  >
                    <template v-slot:default="{ item }">
                      <v-list-item
                        :key="item.id"
                        @click="setPort('portOfDischarge', item)"
                      >
                        <v-list-item-avatar>
                          <v-avatar size="24" class="mr-1" left>
                            <v-img
                              contain
                              v-if="item.locode"
                              :src="`https://cdn.loglive.io/flags/4x3/${item.locode
                                .substring(0, 2)
                                .toLowerCase()}.svg`"
                            ></v-img>
                          </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title style="font-size: 14px">
                            {{ item.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            style="font-size: 13px; color: grey"
                          >
                            {{ item.locode }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-virtual-scroll>
                  <v-list-item v-else>
                    <v-list-item-content class="text-center">
                      <span style="color: grey; font-size: 13px"
                        >No results found</span
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>
              </v-card>
            </v-expand-transition>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Final Destination Modal -->
    <v-dialog
      v-model="fdModal"
      width="400px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title style="font-size: 16px">
            <v-icon class="mr-2" small>location_on</v-icon> Manage Final
            Destination
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            color="redPop"
            icon
            @click="(fdModal = false), (portSearch = null)"
          >
            <v-icon small>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <template>
            <v-container class="pa-0">
              <!-- Chips Container -->
              <v-chip-group
                v-model="selectedTransportModes"
                active-class="primary--text"
                multiple
              >
                <v-chip
                  v-for="transport in transportModes"
                  :key="transport"
                  :value="transport"
                  filter
                  small
                >
                  {{ transport }}
                </v-chip>
              </v-chip-group>
            </v-container>
          </template>
          <v-text-field
            class="my-0 py-0"
            autofocus
            prepend-inner-icon="search"
            dense
            placeholder="Search"
            outlined
            clearable
            v-model="portSearch"
            hide-details
          ></v-text-field>
          <v-progress-linear
            v-if="loadingPortSearch"
            color="primary"
            indeterminate
          ></v-progress-linear>
          <v-expand-transition>
            <v-card flat class="my-0 py-0">
              <v-card-text class="my-0 py-0">
                <v-virtual-scroll
                  v-if="portResults.length > 0"
                  class="my-0 py-0"
                  :bench="0"
                  :items="portResults"
                  height="400"
                  item-height="50"
                >
                  <template v-slot:default="{ item }">
                    <v-list-item
                      :key="item.id"
                      @click="setPort('finalDestination', item)"
                    >
                      <v-list-item-avatar>
                        <v-avatar size="24" class="mr-1" left>
                          <v-img
                            contain
                            v-if="item.locode"
                            :src="`https://cdn.loglive.io/flags/4x3/${item.locode
                              .substring(0, 2)
                              .toLowerCase()}.svg`"
                          ></v-img>
                        </v-avatar>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title style="font-size: 14px">
                          {{ item.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle
                          style="font-size: 13px; color: grey"
                        >
                          {{ item.locode }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-virtual-scroll>
                <v-list-item v-else>
                  <v-list-item-content class="text-center">
                    <span style="color: grey; font-size: 13px"
                      >No results found</span
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-card-text>
            </v-card>
          </v-expand-transition>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Booking Office Modal -->
    <v-dialog
      v-model="bookingOfficeModal"
      width="400px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            <v-icon class="mr-2" small>location_on</v-icon> Manage Booking
            Office
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon color="redPop" @click="bookingOfficeModal = false">
            <v-icon small>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            class="my-0 py-0"
            autofocus
            prepend-inner-icon="search"
            dense
            placeholder="Search"
            outlined
            clearable
            v-model="bookingOfficeSearch"
            hide-details
          ></v-text-field>
          <v-progress-linear
            v-if="bookingOfficeLoading"
            color="primary"
            indeterminate
          ></v-progress-linear>
          <v-expand-transition>
            <v-card flat class="my-0 py-0">
              <v-card-text class="my-0 py-0">
                <v-virtual-scroll
                  v-if="bookingOfficePlaces.length > 0"
                  class="my-0 py-0"
                  :bench="0"
                  :items="bookingOfficePlaces"
                  height="400"
                  item-height="50"
                >
                  <template v-slot:default="{ item }">
                    <v-list-item :key="item.id" @click="setBookingOffice(item)">
                      <v-list-item-avatar>
                        <v-avatar size="24" class="mr-1" left>
                          <v-img
                            contain
                            v-if="item.locode"
                            :src="`https://cdn.loglive.io/flags/4x3/${item.locode
                              .substring(0, 2)
                              .toLowerCase()}.svg`"
                          ></v-img>
                        </v-avatar>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title style="font-size: 14px">
                          {{ item.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle
                          style="font-size: 13px; color: grey"
                        >
                          {{ item.locode }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-virtual-scroll>
                <v-list-item v-else>
                  <v-list-item-content class="text-center">
                    <span style="color: grey; font-size: 13px"
                      >No results found</span
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-card-text>
            </v-card>
          </v-expand-transition>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="caProductModal"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title style="font-size: 16px"
            >Set Controlled Atmosphere</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn icon color="redPop" @click="caProductModal = false">
            <v-icon small>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-autocomplete
            v-model="booking.caProduct"
            :items="caProducts"
            outlined
            dense
            placeholder="Select a CA Product"
            clearable
            @change="setCAProduct()"
            item-text="description"
            item-value="name"
          ></v-autocomplete>
          <v-text-field
            label="O²"
            outlined
            dense
            v-model="booking.oxygen"
            type="number"
            @input="setCASettings()"
            suffix="%"
          ></v-text-field>
          <v-text-field
            label="CO²"
            outlined
            dense
            v-model="booking.carbonDioxide"
            type="number"
            @input="setCASettings()"
            suffix="%"
          ></v-text-field>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="regimeCodeModal"
      width="500px"
      :fullscreen="$vuetify.breakpoint.mobile"
      peristent
    >
      <v-card :loading="regimeCodeLoading">
        <v-toolbar flat color="transparent">
          <v-toolbar-title style="font-size: 16px">
            Manage Regime Code
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip top v-if="booking.regime">
            <template v-slot:activator="{ on }">
              <v-btn icon color="danger" v-on="on" @click="removeRegime">
                <v-icon small> delete </v-icon>
              </v-btn>
            </template>
            <span>Remove Regime</span>
          </v-tooltip>
          <v-btn icon color="redPop" @click="regimeCodeModal = false">
            <v-icon small>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            placeholder="Search"
            autofocus
            prpend-inner-icon="search"
            v-model="regimeCodeSearch"
            outlined
            dense
            clearable
            hide-details
          ></v-text-field>
          <v-virtual-scroll
            v-if="filterRegimeCodes.length > 0"
            :bench="0"
            :items="filterRegimeCodes"
            height="500"
            item-height="75"
          >
            <template v-slot:default="{ item }">
              <v-list-item
                @click="setRegimeCode(item)"
                style="border-bottom: 0.5px solid grey"
              >
                <v-list-item-content>
                  <v-list-item-title style="font-size: 13px">
                    {{ item.code }}
                    <v-chip class="ml-2" outlined small v-if="item.steri">
                      <v-icon left color="blue" small>ac_unit</v-icon>Steri Code
                    </v-chip>
                  </v-list-item-title>
                  <v-list-item-subtitle style="font-size: 12px">
                    <v-icon class="mr-2" color="grey" small>thermostat</v-icon
                    >Set Point: {{ item.setPointTemp }}°C
                    <v-icon class="mx-2" color="red" small>thermostat</v-icon
                    >Max: {{ item.maxProductTemp }}°C
                    <v-icon class="mx-2" color="blue" small>thermostat</v-icon
                    >Min {{ item.minProductTemp }}°C
                  </v-list-item-subtitle>
                  <v-list-item-subtitle style="font-size: 12px">
                    {{ item.recipeDescription }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-virtual-scroll>
          <v-list dense style="max-height: 50vh; overflow-y: auto">
            <v-list-item v-if="filterRegimeCodes.length == 0">
              <v-list-item-content class="text-center">
                <span style="color: grey; font-size: 13px"
                  >No regime codes found</span
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="paymentModal"
      persistent
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title style="font-size: 16px"
            >Payment Details</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            icon
            :disabled="
              !paymentItem.chargeType ||
              !paymentItem.paymentTerm ||
              !paymentItem.partyId
            "
            @click="savePaymentItem"
            ><v-icon small>save</v-icon></v-btn
          >
          <v-btn
            icon
            color="redPop"
            @click="(paymentModal = false), (paymentItem = {})"
          >
            <v-icon small>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-select
            v-model="paymentItem.chargeType"
            outlined
            dense
            label="Charge Type*"
            item-text="value"
            item-value="key"
            :items="paymentTypes"
          ></v-select>
          <v-select
            v-model="paymentItem.paymentTerm"
            outlined
            dense
            label="Payment Term*"
            item-text="value"
            item-value="key" hide-details
            :items="paymentTerms"
          ></v-select>
          <!-- <v-select
            v-model="paymentItem.paymentParty"
            outlined
            dense
            label="Payer*"
            :items="availableParties"
          ></v-select> -->
          <v-list-item @click="payerModal = true">
            <v-list-item-action class="mr-2">
              <v-icon small>groups</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-if="paymentItem.party" style="font-size: 12px">
                {{ paymentItem.party.name }}
              </v-list-item-title>
               <v-list-item-title v-else>
               -
              </v-list-item-title>
              <v-list-item-subtitle style="font-size: 11px">
                Payment Party
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-autocomplete
            hide-no-data
            dense
            class="mt-2"
            outlined
            clearable
            :loading="paymentTownLoading"
            :search-input.sync="searchTowns"
            v-model="paymentItem.paymentLocation"
            :menu-props="{ closeOnContentClick: true }"
            label="Payment Location"
            :items="paymentTowns"
            item-text="name"
            item-value="locode"
            :filter="filterObject"
          >
            <template v-slot:selection="data">
              <span style="font-size: 13px"
                >{{ data.item.name }} ({{ data.item.locode }})</span
              >
            </template>
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ data.item.locode }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="shipmentProfileConfig"
      width="800px"
      :fullscreen="$vuetify.breakpoint.mobile"
      peristent
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title style="font-size: 16px">
            Set Profile Configuration
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon color="redPop" @click="shipmentProfileConfig = false">
            <v-icon small>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text v-if="booking.consigneeProfile">
          <v-list dense>
            <v-card
            class="my-2 py-2"
            style="background-color: var(--v-greyRaised-base) !important"
            v-for="term in booking.consigneeProfile.consigneeProfileIncoTerms" :key="term.id"
             :disabled="booking.contractPartyId && term.contractPartyId !== booking.contractPartyId"
              @click="setDealTerm(term)"
          >
            <v-card-text class="my-0 py-0">
              <v-row class="pa-1">
                <v-col cols="12" md="6">
                  <v-list dense subheader class="my-0 py-0">
                    <v-subheader style="font-size: 14px">Details</v-subheader>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-action class="mr-1">
                        <v-avatar
                          size="32"
                          :color="
                            term.contractParty &&
                            term.contractParty.logo
                              ? 'white'
                              : 'secondary'
                          "
                        >
                          <v-img
                            v-if="
                              term.contractParty &&
                              term.contractParty.logo
                            "
                            :src="term.contractParty.logo"
                            contain
                          />
                          <h3 v-else-if="term.contractParty">
                            {{ term.contractParty.name.charAt(0) }}
                          </h3>
                          <h3 v-else>-</h3>
                        </v-avatar>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                          v-if="term.contractParty"
                        >
                          <span
                            v-if="term.contractParty.alias"
                            style="font-size: 12px; font-weight: 500"
                            >{{ term.contractParty.alias }}</span
                          >
                          <span
                            v-else
                            style="font-size: 12px; font-weight: 500"
                            >{{ term.contractParty.name }}</span
                          >
                        </v-list-item-title>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                          v-else
                        >
                          -
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          Contract Owner
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item style="height: 30px">
                      <v-list-item-content>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                        >
                          {{ term.incoTerm }}
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          INCO Term
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 12px"
                          class="text-wrap"
                          v-if="term.comment"
                        >
                          {{ term.comment }}
                        </v-list-item-title>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 12px"
                          v-else
                        >
                          -
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          Notes
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="12" md="6">
                  <v-list dense subheader class="my-0 py-0">
                    <v-subheader style="font-size: 14px">Charges</v-subheader>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-action class="mr-1">
                        <v-avatar
                          size="32"
                          :color="
                            term.originParty &&
                            term.originParty.logo
                              ? 'white'
                              : 'secondary'
                          "
                        >
                          <v-img
                            v-if="
                              term.originParty &&
                              term.originParty.logo
                            "
                            :src="term.originParty.logo"
                            contain
                          />
                          <h3 v-else-if="term.originParty">
                            {{ term.originParty.name.charAt(0) }}
                          </h3>
                          <h3 v-else>-</h3>
                        </v-avatar>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                          v-if="term.originParty"
                        >
                          <span
                            v-if="term.originParty.alias"
                            style="font-size: 12px; font-weight: 500"
                            >{{ term.originParty.alias }}</span
                          >
                          <span
                            v-else
                            style="font-size: 12px; font-weight: 500"
                            >{{ term.originParty.name }}</span
                          >
                        </v-list-item-title>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                          v-else
                        >
                          -
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          {{ term.originTerm }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle style="font-size: 11px">
                          Origin Charges
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-action class="mr-1">
                        <v-avatar
                          size="32"
                          :color="
                            term.freightParty &&
                            term.freightParty.logo
                              ? 'white'
                              : 'secondary'
                          "
                        >
                          <v-img
                            v-if="
                              term.freightParty &&
                              term.freightParty.logo
                            "
                            :src="term.freightParty.logo"
                            contain
                          />
                          <h3 v-else-if="term.freightParty">
                            {{ term.freightParty.name.charAt(0) }}
                          </h3>
                          <h3 v-else>-</h3>
                        </v-avatar>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                          v-if="term.freightParty"
                        >
                          <span
                            v-if="term.freightParty.alias"
                            style="font-size: 12px; font-weight: 500"
                            >{{ term.freightParty.alias }}</span
                          >
                          <span
                            v-else
                            style="font-size: 12px; font-weight: 500"
                            >{{ term.freightParty.name }}</span
                          >
                        </v-list-item-title>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                          v-else
                        >
                          -
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          {{ term.paymentTerm }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle style="font-size: 11px">
                          Freight Charges
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-action class="mr-1">
                        <v-avatar
                          size="32"
                          :color="
                            term.destinationParty &&
                            term.destinationParty.logo
                              ? 'white'
                              : 'secondary'
                          "
                        >
                          <v-img
                            v-if="
                              term.destinationParty &&
                              term.destinationParty.logo
                            "
                            :src="term.destinationParty.logo"
                            contain
                          />
                          <h3 v-else-if="term.destinationParty">
                            {{ term.destinationParty.name.charAt(0) }}
                          </h3>
                          <h3 v-else>-</h3>
                        </v-avatar>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                          v-if="term.destinationParty"
                        >
                          <span
                            v-if="term.destinationParty.alias"
                            style="font-size: 12px; font-weight: 500"
                            >{{ term.destinationParty.alias }}</span
                          >
                          <span
                            v-else
                            style="font-size: 12px; font-weight: 500"
                            >{{ term.destinationParty.name }}</span
                          >
                        </v-list-item-title>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                          v-else
                        >
                          -
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          {{ term.destinationTerm }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle style="font-size: 11px">
                          Destination Charges
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="containerItemModal" width="1200">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title
            style="font-size: 16px"
            v-if="containerItem.type == 'CONTAINER'"
            >Manage Container Item</v-toolbar-title
          >
          <v-toolbar-title style="font-size: 16px" v-else
            >Manage Breakbulk Item</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-menu
          v-if="
             !containerItem.isCarrierHaulage &&
             (!containerItem.transportCoordinator)
          "
          open-on-hover
          bottom
          left
          offset-x
        >
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon color="grey">
              <v-icon small>save</v-icon>
            </v-btn>
          </template>
          <v-list
            dense
            subheader
            style="
              background: var(--v-component-base) !important;
              background-color: var(--v-component-base) !important;
            "
          >
            <v-subheader style="font-size: 14px">Validation Errors</v-subheader>
            <v-divider></v-divider>
            <v-list-item
              style="height: 30px"
              v-if="
                !containerItem.isCarrierHaulage &&
                (!containerItem.transportCoordinator)
              "
            >
              <v-list-item-action class="mr-1">
                <v-icon small color="redPop">fiber_manual_record</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title style="font-size: 12px"
                  >Transport Coordinator required</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
          <v-btn
            icon
            color="primary"
            @click="saveContainerItem"
            v-else
            :loading="savingContainerItem"
          >
            <v-icon small>save</v-icon>
          </v-btn>
          <v-btn
            v-if="containerItem.saved"
            icon
            color="red"
            @click="deleteContainerItem(containerItem.index)"
            ><v-icon small>delete</v-icon></v-btn
          >
          <v-btn icon color="redPop" @click="containerItemModal = false">
            <v-icon small>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container style="width: 100%">
            <v-row>
              <v-col cols="12" sm="4" class="my-0 py-0">
                <v-list-item style="height: 30px" class="mx-0 px-0">
                  <v-list-item-action class="mx-0">
                    <v-icon small>tag</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title style="font-size: 13px"
                      >Quantity</v-list-item-title
                    >
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-menu offset-y rounded>
                      <template v-slot:activator="{ on }">
                        <v-chip v-on="on" small outlined label>
                          <span style="text-transform: none">
                            {{ containerItem.quantity }}
                          </span>
                          <v-icon small right>expand_more</v-icon>
                        </v-chip>
                      </template>
                      <v-list
                        dense
                        subheader
                        style="max-height: 300px; overflow-y: auto"
                      >
                        <v-list-item
                          v-for="i in 20"
                          :key="i"
                          @click="containerItem.quantity = i"
                          style="height: 30px"
                        >
                          <v-list-item-content class="text-center">
                            <v-list-item-title>{{ i }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list-item-action>
                </v-list-item>
                <v-text-field
                  dense
                  outlined
                  v-model="containerItem.customerContainerRef"
                  hide-details
                  class="my-1 mx-0"
                  clearable
                >
                  <template v-slot:label>
                    <span style="font-size: 13px">Customer Ref </span>
                  </template>
                </v-text-field>
                <v-list-item
                  style="height: 30px"
                  class="mx-0 px-0"
                  v-if="booking.movementType == 'EXPORT'"
                >
                  <v-list-item-action class="mx-0">
                    <v-icon small>scale</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title style="font-size: 13px"
                      >VGM Method</v-list-item-title
                    >
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-menu offset-y rounded>
                      <template v-slot:activator="{ on }">
                        <v-chip v-on="on" small outlined label>
                          <v-icon small left>verified</v-icon>
                          <span style="text-transform: none">
                            {{ containerItem.verificationMethod }}
                          </span>
                          <v-icon small right>expand_more</v-icon>
                        </v-chip>
                      </template>
                      <v-list dense subheader>
                        <v-list-item
                          @click="containerItem.verificationMethod = 'Method 1'"
                        >
                          <v-list-item-content>
                            <v-list-item-title>Method 1</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                          @click="containerItem.verificationMethod = 'Method 2'"
                        >
                          <v-list-item-content>
                            <v-list-item-title>Method 2</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list-item-action>
                </v-list-item>
                <v-list
                  dense
                  v-if="containerItem.type == 'CONTAINER'"
                  class="my-0 py-0"
                >
                  <v-list-item style="height: 30px" class="mx-0 px-0">
                    <v-list-item-action class="mx-0">
                      <v-icon small>widgets</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title style="font-size: 13px"
                        >Container Type</v-list-item-title
                      >
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-menu offset-y rounded>
                        <template v-slot:activator="{ on }">
                          <v-chip v-on="on" small outlined label>
                            <span style="text-transform: none">
                              {{ containerItem.containerTypeCode }}
                            </span>
                            <v-icon small right>expand_more</v-icon>
                          </v-chip>
                        </template>
                        <v-list
                          dense
                          subheader
                          style="max-height: 500px; overflow-y: auto"
                        >
                          <v-list-item
                            v-for="(containerType, index) in filterContainerTypes"
                            :key="index"
                            @click="
                              containerItem.containerTypeCode =
                                containerType.typeCode
                            "
                          >
                            <v-list-item-content>
                              <v-list-item-title style="font-size: 12px">{{
                                containerType.shortDescription
                              }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-list-item-action>
                  </v-list-item>

                  <v-list v-if="displayReeferSettings" dense class="my-0 py-0">
                    <v-divider></v-divider>
                    <v-subheader style="font-size: 14px">
                      <v-icon class="mr-2" small>ac_unit</v-icon> Reefer
                      Settings</v-subheader
                    >
                    <v-divider></v-divider>
                    <v-list-item
                      style="height: 30px"
                      class="mx-0 px-0"
                      v-if="booking.serviceType != 'BREAKBULK'"
                    >
                      <v-list-item-action class="mx-0">
                        <v-icon small>air</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title style="font-size: 13px"
                          >Vent Settings</v-list-item-title
                        >
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-menu offset-y rounded>
                          <template v-slot:activator="{ on }">
                            <v-chip v-on="on" small outlined label>
                              <span style="text-transform: none">
                                {{ containerItem.vents }}
                              </span>
                              <v-icon small right>expand_more</v-icon>
                            </v-chip>
                          </template>
                          <v-list
                            dense
                            subheader
                            style="max-height: 500px; overflow-y: auto"
                          >
                            <v-list-item
                              v-for="(vent, index) in ventSettings"
                              :key="index"
                              @click="containerItem.vents = vent"
                            >
                              <v-list-item-content>
                                <v-list-item-title style="font-size: 12px">{{
                                  vent
                                }}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-list-item-action>
                    </v-list-item>
                    <v-text-field
                      dense
                      outlined
                      v-model="containerItem.setPointTemp"
                      hide-details
                      class="my-1 mx-0"
                      clearable
                    >
                      <template v-slot:label>
                        <span style="font-size: 13px">Set Temp (°C) </span>
                      </template>
                    </v-text-field>
                    <v-text-field
                      dense
                      outlined
                      v-model="containerItem.humidity"
                      hide-details
                      class="my-1 mx-0"
                      clearable
                    >
                      <template v-slot:label>
                        <span style="font-size: 13px">Humidity (%) </span>
                      </template>
                    </v-text-field>
                  </v-list>
                  <v-textarea v-model="containerItem.comment" outlined rows="3">
                    <template v-slot:label>
                      <span style="font-size: 13px">Notes </span>
                    </template>
                  </v-textarea>
                </v-list>
              </v-col>
              <v-col cols="12" sm="4" class="my-0 py-0">
                <v-list dense subheader>
                  <v-list dense subheader style="height: 150px">
                    <v-divider></v-divider>
                    <v-subheader style="font-size: 14px">
                      <v-icon small class="mr-2">list</v-icon>
                      Cargo Items
                      <v-btn
                        icon
                        color="primary"
                        @click="addContainerCargoDetails"
                      >
                        <v-icon>add_circle_outline</v-icon></v-btn
                      >
                    </v-subheader>
                    <v-divider></v-divider>

                    <v-list-item
                      v-if="
                        !containerItem.bookingCargoItems ||
                        (containerItem.bookingCargoItems &&
                          containerItem.bookingCargoItems.length == 0)
                      "
                    >
                      <v-list-item-content class="text-center">
                        <span style="color: grey">No products listed</span>
                      </v-list-item-content></v-list-item
                    >
                    <v-list-item
                      v-for="(cargo, index) in containerItem.bookingCargoItems"
                      :key="index"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          v-if="cargo.product"
                          style="font-size: 12px"
                        >
                          {{ cargo.product.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle
                          class="text-wrap"
                          style="font-size: 11px"
                        >
                          {{ cargo.hsCode }}
                          <span v-if="cargo.pallets">
                            - {{ cargo.pallets }} PALLETS</span
                          >
                          <span v-if="cargo.quantity">
                            - {{ cargo.quantity }}
                            {{ cargo.quantityType }}</span
                          >
                        </v-list-item-subtitle>
                        <v-list-item-subtitle
                          v-if="
                            cargo.grossWeight ||
                            cargo.nettWeight ||
                            cargo.volume
                          "
                          class="text-wrap"
                          style="font-size: 11px"
                        >
                          NETT {{ cargo.nettWeight }} KG - GROSS
                          {{ cargo.grossWeight }} KG - {{ cargo.volume }} CBM
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-row justify="center">
                          <v-btn icon small @click="editCargoItem(cargo)"
                            ><v-icon small>edit</v-icon></v-btn
                          >
                          <v-btn
                            icon
                            small
                            @click="deleteCargoItem(index)"
                            color="red"
                            ><v-icon small>delete</v-icon></v-btn
                          >
                        </v-row>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                  <v-list dense subheader style="height: 150px">
                    <v-divider></v-divider>
                    <v-subheader style="font-size: 14px">
                      <v-icon small class="mr-2">settings_suggest</v-icon>
                      Features
                    </v-subheader>
                    <v-divider></v-divider>
                    <v-list-item v-for="(feature, index) in containerFeatures" :key="index" style="height: 30px">
                      <v-list-item-action>
                        <v-btn icon
      outlined
      small :color="feature.color">
                        <v-icon small :color="feature.color">{{feature.icon}}</v-icon>
                        </v-btn>
                        </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title style="font-size: 12px">{{feature.name}}
                          </v-list-item-title>
                        </v-list-item-content
                        >
                        <v-list-item-action>
                          <v-switch dense hide-details v-model="selectedFeatures[feature.value]"></v-switch>
                        </v-list-item-action>
                      </v-list-item>
</v-list>
                  <v-subheader style="font-size: 14px"
                    ><v-icon small class="mr-2">sell</v-icon>Product Sales
                    <v-btn
                      icon
                      color="primary"
                      @click="addContainerProductService"
                      v-if="
                        availableContainerProducts.length > 0 ||
                        availableContainerServices.length > 0
                      "
                    >
                      <v-icon>add_circle_outline</v-icon>
                    </v-btn>
                  </v-subheader>
                  <v-divider></v-divider>
                  <v-list-item
                    v-if="
                      !containerItem.productServiceSales ||
                      containerItem.productServiceSales.length == 0
                    "
                  >
                    <v-list-item-content class="text-center">
                      <span style="color: grey; font-size: 12px"
                        >No products listed</span
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-for="product in containerItem.productServiceSales"
                    :key="product.id"
                  >
                    <v-list-item-action class="mr-1">
                      <v-avatar
                        class="mr-2"
                        size="30"
                        :color="!product.productService.icon ? 'secondary' : ''"
                      >
                        <v-img
                          v-if="product.productService.icon"
                          :src="product.productService.icon"
                          contain
                        ></v-img>
                        <h3 v-else>{{ product.productService.name.charAt(0) }}</h3>
                      </v-avatar>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title
                        style="font-size: 12px"
                        class="text-wrap"
                        >{{ product.productService.name }}</v-list-item-title
                      >
                      <v-list-item-subtitle
                        style="font-size: 11px"
                        v-if="product.productService.type == 'PRODUCT'"
                      >
                        <v-icon color="grey" class="mr-1 mb-1" small
                          >category</v-icon
                        >
                        Product</v-list-item-subtitle
                      >
                      <v-list-item-subtitle style="font-size: 11px" v-else>
                        <v-icon color="grey" class="mr-1 mb-1" small
                          >engineering</v-icon
                        >
                        Service</v-list-item-subtitle
                      >
                      <v-list-item-subtitle class="text-wrap" style="font-size: 11px" v-if="product.productService.productServiceCategory && product.productService.productServiceSubcategory">
                    {{ product.productService.productServiceCategory.name }} <v-icon x-small>chevron_right</v-icon> {{ product.productService.productServiceSubcategory.name }}
                  </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                    <v-chip small>{{ product.quantity }}
                      </v-chip>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col cols="12" sm="4" class="my-0 py-0">
                <v-list dense subheader :key="milestoneKey">
                  <v-list-item
                    @click="(partyType = 'Buyer'), (partyModal = true)"
                  >
                    <v-list-item-action>
                      <v-avatar
                        :color="
                          containerItem.buyer && containerItem.buyer.logo
                            ? 'white'
                            : 'secondary'
                        "
                        size="32"
                      >
                        <v-img
                          v-if="containerItem.buyer && containerItem.buyer.logo"
                          :src="containerItem.buyer.logo"
                          contain
                        ></v-img>
                        <h3
                          v-else-if="containerItem.buyer"
                          style="color: white"
                        >
                          {{
                            containerItem.buyer.name
                              ? containerItem.buyer.name.charAt(0)
                              : ""
                          }}
                        </h3>
                        <v-icon v-else small>shopping_cart</v-icon>
                      </v-avatar>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title
                        v-if="containerItem.buyer"
                        style="font-size: 12px"
                      >
                        {{ containerItem.buyer.name }}
                      </v-list-item-title>
                      <v-list-item-title v-else>-</v-list-item-title>
                      <v-list-item-subtitle style="font-size: 11px"
                        >Preliminary Buyer</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    @click="(partyType = 'Consignee'), (partyModal = true)"
                  >
                    <v-list-item-action>
                      <v-avatar
                        :color="
                          containerItem.consignee &&
                          containerItem.consignee.logo
                            ? 'white'
                            : 'secondary'
                        "
                        size="32"
                      >
                        <v-img
                          v-if="
                            containerItem.consignee &&
                            containerItem.consignee.logo
                          "
                          :src="containerItem.consignee.logo"
                          contain
                        ></v-img>
                        <h3
                          v-else-if="containerItem.consignee"
                          style="color: white"
                        >
                          {{
                            containerItem.consignee.name
                              ? containerItem.consignee.name.charAt(0)
                              : ""
                          }}
                        </h3>
                        <v-icon v-else small>call_received</v-icon>
                      </v-avatar>
                    </v-list-item-action>
                    <v-list-item-content :key="haulierKey">
                      <v-list-item-title
                        v-if="containerItem.consignee"
                        style="font-size: 12px"
                      >
                        {{ containerItem.consignee.name }}
                      </v-list-item-title>
                      <v-list-item-title v-else>-</v-list-item-title>
                      <v-list-item-subtitle style="font-size: 11px"
                        >Preliminary Consignee</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    @click="setSelectableParties(), (haulierModal = true)"
                    :key="haulierKey"
                  >
                    <v-list-item-action>
                      <v-avatar
                        :color="
                          containerItem.transportCoordinator &&
                          containerItem.transportCoordinator.logo
                            ? 'white'
                            : 'secondary'
                        "
                        size="32"
                      >
                        <v-img
                          v-if="
                            containerItem.transportCoordinator &&
                            containerItem.transportCoordinator.logo
                          "
                          :src="containerItem.transportCoordinator.logo"
                          contain
                        ></v-img>
                        <v-icon v-else small>local_shipping</v-icon>
                      </v-avatar>
                    </v-list-item-action>
                    <v-list-item-content :key="haulierKey">
                      <v-list-item-title
                        style="font-size: 12px"
                        v-if="containerItem.transportCoordinator"
                      >
                        {{ containerItem.transportCoordinator.name }}
                      </v-list-item-title>
                      <v-list-item-title
                        v-else-if="containerItem.isCarrierHaulage"
                        style="font-size: 12px"
                        >Carrier Haulage</v-list-item-title
                      >
                      <v-list-item-title
                        v-else-if="containerItem.isRailage"
                        style="font-size: 12px"
                        >Rail</v-list-item-title
                      >
                      <v-list-item-title v-else>-</v-list-item-title>
                      <v-list-item-subtitle
                        v-if="containerItem.preAdviseRequired"
                        ><v-chip color="blue" x-small
                          >Preadvise Required</v-chip
                        ></v-list-item-subtitle
                      >
                      <v-list-item-subtitle style="font-size: 11px"
                        >Transport Coordinator</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-subheader style="font-size: 14px"
                    >Pre-Carriage Legs / Loading Points
                  </v-subheader>
                  <v-divider></v-divider>
                  <v-list-item
                    v-for="(milestone, index) in availableMilestones.filter(
                      (x) => x.type == booking.movementType
                    )"
                    :key="index"
                  >
                    <v-list-item-action class="mr-0 pr-0">
                      <v-avatar
                        size="10"
                        v-if="containerItem[milestone.value]"
                        :color="
                          containerItem[milestone.value].isActual
                            ? 'success'
                            : 'secondary'
                        "
                      ></v-avatar>
                      <v-icon v-else color="grey" small>warning</v-icon>
                    </v-list-item-action>
                    <v-list-item-content v-if="containerItem[milestone.value]">
                      <v-list-item-title style="font-size: 12px">{{
                        containerItem[milestone.value].description
                      }}</v-list-item-title>
                      <v-list-item-subtitle style="font-size: 11px">{{
                        formatDateTime(
                          containerItem[milestone.value].date,
                          containerItem[milestone.value].time
                        )
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-content v-else>
                      <v-list-item-subtitle style="font-size: 12px">{{
                        milestone.name
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn
                        v-if="!containerItem[milestone.value]"
                        icon
                        small
                        color="primary"
                        @click="
                          addMilestone(containerItem, milestone.value, false)
                        "
                      >
                        <v-icon>add_circle_outline</v-icon>
                      </v-btn>
                      <v-row
                        justify="center"
                        v-if="containerItem[milestone.value]"
                      >
                        <v-btn
                          small
                          icon
                          @click="
                            addMilestone(containerItem, milestone.value, false)
                          "
                        >
                          <v-icon small>edit</v-icon>
                        </v-btn>
                        <v-btn
                          small
                          color="red"
                          icon
                          @click="removeMilestone(milestone.value)"
                        >
                          <v-icon small>delete</v-icon>
                        </v-btn>
                      </v-row>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="productServiceModal" width="1500px">
      <ProductServiceModal @close="productServiceModal=false" @selected="setProductSelection" :level="productServiceItem.level"/>
      <!-- <v-card>
        <v-toolbar flat dense>
          <v-toolbar-title style="font-size: 16px"
            >Add Products & Services</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn @click="productServiceModal = false" icon color="redPop">
            <v-icon small>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            class="mt-2"
            hide-details
            placeholder="Search"
            v-model="searchBookingProduct"
            dense
            outlined
            clearable
          ></v-text-field>
          <v-list dense class="my-1 py-0" :key="selectedProductKey">
            <v-list-item
              v-for="product in filterAvailableShipmentProducts"
              :key="product.id"
              @click="selectBookingProduct(product)"
              :style="{
                'border-left': product.selected
                  ? '4px solid var(--v-success-base)'
                  : '4px solid transparent',
              }"
            >
              <v-list-item-action class="mr-1">
                <v-avatar
                  class="mr-2"
                  size="30"
                  :color="!product.icon ? 'secondary' : ''"
                >
                  <v-img
                    v-if="product.icon"
                    :src="product.icon"
                    contain
                  ></v-img>
                  <h3 v-else>{{ product.name.charAt(0) }}</h3>
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title style="font-size: 12px" class="text-wrap">{{
                  product.name
                }}</v-list-item-title>
                <v-list-item-subtitle
                  style="font-size: 11px"
                  v-if="product.type == 'PRODUCT'"
                >
                  <v-icon color="grey" class="mr-1 mb-1" small>category</v-icon>
                  Product</v-list-item-subtitle
                >
                <v-list-item-subtitle style="font-size: 11px" v-else>
                  <v-icon color="grey" class="mr-1 mb-1" small
                    >engineering</v-icon
                  >
                  Service</v-list-item-subtitle
                >
                <v-list-item-subtitle style="font-size: 11px" class="text-wrap">
                  {{ product.description }}</v-list-item-subtitle
                >
              </v-list-item-content>
              <v-list-item-action>
                <v-icon v-if="product.selected" color="success"
                  >check_circle</v-icon
                >
              </v-list-item-action>
            </v-list-item>
            <v-list-item v-if="filterAvailableShipmentProducts.length == 0">
              <v-list-item-content class="text-center">
                <span style="color: grey; font-size: 12px"
                  >No products found</span
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card> -->
    </v-dialog>

    <v-dialog v-model="containerProductServiceModal" width="400px">
      <v-card>
        <v-toolbar flat dense>
          <v-toolbar-title style="font-size: 16px"
            >Add Products & Services</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn
            @click="containerProductServiceModal = false"
            icon
            color="redPop"
          >
            <v-icon small>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            class="mt-2"
            hide-details
            placeholder="Search"
            v-model="searchContainerProduct"
            dense
            outlined
            clearable
          ></v-text-field>
          <v-list dense class="my-1 py-0">
            <v-list-item
              v-for="product in filterAvailableContainerProducts"
              :key="product.id"
              @click="selectContainerProduct(product)"
              :style="{
                'border-left': selectedContainerProduct(product.id)
                  ? '4px solid var(--v-success-base)'
                  : '4px solid transparent',
              }"
            >
              <v-list-item-action class="mr-1">
                <v-avatar
                  class="mr-2"
                  size="30"
                  :color="!product.icon ? 'secondary' : ''"
                >
                  <v-img
                    v-if="product.icon"
                    :src="product.icon"
                    contain
                  ></v-img>
                  <h3 v-else>{{ product.name.charAt(0) }}</h3>
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title style="font-size: 12px" class="text-wrap">{{
                  product.name
                }}</v-list-item-title>
                <v-list-item-subtitle
                  style="font-size: 11px"
                  v-if="product.type == 'PRODUCT'"
                >
                  <v-icon color="grey" class="mr-1 mb-1" small>category</v-icon>
                  Product</v-list-item-subtitle
                >
                <v-list-item-subtitle style="font-size: 11px" v-else>
                  <v-icon color="grey" class="mr-1 mb-1" small
                    >engineering</v-icon
                  >
                  Service</v-list-item-subtitle
                >
                <v-list-item-subtitle style="font-size: 11px" class="text-wrap">
                  {{ product.description }}</v-list-item-subtitle
                >
              </v-list-item-content>
              <v-list-item-action>
                <v-icon v-if="product.selected" color="success"
                  >check_circle</v-icon
                >
              </v-list-item-action>
            </v-list-item>
            <v-list-item v-if="filterAvailableContainerProducts.length == 0">
              <v-list-item-content class="text-center">
                <span style="color: grey; font-size: 12px"
                  >No products found</span
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="haulierModal"
      width="900px"
      :fullscreen="$vuetify.breakpoint.mobile"
      persistent
    >
      <v-card>
        <v-toolbar flat dense>
          <v-toolbar-title style="font-size: 16px">
            Manage Haulier
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon color="redPop" @click="haulierModal = false">
            <v-icon small>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6">
              <v-list style="max-height: 50vh; overflow-y: auto" dense>
                <v-subheader style="font-size: 14px"
                  >Transport Type</v-subheader
                >
                <v-divider></v-divider>
                <v-list-item
                  v-for="haulier in hauliers"
                  :key="haulier.index"
                  @click="setHaulier(haulier)"
                >
                  <v-list-item-action>
                    <v-avatar color="white" size="36">
                      <v-img
                        v-if="haulier.logo"
                        :src="haulier.logo"
                        contain
                      ></v-img>
                      <v-icon v-else color="black">{{ haulier.icon }}</v-icon>
                    </v-avatar>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title style="font-size: 12px">{{
                      haulier.name
                    }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      haulier.subtitle
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon color="success" v-if="containerItem[haulier.flag]"
                      >check_circle_outline</v-icon
                    >
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-col>

            <v-col cols="12" sm="6">
              <v-list
                style="max-height: 50vh; overflow-y: auto"
                dense
                :key="preadviseKey"
              >
                <v-subheader style="font-size: 16px"
                  >Transport Coordinator</v-subheader
                >
                <v-divider></v-divider>
              </v-list>
              <v-select
                :items="selectableParties"
                :disabled="containerItem.isCarrierHaulage"
                :placeholder="
                  containerItem.isCarrierHaulage
                    ? 'Select Coordinator'
                    : 'Not Applicable'
                "
                item-text="name"
                item-value="id"
                rounded
                style="background-color: #2f2f2f; margin: 1vh 1vw 0.5vh 1vw"
                hide-details
                outlined
                return-object
                v-model="containerItem.transportCoordinator"
                @change="setTransporter"
                clearable
                dense
              >
                <template v-slot:selection="data">
                  <v-row
                    align="center"
                    v-if="
                      containerItem.isPrivateHaulage || containerItem.isRailage
                    "
                  >
                    <v-avatar size="42" class="mr-2" v-if="data.item">
                      <v-img
                        v-if="data.item.icon"
                        contain
                        :src="data.item.icon"
                      ></v-img>
                    </v-avatar>
                    <span v-if="data.item">
                      {{ data.item.name }}
                    </span>
                  </v-row>
                </template>
                <template v-slot:item="data">
                  <v-list-item-avatar>
                    <v-img
                      contain
                      v-if="data.item.logo"
                      :src="data.item.logo"
                    ></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-select>
              <v-list-item
                dense
                v-if="booking.movementType == 'EXPORT'"
                class="mt-2"
              >
                <v-list-item-action>
                  <v-icon color="secondary">article</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title> Pre-Advise </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action :key="preadviseKey">
                  <v-switch
                    color="success"
                    v-model="containerItem.preAdviseRequired"
                  ></v-switch>
                </v-list-item-action>
              </v-list-item>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="addMilestoneModal" width="500px">
      <ManageContainerMilestone
        :item="milestoneItem"
        :key="milestoneKey"
        :bookingType="booking.movementType"
        @close="addMilestoneModal = false"
        @unsavedItem="setMilestonePoint"
      />
    </v-dialog>

    <!-- Edit Cargo -->
    <v-dialog
      v-model="cargoModal"
      persistent
      width="450px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Cargo Details</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            color="primary"
            :disabled="
              !cargoItem.product || !cargoItem.hsCode || !cargoItem.description
            "
            @click="saveCargoItem"
            ><v-icon small>save</v-icon></v-btn
          >
          <v-btn
            icon
            color="redPop"
            @click="(cargoItem = {}), (cargoModal = false)"
          >
            <v-icon small>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-select
                hide-details
                class="my-2"
                label="Type"
                :items="['Product Group', 'Product', 'Variety']"
                v-model="filterProductType"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-autocomplete
                class="my-2"
                hide-details
                ref="countrySelect"
                autofocus
                hide-no-data
                hide-selected
                label="Product"
                dense
                outlined
                :items="filterProducts"
                clearable
                item-text="name"
                item-value="id"
                :menu-props="{ closeOnContentClick: true }"
                @change="setCargoItemDetails"
                v-model="cargoItem.product"
                return-object
                :loading="loadingProduct"
              >
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ data.item.type }}</v-list-item-subtitle
                    >
                    <v-list-item-subtitle
                      v-if="data.item.parentProduct"
                      style="font-size: 12px"
                    >
                      Parent: {{ data.item.parentProduct.name }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" class="my-0 py-0">
              <v-textarea
                hide-details
                class="my-2"
                rows="3"
                label="Description"
                v-model="cargoItem.description"
                outlined
              ></v-textarea>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-text-field
                hide-details
                class="my-2"
                label="HS Code"
                v-model="cargoItem.hsCode"
                outlined
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-text-field
                hide-details
                class="my-2"
                label="No Pallets"
                type="number"
                v-model="cargoItem.pallets"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-text-field
                hide-details
                class="my-2"
                label="Nett Weight"
                suffix="Kg"
                v-model="cargoItem.nettWeight"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-text-field
                hide-details
                class="my-2"
                label="Gross Weight"
                suffix="Kg"
                v-model="cargoItem.grossWeight"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-text-field
                hide-details
                class="my-2"
                label="Cubic measure"
                suffix="CBM"
                v-model="cargoItem.volume"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-text-field
                hide-details
                class="my-2"
                label="Batch Number"
                v-model="cargoItem.batchNumber"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-select
                hide-details
                class="my-2"
                label="Quantity Type"
                clearable
                v-model="cargoItem.quantityTypeCode"
                outlined
                dense
                item-text="text"
                item-value="value"
                :items="productQuantityTypes"
                @change="selectQuantityType()"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-text-field
                hide-details
                class="my-2"
                :label="`Quantity`"
                v-model="cargoItem.quantity"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-switch
                label="Industrial Use"
                dense
                hide-details
                v-model="cargoItem.industrial"
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-switch
                label="Organic"
                dense
                hide-details
                v-model="cargoItem.organic"
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="6" class="my-0 py-0">
              <v-switch
                label="Hazardous"
                dense
                v-model="cargoItem.hazardous"
                @change="updateHazardousStatus"
              ></v-switch>
            </v-col>
          </v-row>
          <v-list dense subheader v-if="cargoItem.hazardous">
            <v-divider></v-divider>
            <v-subheader style="font-size: 16px">Hazardous Details</v-subheader>
            <v-divider></v-divider>
            <v-row class="mt-2">
              <v-col
                cols="12"
                sm="6"
                class="my-0 py-0"
                v-if="cargoItem.hazardous"
              >
                <v-text-field
                  hide-details
                  class="my-2"
                  label="UN Code"
                  v-model="cargoItem.unCode"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                class="my-0 py-0"
                v-if="cargoItem.hazardous"
              >
                <v-text-field
                  hide-details
                  class="my-2"
                  label="UN Packing Group"
                  v-model="cargoItem.unCodePackingGroup"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                class="my-0 py-0"
                v-if="cargoItem.hazardous"
              >
                <v-text-field
                  hide-details
                  class="my-2"
                  :label="`Hazard Class`"
                  v-model="cargoItem.class"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="shippingLineModal"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card :loading="loadingCarriers">
        <v-toolbar flat color="transparent">
          <v-toolbar-title style="font-size: 16px"
            >Set Carrier</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn icon color="redPop" @click="shippingLineModal = false">
            <v-icon small>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            dense
            outlined
            rounded
            prepend-inner-icon="search"
            autofocus
            placeholder="Search"
            clearable
            v-model="searchShippingLine"
          ></v-text-field>
          <v-list dense style="max-height: 50vh; overflow-y: auto">
            <v-list-item
              v-for="carrier in filterShippingLines"
              :key="carrier.id"
              @click="setShippingLine(carrier)"
            >
              <v-list-item-action>
                <v-avatar v-if="carrier.logo" color="white">
                  <v-img :src="carrier.logo" contain></v-img>
                </v-avatar>
                <v-avatar v-else color="secondary">
                  <h3>{{ carrier.name ? carrier.name.charAt(0) : "" }}</h3>
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ carrier.name }}</v-list-item-title>
                <v-list-item-subtitle>{{
                  carrier.friendlyName
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="contractModal"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
      persistent
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title style="font-size: 16px">
            Set Contract
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon color="redPop" @click="booking.contractNumber = contractNumber, contractModal = false">
            <v-icon small>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text :key="contractKey">
          <v-row justify="center">
            <v-col cols="12">
              <v-list dense style="max-height: 50vh; overflow-y: auto">
                <!-- <v-subheader style="font-size: 14px"
                  >Manual Contract Entry</v-subheader
                > -->
                <!-- <v-divider></v-divider> -->
                <!-- <v-select
                  class="my-1 mx-1"
                  outlined
                  dense
                  label="Contract Owner"
                  :items="chargeOptions"
                  v-model="booking.contractOwner"
                  hide-details
                  item-text="name"
                  item-value="value"
                  style="text-transform: capitalize"
                >
                  <template v-slot:label>
                    <span style="font-size: 13px"> Contract Owner </span>
                  </template>
                </v-select> -->
                <v-list-item @click="contractOwnerModal = true">
                      <v-list-item-action class="mr-1">
                        <v-avatar
                          size="32"
                          :color="
                            booking.contractParty &&
                            booking.contractParty.logo
                              ? 'white'
                              : 'secondary'
                          "
                        >
                          <v-img
                            v-if="
                              booking.contractParty &&
                              booking.contractParty.logo
                            "
                            :src="booking.contractParty.logo"
                            contain
                          />
                          <h3 v-else-if="booking.contractParty">
                            {{ booking.contractParty.name.charAt(0) }}
                          </h3>
                          <h3 v-else>-</h3>
                        </v-avatar>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                          v-if="booking.contractParty"
                        >
                          <span
                            v-if="booking.contractParty.alias"
                            style="font-size: 12px; font-weight: 500"
                            >{{ booking.contractParty.alias }}</span
                          >
                          <span
                            v-else
                            style="font-size: 12px; font-weight: 500"
                            >{{ booking.contractParty.name }}</span
                          >
                        </v-list-item-title>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                          v-else
                        >
                          -
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          Contract Owner
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                <v-text-field
                  class="my-3 mx-1"
                  label="Contract Number"
                  outlined
                  dense
                  clearable
                  hide-details
                  v-model="contractNumber"
                >
                  <template v-slot:label>
                    <span style="font-size: 13px"> Contract Number </span>
                  </template></v-text-field
                >
              </v-list>
            </v-col>
            <!-- <v-divider vertical></v-divider> -->
            <!-- <v-col cols="12" sm="7">
              <v-list dense style="max-height: 50vh; overflow-y: auto">
                <v-subheader style="font-size: 14px"
                  >Available System Contracts</v-subheader
                >
                <v-divider></v-divider>
                <v-text-field
                  outlined
                  dense
                  prepend-inner-icon="search"
                  v-model="searchContract"
                  clearable
                ></v-text-field>
                <v-list dense style="max-height: 50vh">
                  <v-list-item v-if="filterSuggestedContracts.length == 0">
                    <v-list-item-content class="text-center">
                      <span style="font-size: 12px; color: grey"
                        >No Contracts found</span
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-list-item
                  v-for="contract in filterSuggestedContracts"
                  :key="contract.id"
                  @click="setContract(contract)"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ contract.contracts[0].contractNo }}
                    </v-list-item-title>
                    <v-list-item-subtitle v-if="contract.contracts[0]">
                      <v-icon color="grey" small class="mr-2">tag</v-icon>
                      {{ contract.alias }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="contract.contracts[0]">
                      <v-icon small color="grey" class="mr-2">event</v-icon>
                      <span>{{ contract.contracts[0].startDate }}</span>
                      <v-icon x-small class="mr-1">arrow_forward</v-icon>
                      <span>{{ contract.contracts[0].endDate }}</span>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="contract.contractOwner">
                      <v-icon small color="grey" class="mr-2">group</v-icon>
                      <span v-if="contract.contractOwner.alias">{{
                        contract.contractOwner.alias
                      }}</span>
                      <span v-else>{{ contract.contractOwner.name }}</span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item></v-list-item>
              </v-list>
            </v-col> -->
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="sailingScheduleError"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-card-text class="text-left mt-3 pt-5">
          <p style="font-size: 16px">
            Please select make sure to select the following before continuing:
          </p>
          <ul>
            <li v-if="!booking.portOfLoadValue">Port of Load (POL)</li>
            <li v-if="!booking.portOfDischargeValue">
              Port of Discharge (POD)
            </li>
          </ul>
          <v-row justify="center" class="mt-5 mb-3">
            <v-btn
              color="blue"
              class="mx-2"
              text
              small
              @click="sailingScheduleError = false"
              >Okay</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="sailingScheduleModal"
      persistent
      width="90vw"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title style="font-size: 16px">
            <v-icon class="mr-2" color="secondary">directions_boat</v-icon>
            Search Sailing Schedules
          </v-toolbar-title>
          <v-chip
            v-if="booking.portOfLoadValue"
            small
            outlined
            style="border: none"
            class="ml-2"
          >
            <v-avatar size="24" left>
              <v-img
                contain
                v-if="booking.portOfLoadValue"
                :src="`https://cdn.loglive.io/flags/4x3/${booking.portOfLoadValue
                  .substring(0, 2)
                  .toLowerCase()}.svg`"
              ></v-img>
            </v-avatar>
            {{ booking.portOfLoadValue }}
          </v-chip>

          <v-icon class="mx-3" color="grey" small>east</v-icon>
          <v-chip
            v-if="booking.portOfDischargeValue"
            small
            outlined
            style="border: none"
          >
            <v-avatar size="24" left>
              <v-img
                contain
                v-if="booking.portOfDischargeValue"
                :src="`https://cdn.loglive.io/flags/4x3/${booking.portOfDischargeValue
                  .substring(0, 2)
                  .toLowerCase()}.svg`"
              ></v-img>
            </v-avatar>
            {{ booking.portOfDischargeValue }}
          </v-chip>
          <v-spacer></v-spacer>
          <v-btn icon color="redPop" @click="sailingScheduleModal = false">
            <v-icon small>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <ScheduleModal
            :export="booking.movementType == 'EXPORT'"
            :portOfLoadValue="booking.portOfLoadValue"
            :finalDestinationValue="booking.portOfDischargeValue"
            :shippingLines="shippingLines"
            @select="selectSchedule"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="voyageNumberModal"
      persistent
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat dense color="transparent">
          <v-spacer></v-spacer>
          <v-btn icon color="redPop" @click="voyageNumberModal = false">
            <v-icon small>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            :label="booking.modeOfTransport == 'AIR' ? 'Flight No' : 'Voyage No'"
            v-model="booking.mainCarriageConveyanceNumber"
            outlined
            dense
            clearable
          ></v-text-field>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="contractOwnerModal" width="500px" persistent>
      <v-card>
        <v-toolbar flat dense>
          <v-toolbar-title style="font-size: 16px"> Contract Owner </v-toolbar-title>
          <v-spacer />
          <v-btn icon color="redPop" @click="contractOwnerModal = false">
            <v-icon small>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            prepend-inner-icon="search"
            placeholder="Search"
            v-model="searchContractOwner"
            hide-details
            outlined
            dense
            autofocus
            clearable
          ></v-text-field>
          <v-list dense subheader style="max-height: 400px; overflow-y: auto">
            <div v-for="(item, index) in availableContractParties" :key="index">
              <div v-if="item.type == 'SUBHEADER'">
                <v-divider></v-divider>
                <v-subheader style="font-size: 14px">
                  {{ item.name }}
                </v-subheader>
                <v-divider></v-divider>
              </div>
              <v-list-item
                v-else
                @click="setContractOwner(item)"
              >
                <v-list-item-action>
                  <v-avatar
                    :color="item.logo ? 'white' : 'secondary'"
                    size="32"
                  >
                    <v-img v-if="item.logo" :src="item.logo" contain />
                    <h3 v-else>
                      {{ item.name.charAt(0) }}
                    </h3>
                  </v-avatar>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.type }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="payerModal" width="500px" persistent>
      <v-card>
        <v-toolbar flat dense>
          <v-toolbar-title style="font-size: 16px"> Payment Party </v-toolbar-title>
          <v-spacer />
          <v-btn icon color="redPop" @click="payerModal = false">
            <v-icon small>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            prepend-inner-icon="search"
            placeholder="Search"
            v-model="searchPayer"
            outlined
            dense
            autofocus
            hide-details
            clearable
          ></v-text-field>
          <v-list dense subheader style="max-height: 400px; overflow-y: auto">
            <div v-for="(item, index) in availablePayerParties" :key="index">

              <div v-if="item.type == 'SUBHEADER'">
                <v-divider></v-divider>
                <v-subheader style="font-size: 14px">
                  {{ item.name }}
                </v-subheader>
                <v-divider></v-divider>
              </div>
              <v-list-item
                v-else
                @click="setPaymentParty(item)"
              >
                <v-list-item-action>
                  <v-avatar
                    :color="item.logo ? 'white' : 'secondary'"
                    size="32"
                  >
                    <v-img v-if="item.logo" :src="item.logo" contain />
                    <h3 v-else>
                      {{ item.name.charAt(0) }}
                    </h3>
                  </v-avatar>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.type }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
    <script>
/* eslint-disable vue/no-unused-components */
import { AgGridVue } from "ag-grid-vue";
import dateFormat from "dateformat";
import ManageContainerMilestone from "./BookingComponents/ManageContainerMilestone.vue";
import ProductServiceModal from '../ProductServices/ProductServiceModal.vue'
import ScheduleModal from "../Bookings/Dialogs/SailingSchedule.vue";
import SearchShipmentProfiles from "@/components/SystemComponents/SearchShipmentProfile.vue";
export default {
  components: {
    AgGridVue,
    ManageContainerMilestone,
    ProductServiceModal,
    ScheduleModal,
    SearchShipmentProfiles,
  },
  props: {
    setBooking: {
      type: Object,
      required: false,
    },
    organisations: {
      type: Array,
      required: false,
    },
  },
  data: () => ({
    airlines: [],
    addMilestoneModal: false,
    allOriginPorts: [],
    allDestinationPorts: [],
    availableContainerProducts: [],
    availableContainerServices: [],
    availableShipmentProducts: [],
    availableShipmentServices: [],
    availableMilestones: [
      { value: "EMPTY_PICKUP", type: "EXPORT", name: "Empty Pickup" },
      { value: "LOADING_POINT_1", type: "EXPORT", name: "Loading Point 1" },
      { value: "LOADING_POINT_2", type: "EXPORT", name: "Loading Point 2" },
      { value: "WEIGH_BRIDGE", type: "EXPORT", name: "Weigh Bridge" },
      { value: "FULL_DROP_OFF", type: "EXPORT", name: "Port Holding" },

      { value: "FULL_PICKUP", type: "LOCAL", name: "Full Pickup" },
      { value: "DELIVERY_POINT_1", type: "LOCAL", name: "Delivery Point 1" },
      { value: "DELIVERY_POINT_2", type: "LOCAL", name: "Delivery Point 2" },
      { value: "DELIVERY_POINT_3", type: "LOCAL", name: "Delivery Point 3" },
      { value: "EMPTY_DROP_OFF", type: "LOCAL", name: "Empty Drop Off" },

      { value: "FULL_PICKUP", type: "IMPORT", name: "Full Pickup" },
      { value: "DELIVERY_POINT_1", type: "IMPORT", name: "Delivery Point 1" },
      { value: "DELIVERY_POINT_2", type: "IMPORT", name: "Delivery Point 2" },
      { value: "DELIVERY_POINT_3", type: "IMPORT", name: "Delivery Point 3" },
      { value: "EMPTY_DROP_OFF", type: "IMPORT", name: "Empty Drop Off" },
    ],
    booking: {},
    bookingOfficeModal: false,
    bookingOfficePlaces: [],
    bookingOfficeSearch: null,
    bookingOfficeLoading: false,
    caProductModal: false,
    caProducts: [
      {
        shippingLine: "HPL",
        name: "EXTRAFRESH PLUS",
        description: "EXTRAFRESH PLUS",
        oxygen: 4,
        carbonDioxide: 6,
      },
      {
        shippingLine: "MSK",
        name: "STARCARE",
        description: "STARCARE",
        oxygen: 4,
        carbonDioxide: 6,
      },
      {
        shippingLine: "MSK",
        name: "STARCOOL",
        description: "STARCOOL",
        oxygen: 4,
        carbonDioxide: 6,
      },
      {
        shippingLine: "MSK",
        name: "LIVENTUS",
        description: "LIVENTUS",
        oxygen: 8,
        carbonDioxide: 15,
      },
      {
        shippingLine: "ONE",
        name: "MAXTEND",
        description: "MAXTEND",
        oxygen: 5,
        carbonDioxide: 10,
      },
      {
        shippingLine: "MSC",
        name: "XTENDFR A",
        description: "XTENDFRESH AVOCADOS",
        oxygen: 4,
        carbonDioxide: 6,
      },
      {
        shippingLine: "HPL",
        name: "EXTRAFR B",
        description: "EXTRAFR BERRIES",
        oxygen: 4,
        carbonDioxide: 12,
      },
    ],
    cargoItem: {},
    cargoKey: 1100,
    cargoModal: false,
    chargeOptions: [
      { name: "Buyer", value: "buyer" },
      { name: "Forwarder", value: "forwarder" },
      { name: "Shipper", value: "shipper" },
      { name: "Consignee", value: "consignee" },
      { name: "1st Notify Party", value: "firstNotify" },
      { name: "2nd Notify Party", value: "secondNotify" },
      { name: "On Behalf of Shipper", value: "onBehalfShipper" },
      { name: "Customer", value: "customer" },
    ],
    contractOwnerModal: false,
    contractNumber: null,
    columns: [
      {
        headerName: "Quantity",
        field: "quantity",
        editable: true,
      },
      {
        headerName: "Unit",
        field: "containerTypeCode",
        editable: true,
      },
      {
        headerName: "VGM Method",
        field: "verificationMethod",
        editable: true,
      },
      {
        headerName: "Vents",
        field: "vents",
        editable: true,
      },
      {
        headerName: "Set Point Temp (C)",
        field: "setPointTemp",
        editable: true,
      },
    ],
    containerFeatures: [],
    containerItem: {},
    containerItemModal: false,
    containerProductServiceModal: false,
    containerTypes: [],
    contractKey: 200,
    contractModal: false,
    countryType: null,
    countryModal: false,
    countries: [],
    customerSearchModal: false,
    destinationPortKey: 500,
    filterProductType: "Product",
    gridApi: null,
    haulierModal: false,
    haulierKey: 800,
    incoTermModal: false,
    incoTerms: [],
    loadingCarriers: false,
    loadingLinkedOrganisations: false,
    linkedOrganisations: [],
    loadingOriginPorts: false,
    loadingDestinationPorts: false,
    loadingProduct: false,
    loadingPortSearch: false,
    milestoneItem: {},
    milestoneKey: 700,
    originPortKey: 400,
    partyModal: false,
    partyType: null,
    partyTypes: [
      {
        name: "Bank",
        value: "bank",
        allowLink: false,
        icon: "account_balance",
        conditionalFields: ["letterOfCredit"],
      },
      {
        name: "Shipper",
        value: "shipper",
        allowLink: true,
        icon: "directions_boat_filled",
        linkedValue: "onBehalfShipper",
      },
      {
        name: "Forwarder",
        value: "forwarder",
        allowLink: true,
        icon: "fast_forward",
        linkedValue: "onBehalfForwarder",
      },
      {
        name: "Buyer",
        value: "buyer",
        allowLink: true,
        icon: "shopping_cart",
        linkedValue: "onBehalfBuyer",
      },
      {
        name: "Consignee",
        value: "consignee",
        allowLink: true,
        icon: "local_shipping",
        linkedValue: "onBehalfConsignee",
      },
      {
        name: "Courier",
        value: "courierParty",
        allowLink: false,
        icon: "local_post_office",
      },
      {
        name: "Notify",
        value: "firstNotify",
        allowLink: false,
        icon: "notifications",
      },
      {
        name: "2nd Notify Party",
        value: "secondNotify",
        allowLink: false,
        icon: "notifications",
      },
      {
        name: "Customer",
        value: "customer",
      },
      {
        name: "Contract Party",
        value: "contractParty",
      },
    ],
    paymentItem: {},
    paymentKey: 300,
    paymentModal: false,
    paymentTypes: [
      { key: "Additional", value: "Additional Charges" },
      { key: "OceanFreight", value: "Basic Freight" },
      { key: "DestinationHaulage", value: "Destination Haulage Charges" },
      { key: "DestinationTerminalHandling", value: "Destination Port Charges" },
      { key: "OriginTerminalHandling", value: "Origin Haulage Charges" },
      { key: "OriginHaulage", value: "Origin Port Charges" },
    ],
    paymentTerms: [
      { key: "Prepaid", value: "Pre-paid" },
      { key: "Collect", value: "Collect" },
      { key: "PayableElsewhere", value: "Payable Elsewhere" },
    ],
    payerModal: false,
    paymentTowns: [],
    paymentTownTimeout: null,
    paymentTownQuery: null,
    paymentTownLoading: false,
    portResults: [],
    portSearch: null,
    portSearchQuery: null,
    portSearchTimeout: null,
    polModal: false,
    podModal: false,
    fdModal: false,
    preadviseKey: 1000,
    products: {
      data: [],
    },
    productServices: [],
    productServiceLoading: false,
    productServiceItem: {},
    productServiceModal: false,
    productServiceTab: "PRODUCT",
    profileDetail: null,
    profileModal: false,
    profileKey: 100,
    regimeCodeModal: false,
    regimeCodes: [],
    regimeCodeLoading: false,
    regimeKey: 600,
    regimeCodeSearch: null,
    sailingScheduleError: false,
    sailingScheduleModal: false,
    savingBooking: false,
    savingContainerItem: false,
    searchBookingProduct: null,
    searchContainerProduct: null,
    searchContract: null,
    searchContractOwner: null,
    searchCountries: null,
    searchCustomer: null,
    searchOrganisation: null,
    searchOrigin: null,
    searchDestination: null,
    searchPayer: null,
    searchShippingLine: null,
    searchTowns: null,
    selectedCarriers: [],
    selectedFeatures: {},
    selectableParties: [],
    selectedProductKey: 900,
    selectedQuantityType: { value: "CT", text: "Carton" },
    productQuantityTypes: [
      { value: "BG", text: "Bag" },
      { value: "TT", text: "Bag, tote" },
      { value: "BA", text: "Barrel" },
      { value: "BL", text: "Bale" },
      { value: "BI", text: "Bin" },
      { value: "BX", text: "Box" },
      { value: "VL", text: "Bulk, liquid" },
      { value: "VR", text: "Bulk, solid" },
      { value: "BH", text: "Bunch" },
      { value: "BE", text: "Bundle" },
      { value: "CT", text: "Carton" },
      { value: "CS", text: "Case" },
      { value: "CN", text: "Container" },
      { value: "CR", text: "Crate" },
      { value: "DR", text: "Drum" },
      { value: "FB", text: "Flexibag" },
      { value: "JT", text: "Jutebag" },
      { value: "LU", text: "Lug" },
      { value: "PK", text: "Package" },
      { value: "PA", text: "Packet" },
      { value: "PX", text: "Pallet" },
      { value: "PT", text: "Pot" },
      { value: "PO", text: "Pouch" },
      { value: "RO", text: "Roll" },
      { value: "SH", text: "Sachet" },
      { value: "SA", text: "Sack" },
      { value: "SI", text: "Skid" },
      { value: "PU", text: "Tray" },
      { value: "VI", text: "Vial" },
    ],
    selectedTransportModes: ["Port"],
    settingShipmentProfile: false,
    shippingLineModal: false,
    shipmentProfileConfig: false,
    shippingLines: [],
    suggestedContracts: [],
    suggestedDealTerms: [],
    transportModes: ["Rail", "Road", "Port", "Airport"],
    ventSettings: [],
    vesselModal: false,
    voyageNumberModal: false,
  }),
  watch: {
    async bookingOfficeSearch(val) {
      if (this.bookingOfficeLoading) {
        clearTimeout(this.bookingOfficeTimeout);
      }
      if (val && val.length > 2) {
        if (this.bookingOfficeLoading && this.bookingOfficeQuery) {
          this.bookingOfficeQuery.abort();
        }
        this.bookingOfficeLoading = true;
        let params = {
          search: val,
        };
        if (this.booking.originCountry) {
          params.filter = {
            countryCode: this.booking.originCountry.iso2,
          };
        }

        this.bookingOfficeTimeout = setTimeout(async () => {
          this.bookingOfficeQuery = new AbortController();
          const signal = this.bookingOfficeQuery.signal;
          this.bookingOfficePlaces = await this.$API.searchTowns({
            params: params,
            signal,
          });
          this.bookingOfficeLoading = false;
        }, 500);
      } else {
        clearTimeout(this.bookingOfficeTimeout);
        this.bookingOfficeLoading = false;
      }
    },
    searchTowns(val) {
      if (this.paymentTownLoading) {
        clearTimeout(this.paymentTownTimeout);
      }
      if (val && val.length > 2) {
        if (this.paymentTownLoading && this.paymentTownQuery) {
          this.paymentTownQuery.abort();
        }
        this.paymentTownLoading = true;
        this.paymentTownTimeout = setTimeout(async () => {
          this.paymentTownQuery = new AbortController();
          const signal = this.paymentTownQuery.signal;
          this.paymentTowns = await this.$API.searchTowns({
            params: {
              search: val,
            },
            signal,
          });
          this.paymentTownLoading = false;
        }, 250);
      } else {
        clearTimeout(this.paymentTownTimeout);
        this.paymentTownLoading = false;
      }
    },
    async portSearch(val) {
      if (this.loadingPortSearch) {
        clearTimeout(this.portSearchTimeout);
      }
      if (this.loadingPortSearch && this.portSearchQuery) {
        this.portSearchQuery.abort();
      }
      this.loadingPortSearch = true;
      let params = {
        search: val,
        portType: this.selectedTransportModes,
      };
      this.portSearchTimeout = setTimeout(async () => {
        this.portSearchQuery = new AbortController();
        const signal = this.portSearchQuery.signal;
        this.portResults = await this.$API.searchTowns({
          params: params,
          signal,
        });
        this.loadingPortSearch = false;
      }, 500);
    },
    setBooking: {
      handler: function (val) {
        this.booking = { ...val };
      },
      deep: false,
      immediate: true,
    },
  },
  computed: {
    availableParties() {
      let keys = ["Shipper", "Forwarder", "Consignee", "Notify Party"];
      return keys;
    },
    availableContractParties() {
      let result = [
        {
          type: "SUBHEADER",
          name: "Profile Parties",
        },
        {
          id: this.$store.state.currentOrg.id,
          name: this.$store.state.currentOrg.name,
          alias: this.$store.state.currentOrg.alias,
          logo: this.$store.state.currentOrg.logo,
          type: 'Current Organisation'
        }
      ];
      for (let party of this.partyTypes) {
        if (this.booking[party.value]) {
          result.push({
            type: party.name,
            name: this.booking[party.value].name,
            id: this.booking[party.value].id,
            friendlyName:
              this.booking[party.value].friendlyName ??
              this.booking[party.value].alias,
            logo: this.booking[party.value].logo,
          });
        }
      }
      result.push({
        type: "SUBHEADER",
        name: "Other Parties",
      });
      let organisations = this.organisations.map(x=>x.relatedOrganisation).filter((x) => !result.map((y) => y.id).includes(x.id))
      organisations = organisations.sort((a,b)=>a.name.localeCompare(b.name))
      result = [
        ...result,
        ...organisations          
      ];
      if (this.searchContractOwner) {
        result = result.filter(
          (x) =>
            x.type != "SUBHEADER" &&
            (x.name.toLowerCase().includes(this.searchContractOwner.toLowerCase()) ||
            (x.alias && x.alias.toLowerCase().includes(this.searchContractOwner.toLowerCase())))
        );
      }
      // console.log("Result", result)
      return result;
    },
    availablePayerParties() {
      let result = [
        {
          type: "SUBHEADER",
          name: "Profile Parties",
        },
        {
          id: this.$store.state.currentOrg.id,
          name: this.$store.state.currentOrg.name,
          alias: this.$store.state.currentOrg.alias,
          logo: this.$store.state.currentOrg.logo,
          type: 'Current Organisation'
        }
      ];
      for (let party of this.partyTypes) {
        if (this.booking[party.value]) {
          result.push({
            type: party.name,
            name: this.booking[party.value].name,
            id: this.booking[party.value].id,
            friendlyName:
              this.booking[party.value].friendlyName ??
              this.booking[party.value].alias,
            logo: this.booking[party.value].logo,
          });
        }
      }
      result.push({
        type: "SUBHEADER",
        name: "Other Parties",
      });
      let organisations = this.organisations.map(x=>x.relatedOrganisation).filter((x) => !result.map((y) => y.id).includes(x.id))
      organisations = organisations.sort((a,b)=>a.name.localeCompare(b.name))
      result = [
        ...result,
        ...organisations          
      ];
      if (this.searchPayer) {
        result = result.filter(
          (x) =>
            x.type != "SUBHEADER" &&
            (x.name.toLowerCase().includes(this.searchPayer.toLowerCase()) ||
            (x.alias && x.alias.toLowerCase().includes(this.searchPayer.toLowerCase())))
        );
      }
      return result;
    },
    filterAvailableContainerProducts() {
      let result = this.productServices.filter((x) => x.level == "CONTAINER");
      if (this.searchContainerProduct) {
        result = result.filter((x) =>
          x.name
            .toLowerCase()
            .includes(this.searchContainerProduct.toLowerCase())
        );
      }
      return result;
    },
    filterAvailableShipmentProducts() {
      let result = this.productServices.filter((x) => x.level == "SHIPMENT");
      if (this.searchBookingProduct) {
        result = result.filter((x) =>
          x.name.toLowerCase().includes(this.searchBookingProduct.toLowerCase())
        );
      }
      return result;
    },
    filterContainerTypes(){
      if(this.booking.modeOfTransport == 'AIR'){
        return this.containerTypes.filter(x=>x.level == 'air')
      } else {
        return this.containerTypes.filter(x=>!x.level || x.level != 'air')
      }
    },
    filterOrganisations() {
      let result = this.organisations ?? [];
      result = result.filter((x) => x.isActive && x.isCustomer);
      let unique = [...new Set(result.map((x) => x.relatedOrganisationId))];
      unique = unique.map((x) =>
        result.find((y) => y.relatedOrganisationId == x)
      );
      let finalResult = [];
      for (let i = 0; i < unique.length; i++) {
        let find = result.find(
          (x) => x.relatedOrganisationId == unique[i].relatedOrganisationId
        );
        if (find) {
          finalResult.push(find);
        }
      }
      if (this.searchCustomer) {
        finalResult = finalResult.filter(
          (organisation) =>
            organisation.relatedOrganisation.name
              .toLowerCase()
              .includes(this.searchCustomer.toLowerCase()) ||
            (organisation.relatedOrganisation.alias &&
              organisation.relatedOrganisation.alias
                .toLowerCase()
                .includes(this.searchCustomer.toLowerCase()))
        );
      }
      finalResult = finalResult.sort((a, b) =>
        a.relatedOrganisation.name.localeCompare(b.relatedOrganisation.name)
      );
      return finalResult;
    },
    filterSuggestedContracts() {
      let result = this.suggestedContracts;
      if (this.searchContract) {
        result = result.filter(
          (x) =>
            (x.contracts[0] &&
              x.contracts[0].contractNo
                .toLowerCase()
                .includes(this.searchContract.toLowerCase())) ||
            (x.contractOwner &&
              x.contractOwner.name
                .toLowerCase()
                .includes(this.searchContract.toLowerCase())) ||
            (x.contractOwner &&
              x.contractOwner.alias
                .toLowerCase()
                .includes(this.searchContract.toLowerCase()))
        );
      }
      return result;
    },
    filterCountries() {
      let result = this.countries;
      if (this.searchCountries) {
        result = result.filter((x) =>
          x.name.toLowerCase().includes(this.searchCountries.toLowerCase())
        );
      }
      return result;
    },
    filterCustomerOrganisations() {
      let result = this.linkedOrganisations;
      if (this.booking.customer) {
        let find = result.find(
          (x) => x.relatedOrganisationId == this.booking.customerId
        );
        if (!find) {
          result.push({
            relatedOrganisation: this.booking.customer,
            relatedOrganisationId: this.booking.customerId,
          });
        }
      }
      if (this.searchOrganisation) {
        result = result.filter(
          (organisation) =>
            organisation.relatedOrganisation.name
              .toLowerCase()
              .includes(this.searchOrganisation.toLowerCase()) ||
            (organisation.relatedOrganisation.alias &&
              organisation.relatedOrganisation.alias
                .toLowerCase()
                .includes(this.searchOrganisation.toLowerCase()))
        );
      }
      result = result.sort((a, b) =>
        a.relatedOrganisation.name.localeCompare(b.relatedOrganisation.name)
      );
      return result;
    },
    filterDestinationPort() {
      let result = this.allDestinationPorts;
      result = result.sort((a, b) => {
        let portA = a.organisationPorts && a.organisationPorts.length > 0;
        let portB = b.organisationPorts && b.organisationPorts.length > 0;
        if (portA && portB) {
          return a.name.localeCompare(b.name);
        } else if (portA) {
          return -1;
        } else if (portB) {
          return 1;
        } else {
          return 0;
        }
      });
      if (this.searchDestination) {
        result = result.filter(
          (x) =>
            x.name
              .toLowerCase()
              .includes(this.searchDestination.toLowerCase()) ||
            x.locode
              .toLowerCase()
              .includes(this.searchDestination.toLowerCase())
        );
      }
      return result;
    },
    filterOriginPort() {
      let result = this.allOriginPorts;
      result = result.sort((a, b) => {
        let portA = a.organisationPorts && a.organisationPorts.length > 0;
        let portB = b.organisationPorts && b.organisationPorts.length > 0;
        if (portA && portB) {
          return a.name.localeCompare(b.name);
        } else if (portA) {
          return -1;
        } else if (portB) {
          return 1;
        } else {
          return 0;
        }
      });

      if (this.searchOrigin) {
        result = result.filter(
          (x) =>
            x.name.toLowerCase().includes(this.searchOrigin.toLowerCase()) ||
            x.locode.toLowerCase().includes(this.searchOrigin.toLowerCase())
        );
      }
      return result;
    },
    filterProducts() {
      let result = this.products.data.filter((x) => x.hsCode);
      if (result && this.filterProductType) {
        result = result.filter((x) => x.type == this.filterProductType);
      }
      return result;
    },
    filterRegimeCodes() {
      let result = this.regimeCodes;
      if (this.regimeCodeSearch) {
        result = result.filter((x) =>
          x.code.toLowerCase().includes(this.regimeCodeSearch.toLowerCase())
        );
      }
      return result;
    },
    filterShippingLines() {
      let result = this.booking.modeOfTransport == 'OCEAN' ? this.shippingLines : this.airlines;
      if (this.searchShippingLine) {
        result = result.filter(
          (x) =>
            (x.name &&
              x.name
                .toLowerCase()
                .includes(this.searchShippingLine.toLowerCase())) ||
            (x.friendlyName &&
              x.friendlyName
                .toLowerCase()
                .includes(this.searchShippingLine.toLowerCase()))
        );
      }
      return result;
    },
    hauliers() {
      let result = [
        {
          index: 2,
          type: "Carrier Haulage",
          name: "Carrier Haulage",
          logo: null,
          icon: "directions_boat",
          flag: "isCarrierHaulage",
        },
        {
          index: 4,
          type: "Merchant Haulage",
          name: "Merchant Haulage",
          icon: "local_shipping",
          logo: null,
          flag: "isPrivateHaulage",
        },
        {
          index: 3,
          type: "Railage",
          name: "Rail",
          icon: "train",
          logo: null,
          flag: "isRailage",
        },
      ];
      return result;
    },
    displayReeferSettings() {
      let result = false;
      if (this.containerItem && this.containerItem.containerTypeCode) {
        let findType = this.containerTypes.find(
          (x) => x.typeCode == this.containerItem.containerTypeCode
        );
        result = findType && findType.typeCategory == "Reefer";
      }
      return result;
    },
    dischargePorts() {
      let result = this.booking.consigneeProfile
        ? this.booking.consigneeProfile.consigneeProfilePorts.filter(
            (x) => x.type == "discharge"
          )
        : [];
      if (this.searchDestination) {
        result = result.filter(
          (x) =>
            (x.name &&
              x.name
                .toLowerCase()
                .includes(this.searchDestination.toLowerCase())) ||
            (x.code &&
              x.code
                .toLowerCase()
                .includes(this.searchDestination.toLowerCase()))
        );
      }
      let unique = [...new Set(result.map((x) => x.code))];
      unique = unique.map((x) => result.find((y) => y.code == x));
      unique.sort((a, b) => (a.code > b.code ? 1 : b.code > a.code ? -1 : 0));
      return unique;
    },
    loadingPorts() {
      let result = this.booking.consigneeProfile
        ? this.booking.consigneeProfile.consigneeProfilePorts.filter(
            (x) => x.type == "loading"
          )
        : [];
      if (this.searchOrigin) {
        result = result.filter(
          (x) =>
            (x.name &&
              x.name.toLowerCase().includes(this.searchOrigin.toLowerCase())) ||
            (x.code &&
              x.code.toLowerCase().includes(this.searchOrigin.toLowerCase()))
        );
      }
      let unique = [...new Set(result.map((x) => x.code))];
      unique = unique.map((x) => result.find((y) => y.code == x));
      unique.sort((a, b) => (a.code > b.code ? 1 : b.code > a.code ? -1 : 0));
      return unique;
    },
    validDetails() {
      let validateFields = [
        "portOfLoadValue",
        "portOfDischargeValue",
        "finalDestinationValue",
        "bookingOffice",
        "incoTerm",
        "regimeCode",
        "originCountry",
        "destinationCountry",
      ];
      let result = true;
      for (let i = 0; i < validateFields.length; i++) {
        if (!this.booking[validateFields[i]]) {
          result = false;
          break;
        }
      }
      if (
        !this.booking.bookingContainerItems ||
        this.booking.bookingContainerItems.length == 0
      ) {
        result = false;
      }
      return result;
    },
  },
  async created() {
    this.loadProducts();
    this.getCarrierList();
    this.getCountries();
    this.getIncoTerms();
    this.getProductServices();
    this.containerFeatures = await this.$API.getContainerFeatures()
  },
  methods: {
    async addContainerDetails(type = null) {
      this.bulkType = "Add";
      if (this.booking.serviceType == "BREAKBULK") {
        this.containerItem = {
          quantity: 1,
          bookingCargoItems: [],
          productServiceSales: [],
          type: "BREAKBULK",
          index: this.booking.bookingContainerItems.length,
          consignee: this.booking.consigneeId
            ? this.linkedOrganisations.find(
                (x) => x.relatedOrganisationId == this.booking.consigneeId
              )?.relatedOrganisation
            : null,
          consigneeId: this.booking.consigneeId,
          buyer: this.booking.buyerId
            ? this.linkedOrganisations.find(
                (x) => x.relatedOrganisationId == this.booking.buyerId
              )?.relatedOrganisation
            : null,
          buyerId: this.booking.buyerId,
          verificationMethod:
            this.booking.movementType == "EXPORT" ? "Method 2" : null,
            containerFeatures: []
        };
        if (!this.containerItem.buyer) {
          this.containerItem.buyerId = null;
        }
        if (!this.containerItem.consignee) {
          this.containerItem.consigneeId = null;
        }
        // this.booking.bookingContainerItems.push(this.containerItem);
        // this.gridApi.applyTransaction({ add: [containerItem] });
      } else {
        if (!this.booking.bookingContainerItems) {
          this.booking.bookingContainerItems = [];
        }
        this.containerItem = {
          type: "CONTAINER",
          quantity: 1,
          bookingCargoItems: [],
          containerTypeCode: this.booking.modeOfTransport == 'AIR'?'AKE':"45R1",
          containerType: this.booking.modeOfTransport == 'AIR' ? 'LD-3 AKE' : "40 RF HC (45R1)",
          productServiceSales: [],
          index: this.booking.bookingContainerItems.length,
          consignee: this.booking.consigneeId
            ? this.linkedOrganisations.find(
                (x) => x.relatedOrganisationId == this.booking.consigneeId
              )?.relatedOrganisation
            : null,
          consigneeId: this.booking.consigneeId,
          buyer: this.booking.buyerId
            ? this.linkedOrganisations.find(
                (x) =>
                  x.relatedOrganisationId ==
                  this.booking.buyerId?.relatedOrganisation
              )
            : null,
          buyerId: this.booking.buyerId,
          verificationMethod:
            this.booking.movementType == "EXPORT" ? "Method 2" : null,
            containerFeatures: []
        };
        if (!this.containerItem.buyer) {
          this.containerItem.buyerId = null;
        }
        if (!this.containerItem.consignee) {
          this.containerItem.consigneeId = null;
        }
        if (this.booking.vents) {
          this.containerItem.vents = this.booking.vents;
        } else if (this.ventSettings.length == 1) {
          this.containerItem.vents = this.ventSettings[0];
        }
        if (this.booking.regime) {
          this.containerItem.setPointTemp = this.booking.regime.setPointTemp;
        }
        if (this.booking.caProduct) {
          this.containerItem.caProduct = this.booking.caProduct;
          this.containerItem.oxygen = this.booking.oxygen;
          this.containerItem.carbonDioxide = this.booking.carbonDioxide;
          this.containerItem.nitrogen = 0;
        }
        // this.booking.bookingContainerItems.push(this.containerItem);
        // this.gridApi.applyTransaction({ add: [containerItem] });
      }
      this.selectedFeatures = {}
      this.containerFeatures.forEach(x=>{
        this.selectedFeatures[x.value] = false
      })
      // this.setHaulier(this.hauliers[0]);
      this.containerItemModal = true;
    },
    selectQuantityType() {
      let findCode = this.productQuantityTypes.find(x=>x.value == this.cargoItem.quantityTypeCode)
      this.cargoItem.quantityType = findCode ? findCode.text: null
    },
    addContainerCargoDetails() {
      this.cargoItem = {
        type: "container",
        index: this.containerItem.bookingCargoItems.length,
        quantityType: "Carton",
        quantityTypeCode: "CT",
      };
      let findContainer = this.containerTypes.find(
        (x) => x.typeCode == this.containerItem.containerTypeCode
      );
      if (findContainer) {
        this.cargoItem.volume = 25 * findContainer.teu;
      }

      this.cargoModal = true;
    },
    addMilestone(item, type = null, autosave = true) {
      if (type && item[type]) {
        this.milestoneItem = item[type];
        this.milestoneItem.autosave = autosave;
      } else {
        this.milestoneItem = {
          type,
          bookingContainerId: item.id,
          pol: this.booking.portOfLoadValue,
          autosave,
          isActual: false,
        };
      }

      this.addMilestoneModal = true;
      this.$nextTick(() => {
        this.milestoneKey++;
      });
    },
    addPayment() {
      if (!this.booking.bookingPaymentDetails) {
        this.booking.bookingPaymentDetails = [];
      }
      this.paymentItem = {
        index: this.booking.bookingPaymentDetails.length,
      };
      this.paymentModal = true;
    },
    addContainerProductService() {
      if (!this.containerItem.productServiceSales) {
        this.containerItem.productServiceSales = [];
      }
      this.productServiceItem = {
        index: this.containerItem.productServiceSales.length,
        level: "CONTAINER",
      };
      // this.containerProductServiceModal = true;
      this.productServiceModal = true;
    },
    addProductService() {
      if (!this.booking.productServiceSales) {
        this.booking.productServiceSales = [];
      }
      this.productServiceItem = {
        index: this.booking.productServiceSales.length,
        level: "SHIPMENT",
      };
      this.productServiceModal = true;
    },
    deleteCargoItem(index) {
      this.containerItem.bookingCargoItems.splice(index, 1);
    },
    deleteContainerItem(index) {
      this.booking.bookingContainerItems.splice(index, 1);
      this.booking.isHazardous = this.booking.bookingContainerItems.some((x) =>
        x.bookingCargoItems.some((y) => y.hazardous == true)
      );
      this.containerItemModal = false;
    },
    deletePaymentItem(item) {
      this.booking.bookingPaymentDetails.splice(item.index, 1);
    },
    editCargoItem(item) {
      this.cargoItem = item;
      this.cargoModal = true;
    },
    editContainerItem(item, index, type = null) {
      this.containerItem = item;
      this.containerItem.index = index;
      this.bulkType = "Edit";
      this.selectedFeatures = {}
      console.log(this.containerItem.containerFeatures)
      this.containerItem.containerFeatures.forEach((x) => {
        this.selectedFeatures[x.value] = true
      })
      if (item.containerMilestones && item.containerMilestones.length > 0) {
        for (let milestone of item.containerMilestones) {
          this.containerItem[milestone.type] = milestone;
        }
      }
      if (this.ventSettings.length == 0) {
        this.ventSettings = [
          "5",
          "10",
          "15",
          "20",
          "25",
          "28",
          "30",
          "35",
          "40",
          "50",
          "Closed",
          "Fully Open",
          "CA",
          "MA",
          "Auto",
        ];
      }
      console.log(this.selectedFeatures)
      this.containerItemModal = true;
    },
    async editContractNumber() {
      this.contractNumber = this.booking.contractNumber ?? null
      let containers = this.booking.bookingContainerItems;
      let products = [
        ...new Set(
          [].concat
            .apply(
              [],
              containers.map((x) => x.bookingCargoItems)
            )
            .map((x) => x.productId)
        ),
      ];
      let containerTypes = [
        ...new Set(
          [].concat.apply(
            [],
            this.booking.bookingContainerItems.map((x) => x.containerTypeCode)
          )
        ),
      ];
      this.suggestedContracts = [];
      if (
        this.booking.etd &&
        containerTypes.length > 0 &&
        products.length > 0 &&
        this.booking.portOfLoadValue &&
        this.booking.portOfDischargeValue &&
        this.booking.shippingLineId
      ) {
        let contracts = await this.$API.getBookingContracts({
          params: {
            origin: this.booking.portOfLoadValue,
            originCountry: this.booking.portOfLoadValue
              ? this.booking.portOfLoadValue.substring(0, 2)
              : null,
            destination: this.booking.portOfDischargeValue,
            destinationCountry: this.booking.portOfDischargeValue
              ? this.booking.portOfDischargeValue.substring(0, 2)
              : null,
            contractPartyId: this.booking.shippingLineId,
            date: this.booking.etd,
            containerTypes: containerTypes,
            products: products,
          },
        });
        this.suggestedContracts = contracts;
      }

      this.contractModal = true;
    },
    editPaymentItem(item) {
      this.paymentItem = item;
      this.paymentModal = true;
    },
    filterObject(item, queryText, itemText) {
      return (
        item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1 ||
        item.locode.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1
      );
    },
    findChargeType(type) {
      return this.paymentTypes.find((x) => x.key == type).value;
    },
    formatDateTime(date, time) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "dd-mm-yyyy");
        if (time) {
          result += " " + time;
        }
      }
      return result;
    },
    formatParty(party) {
      switch (party) {
        case "forwarder":
          return "Forwarder";
        case "buyer":
          return "Buyer";
        case "consignee":
          return "Consignee";
        case "firstNotify":
          return "Notify Party";
        case "secondNotify":
          return "Notify Party";
        default:
          return party;
      }
    },
    async getCountries() {
      this.countries = await this.$API.searchCountriesBasic({});
      if (
        !this.booking.originCountry &&
        this.$store.state.currentOrg.countryCode
      ) {
        let find = this.countries.find(
          (x) => x.iso2 == this.$store.state.currentOrg.countryCode
        );
        if (find) {
          this.booking.originCountry = find;
          this.booking.originCountryId = find.id;
        }
      }
    },
    async getCarrierList() {
      this.loadingCarriers = true;
      this.shippingLines = await this.$API.getShippingLines();
      this.airlines = await this.$API.getAirLines();
      this.selectedCarriers = this.shippingLines
        .filter((x) => x.scacCode)
        .map((x) => x.scacCode);
      this.containerTypes = await this.$API.listContainerTypes();
      this.loadingCarriers = false;
    },
    getContainerDescription(code) {
      let find = this.containerTypes.find((x) => x.typeCode == code);
      return find ? find.shortDescription : null;
    },
    getContractParty(party) {
      if (this.booking[party]) {
        return this.booking[party].name;
      } else return null;
    },
    getClientStatusColor(status) {
      switch (status) {
        case "GOOD STANDING":
          return "green";
        case "INACTIVE":
          return "red";
        case "ON HOLD":
          return "orange";
        case "COD":
          return "blue";
      }
    },
    async getProductServices() {
      this.productServiceLoading = true;
      this.productServices = await this.$API.getProductServices();
      this.productServiceLoading = false;
      this.availableShipmentProducts = this.productServices.filter(
        (x) => x.level == "SHIPMENT" && x.type == "PRODUCT"
      );
      this.availableShipmentServices = this.productServices.filter(
        (x) => x.level == "SHIPMENT" && x.type == "SERVICE"
      );
      this.availableContainerProducts = this.productServices.filter(
        (x) => x.level == "CONTAINER" && x.type == "PRODUCT"
      );
      this.availableContainerServices = this.productServices.filter(
        (x) => x.level == "CONTAINER" && x.type == "SERVICE"
      );
    },
    async getIncoTerms() {
      if (localStorage["incoTerms"]) {
        this.incoTerms = JSON.parse(localStorage["incoTerms"]);
      } else {
        this.incoTerms = await this.$API.getIncoTerms();
        localStorage["incoTerms"] = JSON.stringify(this.incoTerms);
      }
    },
    async getRegimeCodes(modal = false) {
      this.regimeCodeModal = modal;
      this.regimeCodeLoading = true;
      let params = {};
      if (this.booking.destinationCountry) {
        params.countryCode = this.booking.destinationCountry.iso2;
      }
      if (
        this.booking.bookingContainerItems &&
        this.booking.bookingContainerItems.length > 0 &&
        this.booking.bookingContainerItems.some(x=>x.bookingCargoItems) &&
        this.booking.bookingContainerItems.some(x=>x.bookingCargoItems.length > 0)
      ) {
        let uniqueProducts = [...new Set(this.booking.bookingContainerItems.map(x=>x.bookingCargoItems.map(y=>y.productId)).flat())]
        params.productCode = uniqueProducts
      }
      this.regimeCodes = await this.$API.getRegimeCodes({
        params: params,
      });
      this.regimeCodeLoading = false;
    },
    async loadProducts() {
      this.loadingProduct = true;
      this.products = await this.$API.getProducts({});
      this.loadingProduct = false;
    },
    onGridReady(params) {
      this.gridApi = params.api;
    },
    openSailingSchedules() {
      if (this.booking.portOfLoadValue && this.booking.portOfDischargeValue) {
        this.sailingScheduleModal = true;
        // if(this.booking.consigneeProfile)
        // this.searchOceanSchedules()
      } else {
        this.sailingScheduleError = true;
      }
    },
    removeImportShipperParty() {
      this.partyType = "Shipper";
      this.booking.shipper = null;
      this.booking.shipperId = null;
    },
    removeMilestone(milestone) {
      this.containerItem.containerMilestones =
        this.containerItem.containerMilestones.filter(
          (x) => x.type != milestone
        );
      let filteredMilestones = this.availableMilestones.filter(
        (x) => x.type == this.booking.movementType
      );
      for (let data of filteredMilestones) {
        let find = this.containerItem.containerMilestones.find(
          (x) => x.type == data.value
        );
        this.containerItem[data.value] = find ?? null;
      }
      this.milestoneKey++;
    },
    removeProfile() {
      this.booking.consigneeProfile = null;
      this.booking.consigneeProfileId = null;
      this.booking.dealTerm = null;
      this.booking.dealTermId = null;
      this.profileKey++;
    },
    removeRegime() {
      this.booking.regime = null;
      this.booking.regimeCode = null;
      this.booking.regimeId = null;
      this.regimeCodeModal = false;
      this.regimeKey++;
    },
    async saveBooking() {
      try {
        if (!this.validDetails) {
          this.booking.state = "Draft";
        } else {
          this.booking.state = "Pending";
        }
        this.savingBooking = true;
        if (this.booking.caProduct) {
          this.booking.bookingContainerItems.forEach((x) => {
            x.caProduct = this.booking.caProduct;
            x.oxygen = this.booking.oxygen;
            x.carbonDioxide = this.booking.carbonDioxide;
            x.nitrogen = 0;
          });
        }
        this.booking.numContainers = this.booking.bookingContainerItems.reduce(
          (a, b) => a + b.quantity,
          0
        );
        let result = await this.$API.createBooking(this.booking);
        if (!this.booking.id) {
          this.$message.success("Successfully created booking!");

          this.$router.push({ path: "/booking/manage/" + result.systemReference });
          this.savingBooking = false;
        } else {
          this.booking.id = result.id;
          this.savingBooking = false;
          this.$message.success("Successfully updated booking!");
        }
      } catch (e) {
        this.savingBooking = false;
        this.$message.error(e.message);
      }
    },
    saveCargoItem() {
      if (this.booking.regime) {
        this.containerItem.setPointTemp = this.booking.regime.setPointTemp;
        if (
          this.booking.regime.regimeVents &&
          this.booking.regime.regimeVents.length > 0
        ) {
          this.ventSettings = [
            "5",
            "10",
            "15",
            "20",
            "25",
            "28",
            "30",
            "35",
            "40",
            "50",
            "Closed",

            "Fully Open",
            "CA",
            "MA",
            "Auto",
          ].filter((x) =>
            this.booking.regime.regimeVents.map((x) => x.ventCode).includes(x)
          );
        } else {
          this.ventSettings = [
            "5",
            "10",
            "15",
            "20",
            "25",
            "28",
            "30",
            "35",
            "40",
            "50",
            "Closed",
            "Fully Open",
            "CA",
            "MA",
            "Auto",
          ];
        }
        if (this.ventSettings.length == 1) {
          this.containerItem.vents = this.ventSettings[0];
        }
      }
      if (this.cargoItem.type == "container") {
        let find = this.containerItem.bookingCargoItems.findIndex(
          (x) => x.index === this.cargoItem.index
        );
        this.cargoItem.productId = this.cargoItem.product.id;
        this.cargoItem.hsCodeId = this.cargoItem.hsCode.id;

        if (find > -1) {
          this.containerItem.bookingCargoItems[find] = this.cargoItem;
        } else {
          this.containerItem.bookingCargoItems.push(this.cargoItem);
        }
      } else {
        let find = this.booking.bookingCargoItems.findIndex(
          (x) => x.index === this.cargoItem.index
        );
        this.cargoItem.productId = this.cargoItem.product.id;
        this.cargoItem.hsCodeId = this.cargoItem.hsCode.id;
        if (find > -1) {
          this.booking.bookingCargoItems[find] = this.cargoItem;
        } else {
          this.booking.bookingCargoItems.push(this.cargoItem);
        }
      }
      this.cargoKey++;
      this.cargoModal = false;
      this.cargoItem = {};
    },
    saveContainerItem() {
      this.savingContainerItem = true;
      let keys = Object.keys(this.selectedFeatures);
      let containerFeatures = []
      console.log(this.selectedFeatures)
    
      keys.forEach((x) => {
        if(this.selectedFeatures[x]){
          let find = this.containerFeatures.find(y=>y.value == x)
          containerFeatures.push(find)
        }
      })
      this.containerItem.containerFeatures = containerFeatures
      if (this.containerItem.verificationMethod == "Method 2") {
        this.containerItem.weighBridgeId = null;
        this.containerItem.verificationMethodOwner = null;
      }
      let find = this.booking.bookingContainerItems.findIndex(
        (x) => x.index === this.containerItem.index
      );
      let findType = this.containerTypes.find(
        (x) => x.typeCode == this.containerItem.containerTypeCode
      );
      this.containerItem.containerTypeDescription = findType
        ? findType.shortDescription
        : null;
      if (find > -1) {
        this.booking.bookingContainerItems[find] = this.containerItem;
      } else {
        this.booking.bookingContainerItems.push(this.containerItem);
      }
      this.containerItemModal = false;
      this.containerItem = {};
      this.savingContainerItem = false;
    },
    savePaymentItem() {
      let find = this.booking.bookingPaymentDetails.findIndex(
        (x) => x.index === this.paymentItem.index
      );
      if (find > -1) {
        this.booking.bookingPaymentDetails[find] = this.paymentItem;
      } else {
        this.booking.bookingPaymentDetails.push(this.paymentItem);
      }
      this.paymentKey++;
      this.paymentModal = false;
      this.paymentItem = {};
    },
    setBookingOffice(office) {
      this.booking.bookingOffice = office.locode;
      this.booking.bookingOfficeName = office.name;
      this.bookingOfficeModal = false;
    },
    setCargoItemDetails() {
      delete this.cargoItem.id;
      if (this.cargoItem.product) {
        this.cargoItem.description = this.cargoItem.product.hsCode.explanation;
        this.cargoItem.hsCode =
          this.cargoItem.product.hsCode.alternateCode.replace(/ /g, "");
        this.cargoItem.productId = this.cargoItem.product.id;
      }
    },
    setCASettings() {
      if (!this.booking.bookingContainerItems) {
        this.booking.bookingContainerItems = [];
      }
      this.booking.bookingContainerItems.forEach((x) => {
        x.oxygen = this.booking.oxygen;
        x.carbonDioxide = this.booking.carbonDioxide;
      });
    },
    async setCAProduct() {
      this.booking.vents =
        this.booking.caProduct && this.booking.caProduct != "MAXTEND"
          ? "Closed"
          : this.booking.caProduct == "MAXTEND"
          ? "MAXTEND"
          : this.booking.vents;
      if (this.booking.caProduct) {
        let findProduct = this.caProducts.find(
          (x) => x.name == this.booking.caProduct
        );
        this.booking.oxygen = findProduct.oxygen;
        this.booking.carbonDioxide = findProduct.carbonDioxide;
      } else {
        this.booking.oxygen = null;
        this.booking.carbonDioxide = null;
      }
      if (!this.booking.bookingContainerItems) {
        this.booking.bookingContainerItems = [];
      }
      this.booking.bookingContainerItems.forEach((x) => {
        if (this.booking.caProduct) {
          if (this.booking.caProduct != "MAXTEND") {
            x.vents = "Closed";
          }
          x.caProduct = this.booking.caProduct;
          x.controlledAtmosphere = true;
          let findProduct = this.caProducts.find(
            (y) => y.name == this.booking.caProduct
          );
          if (findProduct) {
            x.oxygen = findProduct.oxygen;
            x.carbonDioxide = findProduct.carbonDioxide;
            x.nitrogen = 0;
          }
        } else {
          x.caProduct = null;
          x.controlledAtmosphere = false;
        }
      });
      this.caProductModal = false;
    },
    setCountry(country) {
      if (this.countryType == "Origin") {
        this.booking.originCountry = country;
        this.booking.originCountryId = country.id;
      } else if (this.countryType == "Destination") {
        this.booking.destinationCountry = country;
        this.booking.destinationCountryId = country.id;
      }
      this.countryModal = false;
      this.searchCountries = null;
    },
    async setCustomer(organisation) {
      this.booking.customer = organisation.relatedOrganisation;
      this.booking.customerId = organisation.relatedOrganisationId;
      this.booking.consigneeProfile = null;
      this.booking.consigneeProfileId = null;
      this.booking.dealTerm = null;
      this.booking.dealTermId = null;
      this.booking.incoTerm = null;
      let parties = [
        "buyer",
        "shipper",
        "consignee",
        "forwarder",
        "firstNotify",
        "secondNotify",
        "courierParty",
      ];
      for (let i = 0; i < parties.length; i++) {
        this.booking[parties[i]] = null;
        this.booking[parties[i] + "Id"] = null;
      }
      for (let i = 0; i < parties.length; i++) {
        if (organisation[parties[i]]) {
          this.booking[parties[i]] = organisation.relatedOrganisation;
          this.booking[parties[i] + "Id"] = organisation.relatedOrganisationId;
        }
      }
      this.customerSearchModal = false;
      this.loadingLinkedOrganisations = true;
      this.linkedOrganisations = await this.$API.getCustomerRelationships(
        organisation.relatedOrganisationId
      );
      this.loadingLinkedOrganisations = false;
    },
    setPaymentParty(party){
      this.paymentItem.partyId = party.id
      this.paymentItem.party = party
      this.payerModal = false
    },
    selectedBookingProduct(id) {
      let findProduct =
        this.booking.productServiceSales &&
        this.booking.productServiceSales.find((x) => x.id == id);
      return findProduct ? true : false;
    },
    selectBookingProduct(product) {
      if (!product.selected) {
        product.selected = true;
      } else {
        product.selected = false;
      }
      if (this.selectedBookingProduct(product.id)) {
        let findProduct = this.booking.productServiceSales.findIndex(
          (x) => x.id == product.id
        );
        this.booking.productServiceSales.splice(findProduct, 1);
      } else {
        this.booking.productServiceSales.push(product);
      }
      this.selectedProductKey++;
    },
    selectedContainerProduct(id) {
      let findProduct =
        this.containerItem &&
        this.containerItem.productServiceSales &&
        this.containerItem.productServiceSales.find((x) => x.id == id);
      return findProduct ? true : false;
    },
    selectContainerProduct(product) {
      if (this.selectedContainerProduct(product.id)) {
        let findProduct = this.containerItem.productServiceSales.findIndex(
          (x) => x.id == product.id
        );
        this.containerItem.productServiceSales.splice(findProduct, 1);
      } else {
        this.containerItem.productServiceSales.push(product);
      }
      this.selectedProductKey++;
    },
    setProductSelection(selection){
      if(this.productServiceItem.level == 'SHIPMENT'){
        this.booking.productServiceSales = selection
      } else if(this.productServiceItem.level == 'CONTAINER'){
        this.containerItem.productServiceSales = selection
      }
    },
    async setContract(contract) {
      let parties = [
        "forwarder",
        "shipper",
        "customer",
        "onBehalfShipper",
        "onBehalfForwarder",
        "consignee",
        "buyer",
        "onBehalfBuyer",
        "onBehalfConsignee",
        "firstNotify",
        "secondNotify",
      ];
      let result = [];
      for (let party of parties) {
        if (this.booking[party]) {
          let obj = {
            role: party,
            ...this.booking[party],
          };
          result.push(obj);
        }
      }
      let owner = result.find((x) => x.id == contract.contractOwnerId);
      this.booking.contractId = contract.id;
      this.booking.contractOwner = owner ? owner.role : null;
      this.booking.contractNumber = contract.contracts[0].contractNo;
      this.booking.contractPartyId = contract.contractOwnerId;
      this.contractModal = false;
    },
    setContractOwner(party){
      this.booking.contractPartyId = party.id
      this.booking.contractParty = party
      this.contractOwnerModal = false
    },
    async setDealTerm(term) {
      this.booking.dealTermId = term.id;
      this.booking.dealTerm = term;
      this.booking.incoTerm = term.incoTerm;
      if(!this.booking.contractPartyId){
        this.booking.contractPartyId = term.contractPartyId
        this.booking.contractParty = term.contractParty
      }
      // if (
      //   term.consigneeProfileShippingContracts &&
      //   term.consigneeProfileShippingContracts.length > 0
      // ) {
      //   this.selectedCarriers = term.consigneeProfileShippingContracts
      //     .filter((x) => x.shippingLine)
      //     .map((x) => x.shippingLine.scacCode)
      //     .filter(Boolean);
      // } else {
      //   this.shippingLines.filter((x) => x.scacCode).map((x) => x.scacCode);
      // }
      this.shipmentProfileConfig = false;
      this.booking.bookingPaymentDetails = [];
      if(term.contractPartyId ||this.booking[term.contractOwner]){
        this.booking.contractParty = term.contractParty ?? this.booking[term.contractOwner]
        this.booking.contractPartyId = term.contractPartyId ?? this.booking[term.contractOwner + "Id"]
      }
      if (this.booking.dealTerm) {
        if (this.booking.dealTerm.freightCharge) {
          this.paymentItem = {
            chargeType: "OceanFreight",
            index: this.booking.bookingPaymentDetails.length,
            paymentTerm: this.booking.dealTerm.paymentTerm,
            paymentParty: this.formatParty(this.booking.dealTerm.freightCharge),
            partyId: this.booking.dealTerm.freightPartyId,
            party: this.booking.dealTerm.freightParty
          };
          await this.savePaymentItem();
        }

        if (this.booking.dealTerm.originCharge) {
          this.paymentItem = {
            chargeType: "OriginTerminalHandling",
            index: this.booking.bookingPaymentDetails.length,
            paymentTerm: this.booking.dealTerm.originTerm,
            paymentParty: this.formatParty(this.booking.dealTerm.originCharge),
            partyId: this.booking.dealTerm.originPartyId,
            party: this.booking.dealTerm.originParty
          };
          await this.savePaymentItem();
        }

        if (this.booking.dealTerm.destinationCharge) {
          this.paymentItem = {
            chargeType: "DestinationTerminalHandling",
            index: this.booking.bookingPaymentDetails.length,
            paymentTerm: this.booking.dealTerm.destinationTerm,
            paymentParty: this.formatParty(
              this.booking.dealTerm.destinationCharge
            ),
            partyId: this.booking.dealTerm.destinationPartyId,
            party: this.booking.dealTerm.destinationParty
          };
          await this.savePaymentItem();
        }
      }
    },
    setHaulier(haulier) {
      let types = ["isPrivateHaulage", "isCarrierHaulage", "isRailage"];
      if (haulier.flag) {
        let setFalse = types.filter((x) => x != haulier.flag);
        for (let i = 0; i < setFalse.length; i++) {
          this.containerItem[setFalse[i]] = false;
        }
        this.containerItem[haulier.flag] = true;
        this.containerItem.transportCoordinator = null;
        this.containerItem.transportCoordinatorId = null;
      } else {
        for (let i = 0; i < types.length; i++) {
          this.containerItem[types[i]] = false;
        }
        this.containerItem.transportCoordinator = haulier;
        this.containerItem.transportCoordinatorId = haulier.id;
        // this.booking.preAdvise = true
      }
      this.haulierKey++;
      // this.haulierModal = false
    },
    setTransporter() {
      if (this.containerItem.transportCoordinator) {
        this.containerItem.transportCoordinatorId =
          this.containerItem.transportCoordinator.id;
      } else {
        this.containerItem.transportCoordinatorId = null;
      }
      if (
        this.containerItem.transportCoordinatorId ==
          this.$store.state.currentOrg.id &&
        this.booking.movementType == "EXPORT"
      ) {
        this.containerItem.preAdviseRequired = true;
      }
      this.preadviseKey++;
    },
    setIncoTerm(term) {
      this.booking.incoTerm = term.abbreviation;
      this.incoTermModal = false;
    },
    setMilestonePoint(item) {
      if (item.type) {
        this.containerItem[`${item.type}`] = item;
      }
      if (!this.containerItem.containerMilestones) {
        this.containerItem.containerMilestones = [];
      }
      let find = this.containerItem.containerMilestones.findIndex(
        (x) => x.type == item.type
      );
      if (find == -1) {
        this.containerItem.containerMilestones.push(item);
      } else {
        this.containerItem.containerMilestones[find] = item;
      }
      this.addMilestoneModal = false;
    },
    async setShipmentProfile(profile) {
      this.settingShipmentProfile = true;
      this.booking.dealTerm = null;
      this.booking.dealTermId = null;
      this.booking.incoTerm = null;
      this.booking.portOfLoadValue = null;
      this.booking.portOfLoadCity = null;
      this.booking.portOfDischargeValue = null;
      this.booking.portOfDischargeCity = null;
      this.booking.finalDestinationValue = null;
      this.booking.finalDestinationCity = null;
      this.booking.originCountry = null;
      this.booking.originCountryId = null;
      this.booking.shipperType = null;
      this.booking.consigneeType = null;
      this.booking.forwarderType = null;
      this.booking.buyerType = null;  
      if (
        !this.booking.originCountry &&
        this.$store.state.currentOrg.countryCode
      ) {
        let find = this.countries.find(
          (x) => x.iso2 == this.$store.state.currentOrg.countryCode
        );
        if (find) {
          this.booking.originCountry = find;
          this.booking.originCountryId = find.id;
        }
      }
      this.booking.destinationCountry = null;
      this.booking.destinationCountryId = null;
      let profileDetail = await this.$API.getConsigneeProfile(profile.id);
      this.profileDetail = profileDetail;
      this.booking.shipperType = profile.shipperType
      this.booking.consigneeType = profile.consigneeType
      this.booking.forwarderType = profile.forwarderType
      this.booking.buyerType = profile.buyerType
      if (profileDetail.bookingContainerItems.length > 0) {
        this.containerDialog = true;
        this.profileDetailKey++;
      }
      this.booking.consigneeProfile = profile;
      this.booking.ucrNo = profile.ucrNo;
      this.booking.consigneeProfileId = profile.id;
      let parties = [
        "shipper",
        "consignee",
        "forwarder",
        "buyer",
        "firstNotify",
        "secondNotify",
        "courierParty",
        "onBehalfShipper",
        "onBehalfConsignee",
        "onBehalfForwarder",
        "onBehalfBuyer",
        
      ];
      for (let i = 0; i < parties.length; i++) {
        this.booking[parties[i]] = profile[parties[i]];
        this.booking[parties[i] + "Id"] = profile[parties[i] + "Id"];
      }
      this.booking.type = profileDetail.type;
      this.booking.movementType = profileDetail.movementType;
      this.booking.serviceType = profileDetail.serviceType;
      this.booking.modeOfTransport = profileDetail.modeOfTransport;
      this.booking.originCountry = profile.originCountry;
      this.booking.originCountryId = profile.originCountryId;
      this.booking.destinationCountry = profile.destinationCountry;
      this.booking.destinationCountryId = profile.destinationCountryId;
      if (!this.booking.finalDestinationValue) {
        this.booking.finalDestinationValue =
          profileDetail.finalDestinationValue;
        this.booking.finalDestinationCity = profileDetail.finalDestinationCity;
      }
      this.booking.regimeCode = profileDetail.regimeCode
        ? profileDetail.regimeCode
        : this.booking.regimeCode;
      this.booking.regime = profileDetail.regime
        ? profileDetail.regime
        : this.booking.regime;
      this.booking.regimeId = profileDetail.regimeId
        ? profileDetail.regimeId
        : this.booking.regimeId;
      this.booking.caProduct = profileDetail.caProduct;
      this.booking.isPrivateHaulage = profileDetail.isPrivateHaulage;
      this.booking.isRailage = profileDetail.isRailage;
      this.booking.isCarrierHaulage = profileDetail.isCarrierHaulage;
      if (profileDetail.transportCoordinator) {
        this.booking.transportCoordinatorId =
          profileDetail.transportCoordinatorId;
        this.booking.transportCoordinator = profileDetail.transportCoordinator;
      }
      if (profileDetail.bookingContainerItems.length > 0) {
        if (!this.booking.bookingContainerItems) {
          this.booking.bookingContainerItems = [];
          this.booking.bookingContainerItems =
            profileDetail.bookingContainerItems;
        }

        this.booking.bookingContainerItems.forEach((x) => {
          delete x.id;
          delete x.consigneeProfileId;
          delete x.createdAt;
          delete x.updatedAt;
          x.bookingCargoItems.forEach((y) => {
            delete y.id;
            delete y.bookingContainerItemId;
            delete y.createdAt;
            delete y.updatedAt;
          });
          x.containerMilestones
            ? x.containerMilestones.forEach((y) => {
                delete y.id;
                delete y.bookingContainerItemId;
                delete y.date;
                delete y.createdAt;
                delete y.updatedAt;
              })
            : [];
        });
      }
      if (!profile.allDestinationPorts) {
        let findDest = profile.consigneeProfilePorts.filter(
          (x) => x.type == "discharge"
        );
        if (findDest.length == 1) {
          this.setPort("portOfDischarge", {
            locode: findDest[0].code,
            name: findDest[0].name,
          });
        }
      }

      if (!profile.allOriginPorts) {
        let findDest = profile.consigneeProfilePorts.filter(
          (x) => x.type == "loading"
        );
        if (findDest.length == 1) {
          this.setPort("portOfLoad", {
            locode: findDest[0].code,
            name: findDest[0].name,
          });
        }
      }
      if (
        profileDetail.consigneeProfileIncoTerms &&
        profileDetail.consigneeProfileIncoTerms.length == 1
      ) {
        this.setDealTerm(profileDetail.consigneeProfileIncoTerms[0]);
      }
      this.profileModal = false;
      this.contractKey++;
      this.allOriginPorts = await this.$API.getPortsByCountry(
        profile.originCountry.iso2
      );
      this.allDestinationPorts = await this.$API.getPortsByCountry(
        profile.destinationCountry.iso2
      );
      this.settingShipmentProfile = false;
    },
    setParty(organisation) {
      if (!this.containerItemModal) {
        switch (this.partyType) {
          case "Shipper":
            this.booking.shipper = organisation;
            this.booking.shipperId = organisation.id;
            break;
          case "Consignee":
            this.booking.consignee = organisation;
            this.booking.consigneeId = organisation.id;
            break;
          case "Forwarder":
            this.booking.forwarder = organisation;
            this.booking.forwarderId = organisation.id;
            break;
          case "Buyer":
            this.booking.buyer = organisation;
            this.booking.buyerId = organisation.id;
            break;
          case "First Notify":
            this.booking.firstNotify = organisation;
            this.booking.firstNotifyId = organisation.id;
            break;
          case "Second Notify":
            this.booking.secondNotify = organisation;
            this.booking.secondNotifyId = organisation.id;
            break;
          case "Courier Party":
            this.booking.courierParty = organisation;
            this.booking.courierPartyId = organisation.id;
            break;
          case "Stock Provider":
            this.booking.stockProvider = organisation;
            this.booking.stockProviderId = organisation.id;
            break;
        }
      } else {
        switch (this.partyType) {
          case "Consignee":
            this.containerItem.consignee = organisation;
            this.containerItem.consigneeId = organisation.id;
            break;
          case "Buyer":
            this.containerItem.buyer = organisation;
            this.containerItem.buyerId = organisation.id;
            break;
        }
      }
      this.partyModal = false;
    },
    setPort(type, item) {
      if (type == "portOfLoad") {
        this.booking.portOfLoadValue = item.locode ?? item.code;
        this.booking.portOfLoadCity = item.name;
        if (!this.booking.originCountry) {
          let findCountry = this.countries.find(
            (x) => x.iso2 == this.booking.portOfLoadValue.substring(0, 2)
          );
          if (findCountry) {
            this.booking.originCountry = findCountry;
            this.booking.originCountryId = findCountry.id;
          }
        }
      } else if (type == "portOfDischarge") {
        this.booking.portOfDischargeValue = item.locode ?? item.code;
        this.booking.portOfDischargeCity = item.name;
        if (!this.booking.finalDestinationValue) {
          this.booking.finalDestinationValue = item.locode ?? item.code;
          this.booking.finalDestinationCity = item.name;
          let findCountry = this.countries.find(
            (x) => x.iso2 == this.booking.finalDestinationValue.substring(0, 2)
          );
          if (findCountry) {
            this.booking.destinationCountry = findCountry;
            this.booking.destinationCountryId = findCountry.id;
          }
        }
      } else if (type == "finalDestination") {
        this.booking.finalDestinationValue = item.locode ?? item.code;
        this.booking.finalDestinationCity = item.name;
        let findCountry = this.countries.find(
          (x) => x.iso2 == this.booking.finalDestinationValue.substring(0, 2)
        );
        if (findCountry) {
          this.booking.destinationCountry = findCountry;
          this.booking.destinationCountryId = findCountry.id;
        }
        if (!this.booking.portOfDischargeValue) {
          this.booking.portOfDischargeValue = item.locode ?? item.code;
          this.booking.portOfDischargeCity = item.name;
        }
      }
      this.portSearch = null;
      this.polModal = false;
      this.podModal = false;
      this.fdModal = false;
    },
    async setRegimeCode(regime) {
      this.booking.regimeCode = regime.code;
      this.booking.regime = regime;
      this.booking.regimeId = regime.id;
      if (this.containerItem)
        this.containerItem.setPointTemp = regime.setPointTemp;
      if (this.booking.regimeId) {
        let ventSettings = await this.$API.getRegimeVent(this.booking.regimeId);
        if (ventSettings.length > 0) {
          this.ventSettings = [
            // "Closed",
            "5",
            "10",
            "15",
            "20",
            "25",
            "28",
            "30",
            "35",
            "40",
            "50",
            "Closed",
            "Fully Open",
            "CA",
            "MA",
            "Auto",
          ].filter((x) => ventSettings.map((y) => y.ventCode).includes(x));
        } else {
          this.ventSettings = [
            "5",
            "10",
            "15",
            "20",
            "25",
            "28",
            "30",
            "35",
            "40",
            "50",
            "Closed",
            "Fully Open",
            "CA",
            "MA",
            "Auto",
          ];
        }
      } else {
        this.ventSettings = [
          "5",
          "10",
          "15",
          "20",
          "25",
          "28",
          "30",
          "35",
          "40",
          "50",
          "Closed",
          "Fully Open",
          "CA",
          "MA",
          "Auto",
        ];
      }
      if (this.containerItem && this.ventSettings.length == 1) {
        this.containerItem.vents = this.ventSettings[0];
      }
      if (
        !this.booking.vents ||
        !this.ventSettings.includes(this.booking.vents)
      ) {
        this.booking.vents = this.ventSettings[0];
      }
      this.regimeCodeModal = false;
    },
    selectSchedule(schedule) {
      this.booking.carrier = schedule.carrier;
      this.booking.vessel = schedule.vessel;
      this.booking.vesselId = schedule.vesselId;
      this.booking.voyage = schedule.voyage;
      this.booking.sailingSchedule = schedule;
      this.booking.sailingScheduleId = schedule.id;
      this.booking.carrierScac = schedule.scac;
      this.booking.serviceName = schedule.serviceName;
      this.booking.eta = schedule.eta;
      this.booking.etd = schedule.etd;
      this.booking.atd = null;
      this.booking.ata = null;
      this.booking.shippingLine = schedule.shippingLine;
      this.booking.shippingLineId = schedule.shippingLineId;
      this.booking.carrierName = schedule.carrier;
      this.booking.mainCarriageVesselName = schedule.vesselDescription;
      this.booking.mainCarriageConveyanceNumber = schedule.voyage;
      this.booking.stackDate = schedule.stackDate;
      this.booking.stackDateId = schedule.stackDateId;
      this.booking.isTransshipment = !schedule.direct;
      this.booking.transportRouting = schedule.via;
      let terms = [];
      if (this.booking.consigneeProfile) {
        for (
          let i = 0;
          i < this.booking.consigneeProfile.consigneeProfileIncoTerms.length;
          i++
        ) {
          let term = this.booking.consigneeProfile.consigneeProfileIncoTerms[i];
          let result = term.consigneeProfileShippingContracts
            .filter((x) => x.shippingLine)
            .map((y) => ({
              id: term.id,
              incoTerm: term.incoTerm,
              shippingLine: y.shippingLine,
              scacCode: y.shippingLine.scacCode,
              contractNumber: y.contractNumber,
              contractOwner: y.contractOwner,
            }));
          terms = [...terms, ...result];
        }
      }
      this.suggestedDealTerms = terms.filter(
        (x) => x.scacCode == this.booking.carrierScac
      );
      if (this.suggestedDealTerms.length == 1) {
        this.booking.contractOwner = this.suggestedDealTerms[0].contractOwner;
        this.booking.contractNumber = this.suggestedDealTerms[0].contractNumber;
      }
      this.sailingScheduleModal = false;
    },
    setSelectableParties() {
      let keys = [];
      if (this.booking.movementType == "EXPORT") {
        keys = [
          "forwarder",
          "shipper",
          "firstNotifyParty",
          "secondNotifyParty",
        ];
      } else {
        keys = [
          "forwarder",
          "consignee",
          "shipper",
          "buyer",
          "firstNotifyParty",
          "secondNotifyParty",
        ];
      }

      let result = [];
      for (let i = 0; i < keys.length; i++) {
        if (this.booking[keys[i] + "Id"]) {
          result.push(this.booking[keys[i]]);
        }
      }
      if (this.booking.customerId) {
        let findCustomer = result.find((x) => x.id == this.booking.customerId);
        if (!findCustomer) {
          result.unshift(this.booking.customer);
        }
      }
      let findCurrentOrg = result.find(
        (x) => x.id == this.$store.state.currentOrg.id
      );
      if (!findCurrentOrg) {
        result.unshift(this.$store.state.currentOrg);
      }
      this.selectableParties = result;
    },
    setShippingLine(item) {
      this.booking.shippingLine = item;
      this.booking.shippingLineId = item.id;
      this.booking.carrier = item;
      this.booking.carrierId = item.id;
      this.shippingLineModal = false;
    },
    setVessel(vessel) {
      this.booking.vessel = vessel;
      this.booking.vesselId = vessel.id;
      this.vesselModal = false;
      this.voyageNumberModal = true;
    },
    updateHazardousStatus() {
      if (this.cargoItem.hazardous) {
        this.booking.isHazardous = true;
      } else {
        this.booking.isHazardous = this.booking.bookingContainerItems.some(
          (x) => x.bookingCargoItems.some((y) => y.hazardous == true)
        );
      }
    },
  },
};
</script>
<style scoped>
.tabby ::v-deep.theme--dark.v-tabs .v-tabs-bar,
.tabby ::v-deep .v-tabs-bar__content,
.tabby ::v-deep .v-tab {
  background: var(--v-background-base) !important;
  border-radius: 0px;
  height: 30px !important;
}
.infoCards {
  background: var(--v-greyDarker-base) !important ;
  background-color: var(--v-greyDarker-base) !important ;
  contain: content;
  border-radius: 10px;
}
.pulse-hazardous {
  animation: pulse-hazardous 1.5s infinite;
  z-index: 10;
}

@keyframes pulse-hazardous {
  0% {
    border-radius: 50%;
    box-shadow: 0 0 0 0px #0e871e;
  }

  100% {
    border-radius: 50%;
    box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
  }
}
</style>