<template>
  <div v-if="item">
    <v-icon :color="item == true ? 'success' : 'red'">
      {{ item == true ? 'check' : 'clear' }}
    </v-icon>
  </div>
</template>

<script>
export default {
  data: () => ({
    group: null,
    item: null,
  }),
  mounted() {
    this.item = this.params.value;
    if (this.params.node && this.params.node.group && this.params.node.key) {
      this.group = this.params.value;
    }
  },
};
</script>

<style></style>