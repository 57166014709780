<template>
  <div style="height: 100%">
    <v-toolbar flat dense>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-chip small label outlined class="primaryText--text mx-1 smallText" v-on="on">
            <v-icon x-small left>category</v-icon> Date Type: {{ formatDateType(params.dateType)
            }}<v-icon small right>expand_more</v-icon>
          </v-chip>
        </template>
        <v-list style="overflow: hidden" dense>
          <v-list-item v-for="(mileType, index) in milestoneTypes" :key="index" @click="changeDateType(mileType.value)">
            <v-list-item-content>
              <v-list-item-title>
                {{ mileType.name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-chip small label outlined class="primaryText--text mx-1 smallText" v-on="on">
            <v-icon x-small left>event</v-icon>
            {{
              quickSelectDates.find((x) => x.value == params.selectedDate)
                ? quickSelectDates.find((x) => x.value == params.selectedDate)
                  .text
                : "Select Date"
            }}<v-icon small right>expand_more</v-icon>
          </v-chip>
        </template>
        <v-list style="overflow: hidden" dense>
          <v-list-item v-for="(date, index) in quickSelectDates" :key="index" @click="selectDate(date.value)">
            <v-list-item-content>
              <v-list-item-title>
                {{ date.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu v-if="params.selectedDate == 'select_dates'" ref="menu" v-model="dateMenu" :close-on-content-click="false"
        transition="scale-transition" offset-y min-width="auto">
        <template v-slot:activator="{ on, attrs }">
          <v-chip color="primary" class="mx-1" outlined label v-bind="attrs" v-on="on" small><v-icon x-small
              left>date_range</v-icon>{{ dateText }}
            <v-icon small right>expand_more</v-icon></v-chip>
        </template>
        <v-date-picker v-model="params.dates" range color="primary" show-week scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary"> Cancel </v-btn>
          <v-btn text color="primary" @click="setDateFilter"> OK </v-btn>
        </v-date-picker>
      </v-menu>

      <v-spacer></v-spacer>
      <v-chip class="mr-1 searchChipWrap" style="max-width:550px">
        <v-icon small color="grey" class="ml-1">search</v-icon>
        <v-text-field placeholder="Search" class="mb-1 searchInput" hide-details rounded clearable dense
          style="margin-top: 2px" v-model="search"></v-text-field>

        <v-tooltip top v-if="filterActive">
          <template v-slot:activator="{ on }">
            <v-btn rounded right small icon color="redPop" @click="resetFilters" v-on="on">
              <v-icon>filter_alt_off</v-icon>
            </v-btn>
          </template>
          <span style="font-size: 12px">Clear Filters</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn icon right small color="blue" @click="getContainers" v-on="on">
              <v-icon>autorenew</v-icon>
            </v-btn>
          </template>
          <span style="font-size: 12px">Refresh Containers</span>
        </v-tooltip>
      </v-chip>
    </v-toolbar>

    <v-dialog v-model="assignToView" width="90vw">
      <AssignContainers :chosenContainers="chosenContainer" @close="assignToView = false" />
    </v-dialog>

    <v-dialog v-model="viewContainer" width="94vw" persistent>
      <ViewContainer v-if="container && container.id" :container="container" :booking="container.booking"
        :modalOpen="viewContainer" @close="viewContainer = !viewContainer, container = {}" />
    </v-dialog>

    <v-dialog v-model="confirmRemoveTransporter" width="500px">
      <v-card>
        <v-toolbar dense flat color="transparent">
          <v-spacer></v-spacer>
          <v-btn text @click="confirmRemoveTransporter = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <p style="font-size: 16px">
            Are you sure you would like to remove the transporter from these
            {{ transporter ? '1' : null }} items?
          </p>
          <v-row justify="center" class="mt-5 mb-3">
            <v-btn color="red" class="mx-2" text small @click="confirmRemoveTransporter = false">No</v-btn>
            <v-btn color="success" class="mx-2" text small @click="removeTransporter">Yes</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="bulkUpdateStatusModal" width="400px">
      <v-card :loading="savingBulkStatus">
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Set Status </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="bulkUpdateStatusModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-list dense>
            <v-list-item v-for="(status, index) in transportStatuses" :key="index" style="height: 35px"
              @click="setBulkStatus(status.text, status.value)">
              <v-list-item-action>
                <v-icon :color="status.color">fiber_manual_record</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ status.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>


    <ag-grid-vue 
      groupDisplayType="multipleColumns" 
      class="ag-grid-theme-builder"
      :animateRows="false" 
      :rowGroupPanelShow="'always'"
      :autoSizeStrategy="{ type: 'fitCellContents', defaultMinWidth: 150, columnLimits: [], }" 
      style="width: 100%; height: 95%" 
      :enableRangeSelection="true" 
      :allowContextMenuWithControlKey="true"
      :columnDefs="columns" 
      :rowData="containers" 
      :getContextMenuItems="getContextMenuItems"
      :suppressAggFuncInHeader="true"
      :defaultColDef="defaultColDef.def"
      :sideBar="sidebar"
      :statusBar="statusBar"
      :getRowId="getRowId"
      @columnMoved="columnsChanged"
      @columnPivotChanged="columnsChanged"
      @newColumnsLoaded="columnsChanged"
      @columnValueChanged="columnsChanged"
      @columnPinned="columnsChanged"
      @displayedColumnsChanged="columnsChanged"
      @columnResized="columnsChanged"
      @columnRowGroupChanged="columnsChanged"
      @columnPivotModeChanged="columnsChanged"
      @grid-ready="onGridReady">
    </ag-grid-vue>
  </div>
</template>
<script>
/* eslint-disable vue/no-unused-components */
import ActionPanel from "./InterModalComponents/ActionPanel.vue";
import { AgGridVue } from "ag-grid-vue";
import ViewContainer from "./InterModalComponents/Dialogs/ViewContainer.vue";
import AssignContainers from "./InterModalComponents/Dialogs/AssignContainers.vue"
import Boolean from "../GridComponents/Boolean.vue";
import Preadvise from "./InterModalComponents/CellRenders/Preadvise.vue";
import BookingModal from "../Bookings/BookingModal.vue";
import BookingState from "./BookingComponents/BookingState.vue";
import ContainerStatus from "../GridComponents/ContainerStatus.vue";
import BookingType from "./BookingComponents/BookingType.vue";
import FileStatus from "./ContainerComponents/FileStatus.vue";
import dateFormat from "dateformat";
import * as moment from "moment";
import Stacks from "./InterModalComponents/CellRenders/Stacks.vue";
import MovementType from "./BookingComponents/MovementType.vue";
import Ready from "./InterModalComponents/CellRenders/Ready.vue";
import Dummy from "./InterModalComponents/CellRenders/Dummy.vue";
import Status from "./InterModalComponents/CellRenders/Status.vue";
import OrganisationName from "../GridComponents/Organisation.vue";
import PortCell from "../GridComponents/Port.vue";
import ContainerStockStatus from "../GridComponents/ContainerStockStatus.vue";
import TableHeader from "../GridComponents/TableHeader.vue";
import TemporaryProducts from "../GridComponents/TemporaryProducts.vue";
import TransportStatus from "../GridComponents/TransportStatus.vue";
import UserCell from "../GridComponents/User.vue";
import Templates from "../AgGrid/GridSideBar/Templates.vue"
export default {
  components: {
    ActionPanel,
    Preadvise,
    ViewContainer,
    Stacks,
    AgGridVue,
    Boolean,
    Ready,
    Dummy,
    Status,
    AssignContainers,
    BookingModal,
    BookingState,
    BookingType,
    ContainerStatus,
    ContainerStockStatus,
    FileStatus,
    MovementType,
    OrganisationName,
    PortCell,
    agColumnHeader: TableHeader,
    TemporaryProducts,
    TransportStatus,
    UserCell,
    Templates,
  },
  data: () => ({
    columns: [],
    viewContainer: false,
    containers: null,
    containerQuery: null,
    sideBar: null,
    tableType: "Intermodal",
    container: null,
    confirmRemoveTransporter: false,
    chosenContainer: null,
    transporter: null,
    status: null,
    assignToView: false,
    bulkUpdateStatusModal: false,
    containerTimeout: null,
    dateKey: 200,
    dateMenu: false,
    defaultColDef: {
      def: {
        sortable: true,
        resizable: true,
        filter: true,
        enableRowGroup: true,
        enablePivot: true,
        flex: 1,
        minWidth: 100,
        cellClass: "d-flex align-center",
        cellStyle: { textAlign: "center", fontSize: "11px" },
      },
    },
    filterActive: false,
    filterSetting: {},
    gridApi: null,
    initialState: {},
    loading: false,
    milestoneTypes: [
      {
        name: "ETD",
        value: "ETD",
      },
      {
        name: "ETA",
        value: "ETA",
      },
      {
        name: "Loading Date",
        value: "LOADING_DATE",
      },
    ],
    params: {
      dateType: "ETD",
    },
    quickSelectDates: [
      {
        text: "Specify Dates",
        type: "current",
        value: "select_dates",
        difference: 0,
      },
      {
        text: 'Today',
        type: 'current',
        value: 'today',
        difference: 0
      },
      {
        text: 'Current 3 Days',
        type: 'current',
        value: 'current_3day',
        difference: 0
      },
      {
        text: "This week",
        type: "current",
        value: "current_week",
        difference: 0,
      },
      {
        text: "Next 2 weeks",
        type: "future",
        value: "2_week",
        difference: 0,
      },
    ],
    transportStatuses: [
      {
        text: 'On Hold',
        value: 'onHold',
        color: 'brown lighten-1'
      },
      {
        text: 'Breakdown',
        value: 'BREAKDOWN',
        color: 'red'
      },
      {
        text: "Unallocated",
        value: "PENDING",
        color: "orange",
      },
      {
        text: 'Turn Load',
        value: 'turnLoad',
        color: 'yellow'
      },
      {
        text: "Allocated",
        value: "ALLOCATED",
        color: "lime darken-3",
      },
      {
        text: 'Pre-Loaded',
        value: 'PRE_LOADED',
        color: 'blue-grey darken-2'
      },
      {
        text: 'Collect Tomorrow',
        value: 'COLLECT_TOMORROW',
        color: 'orange darken-2'
      },
      {
        text: 'Collect Later Today',
        value: 'COLLECT_LATER_TODAY',
        color: 'orange darken-2'
      },
      {
        text: "Instr. Sent",
        value: "INSTRUCTION_SENT",
        color: "blue-grey",
      },
      {
        text: "Empty Picked Up",
        value: "GATE_OUT_EMPTY_PICKUP",
        color: "teal",
      },
      {
        text: "En route LP-1",
        value: "EN_ROUTE_LP1",
        color: "cyan darken-2",
      },
      {
        text: "At LP-1",
        value: "AT_LP1",
        color: "cyan darken-4",
      },
      {
        text: "En route LP-2",
        value: "EN_ROUTE_LP2",
        color: "purple darken-2",
      },
      {
        text: "At LP-2",
        value: "AT_LP2",
        color: "purple darken-4",
      },
      {
        text: "En route Gas",
        value: "EN_ROUTE_GAS",
        color: "green lighten-2",
      },
      {
        text: "At Gassing",
        value: "AT_GASSING",
        color: "green lighten-3",
      },
      {
        text: "En route WB",
        value: "EN_ROUTE_WB",
        color: "blue",
      },
      {
        text: "AT WB",
        value: "AT_WB",
        color: "blue lighten-1",
      },
      {
        text: "En route Depot",
        value: "EN_ROUTE_DEPOT",
        color: "blue darken-4",
      },
      {
        text: "At Depot",
        value: "AT_DEPOT",
        color: "blue darken-5",
      },
      {
        text: 'Overnight Genset',
        value: 'OVERNIGHT_GENSET',
        color: '#363062'
      },
      {
        text: 'En route Plug-in',
        value: 'EN_ROUTE_PLUG_IN',
        color: 'blue lighten-2'
      },
      {
        text: 'Plugged-in',
        value: 'PLUG_IN',
        color: 'blue lighten-3'
      },
      {
        text: "En route Port",
        value: "EN_ROUTE_PORT",
        color: "blue darken-1",
      },
      {
        text: "At Port",
        value: "AT_PORT",
        color: "blue darken-2",
      },
      {
        text: "Gate In",
        value: "FULL_DROP_OFF",
        color: "#183A1D",
      },
      {
        text: 'Stacked',
        value: 'STACKED',
        color: 'success'
      },
      {
        text: 'Preadvise Only',
        value: 'preadviseOnly',
        color: 'pink darken-2'
      },
      {
        text: 'Arrange Plugged-In',
        value: 'preadviseOnly',
        color: '#F4CE14'
      },
      {
        text: 'Cancelled',
        value: 'cancelled',
        color: 'red darken-2'
      }
    ],
    search: null,
    searchTimeout: null,
    statusBar: {
      statusPanels: [
        {
          statusPanel: "agTotalRowCountComponent",
          align: "left",
        },
        {
          statusPanel: "agFilteredRowCountComponent",
        },
        {
          statusPanel: "agSelectedRowCountComponent",
        },
        {
          statusPanel: "agAggregationComponent",
        },
      ],
    },
  }),
  watch: {
    search(val) {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout(async () => {
        this.gridApi.updateGridOptions({ quickFilterText: val });
      }, 400);
    },
  },
  computed: {
    dateText() {
      if (this.params.dates.length === 0) {
        return "Select Dates";
      } else if (this.params.dates.length === 1) {
        return "Week: " + moment(this.params.dates[0]).isoWeek();
      } else {
        return `${this.params.dates[0]} - ${this.params.dates[1]}`;
      }
    },
  },
  created() {
    this.columns = [
      {
        headerName: "User",
        field: "userFullName",
        cellRenderer: "UserCell",
        cellRendererParams: (p) => {
          return p.data && p.data.user
            ? {
              name: p.data.user.firstname,
              surname: p.data.user.surname,
              avatar: p.data.user.avatar,
            }
            : null;
        },
      },
      {
        headerName: "Port",
        field: "portOfLoadValue",
        cellRenderer: "PortCell",
        cellRendererParams: (p) => {
          return {
            locode: p.data ? p.data.portOfLoadValue : null,
            name: p.data ? p.data.portOfLoadCity : null,
          };
        },
      },
      {
        headerName: "Product",
        field: "products",
      },
      {
        headerName: "Features",
        field: "temporaryProducts",
        filter: false,
        cellRenderer: "TemporaryProducts",
        cellStyle: { textAlign: "center", justifyContent: "center" },
      },
      {
        headerName: "Movement",
        field: "movementType",
        cellRenderer: "MovementType",
        cellStyle: { textAlign: "center", justifyContent: "center" },
      },
      {
        headerName: "Confirmed Booking",
        field: "isConfirmedBooking",
        cellRenderer: "Ready",
        cellRendererParams: (p) => {
          return p.data.isConfirmedBooking
        },
        cellStyle: { textAlign: "center", justifyContent: "center" },
      },
      {
        headerName: "Transporter",
        field: "transporter.name",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.transporter
            ? {
              organisation: p.data.transporter,
            }
            : null;
        },
      },
      {
        headerName: "Consignee",
        field: "consigneeName",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.consignee
            ? {
              organisation: p.data.consignee,
            }
            : null;
        },
      },
      {
        headerName: "Customer",
        field: "customer.name",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.customer
            ? {
              organisation: p.data.customer,
            }
            : null;
        },
      },
      {
        headerName: "Transport Status",
        field: "transportStatus",
        cellRenderer: "Status"
      },
      {
        headerName: "Vessel",
        field: "vesselVoyage",
      },
      {
        headerName: "Gross Weight",
        field: "grossWeight",
      },
      {
        headerName: "Dry Stack Start Date",
        field: "stackDate.dryStackStart",
        cellRenderer: "Stacks"
      },
      {
        headerName: "Dry Stack Finish Date",
        field: "stackDate.dryStackFinish",
        cellRenderer: "Stacks"
      },
      {
        headerName: "Reefer Stack Start Date",
        field: "stackDate.reeferStackStart",
        cellRenderer: "Stacks"
      },
      {
        headerName: "Reefer Stack Finish Date",
        field: "stackDate.reeferStackFinish",
        cellRenderer: "Stacks"
      },
      {
        headerName: "Order No",
        field: "orderNo",
      },
      {
        headerName: "Customer Ref",
        field: "customerContainerRef",
      },
      {
        headerName: "Preadvise",
        field: "preadviseStatus",
        cellRenderer: "Preadvise"
      },
      {
        headerName: "Cto No",
        field: "ctoNo",
      },
      {
        headerName: "Container No",
        field: "containerNo",
      },
      {
        headerName: "Dummy Weight",
        field: "dummyWeight",
        cellRenderer: "Dummy",
        cellRendererParams: (p) => {
          return p.data.dummyWeight
        },
        cellStyle: { textAlign: "center", justifyContent: "center" },
      },
      {
        headerName: "Type",
        field: "containerTypeCode",
      },
      {
        headerName: "Nett Weight",
        field: "nettWeight",
      },
      {
        headerName: 'Milestones',
        children: [
          {
            headerName: 'Empty Depot', field: 'milestones.EMPTY_PICKUP.description', valueFormatter: (p) => {
              return p.data && p.data.milestones && p.data.milestones.EMPTY_PICKUP ? p.data.milestones.EMPTY_PICKUP.description : null
            }
          },
          {
            headerName: 'LP-1', field: 'milestones.LOADING_POINT_1.description', valueFormatter: (p) => {
              return p.data && p.data.milestones && p.data.milestones.LOADING_POINT_1 ? p.data.milestones.LOADING_POINT_1.description : null
            }
          },
          {
            headerName: 'Loading Date 1', field: 'milestones.LOADING_POINT_1.date', valueFormatter: (p) => {
              return p.data && p.data.milestones && p.data.milestones.LOADING_POINT_1 ? this.$Format.formatDate(p.data.milestones.LOADING_POINT_1.date).isoFormat : null
            }
          },
          {
            headerName: 'Loading Time 1', field: 'milestones.LOADING_POINT_1.time', valueFormatter: (p) => {
              return p.data && p.data.milestones && p.data.milestones.LOADING_POINT_1 ? p.data.milestones.LOADING_POINT_1.time : null
            }
          },
          {
            headerName: 'LP-2', field: 'milestones.LOADING_POINT_2.description', valueFormatter: (p) => {
              return p.data && p.data.milestones && p.data.milestones.LOADING_POINT_2 ? p.data.milestones.LOADING_POINT_2.description : null
            }
          },
          // {
          //   headerName: 'Loading Date 2', field: 'milestones.LOADING_POINT_2.date', valueFormatter: (p) => {
          //     return p.data && p.data.milestones && p.data.milestones.LOADING_POINT_2 ? this.$Format.formatDate(p.data.milestones.LOADING_POINT_2.date).isoFormat : null
          //   }
          // },
          // {
          //   headerName: 'Loading Time 2', field: 'milestones.LOADING_POINT_2.time', valueFormatter: (p) => {
          //     return p.data && p.data.milestones && p.data.milestones.LOADING_POINT_2 ? p.data.milestones.LOADING_POINT_2.time : null
          //   }
          // },
          {
            headerName: 'Plug-in Depot', field: 'milestones.PLUG_IN.description', valueFormatter: (p) => {
              return p.data && p.data.milestones && p.data.milestones.PLUG_IN ? p.data.milestones.PLUG_IN.description : null
            }
          },
          {
            headerName: 'Drop Off', field: 'milestones.FULL_DROP_OFF.description', valueFormatter: (p) => {
              return p.data && p.data.milestones && p.data.milestones.FULL_DROP_OFF ? p.data.milestones.FULL_DROP_OFF.description : null
            }
          },
        ],
      },
    ]
    if (
      localStorage["containerSearchParams_" + this.$store.state.currentOrg.id]
    ) {
      this.params = JSON.parse(
        localStorage["containerSearchParams_" + this.$store.state.currentOrg.id]
      );
      if (!this.params) {
        this.params = {};
      }
      if (!this.params.dateType) {
        this.params.dateType = "ETD";
      }
      if (
        this.params.selectedDate &&
        this.params.selectedDate != "select_dates"
      ) {
        this.selectDate(this.params.selectedDate);
      }
    } else {
      this.params = {
        dateType: "ETD",
        selectedDate: "current_3day",
        dates: [],
      };
      this.selectDate("current_3day");
    }

    this.sidebar = {
      toolPanels: [
        {
          id: "actions",
          labelDefault: "Actions",
          labelKey: "actions",
          iconKey: "chart",
          toolPanel: "ActionPanel",
          minWidth: 300,
          maxWidth: 500,
          width: 300,
          toolPanelParams: {
            setTeamFilter: (teams, allTeams) => {
              this.selectedTeams = teams;
              this.params.allTeams = allTeams;
              this.getContainers();
            },
            resetTable: this.resetTable,
            exportExcel: () => {
              this.gridApi.exportDataAsExcel();
            },
            exportCSV: () => {
              this.gridApi.exportDataAsCsv();
            },
          },
        },
        {
          id: "templates",
          labelDefault: "Templates",
          labelKey: "templates",
          iconKey: 'colorPicker',
          toolPanel: "Templates",
          minWidth: 300,
          maxWidth: 500,
          width: 300,
          toolPanelParams: {
            tableType: this.tableType,
          },
        },
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          minWidth: 225,
          maxWidth: 225,
          width: 225,
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
          },
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
          minWidth: 180,
          maxWidth: 400,
          width: 250,
        },
      ],
      defaultToolPanel: "ActionPanel",
    };
  },
  methods: {
    columnsChanged() {
      if(this.gridApi) {
          let columnState = this.gridApi.getColumnState()
          localStorage.setItem(`columnState-${this.tableType}-${this.$store.state.user.id}-${this.$store.state.currentOrg.id}`, JSON.stringify(columnState))
          localStorage.setItem(`pivotMode-${this.tableType}-${this.$store.state.user.id}-${this.$store.state.currentOrg.id}`, JSON.stringify({pivotMode: this.gridApi.isPivotMode()}))
      }
    },
    changeDateType(name) {
      if (name == "ETD") {
        this.params.dateType = "ETD";
      } else if (name == "ETA") {
        this.params.dateType = "ETA";
      } else if (name == "LOADING_DATE") {
        this.params.dateType = "LOADING DATE";
      }
      this.dateKey++;
      this.getContainers();
    },
    formatDateType(type) {
      let find = this.milestoneTypes.find(x => x.value == type);
      return find ? find.name : type;
    },
    async getContainers() {
      if (this.containerTimeout) {
        clearTimeout(this.containerTimeout);
      }
      if (this.loading && this.containerQuery) {
        this.containerQuery.abort();
      }
      this.loading = true;
      // if (this.gridApi) {
      //   // this.gridApi.setRowData(null);
      //   this.gridApi.showLoadingOverlay();
      // }
      let params = { ...this.params };
      params.teams = this.selectedTeams;
      if (this.params.allTeams) {
        delete params.teams
      }
      if (this.params.dates.length == 1) {
        params.dates = [
          moment(this.params.dates[0])
            .startOf("isoWeek")
            .toISOString()
            .substring(0, 10),
          moment(this.params.dates[0])
            .endOf("isoWeek")
            .toISOString()
            .substring(0, 10),
        ];
      }
      this.selectedBookings = [];
      this.containerTimeout = setTimeout(async () => {
        this.containerQuery = new AbortController();
        const signal = this.containerQuery.signal;
        let result = await this.$API.getBookingContainerOverviewUpdated({
          params: params,
          signal,
        });
        this.containers = result;
        this.gridApi.setRowData(this.containers);
        this.loading = false;
      }, 500);
    },
    getContextMenuItems(params) {
      let result = [];
      if (!params.node.group) {
        result = [
          {
            name: "View Container",
            icon: '<span class="material-icons" unselectable="on" role="presentation" style="font-size: 16px; color: grey; margin-bottom: 0px; padding-bottom: 0px">launch</span>',
            action: () => {
              if (params.node && params.node.data && params.node.data.id) {
                console.log(params.node.data)
                this.viewInterModalContainer(params.node.data);
              } else {
                this.$message.error(
                  "Please select a single row to view the file"
                );
              }
            },
          },
          {
            name: "View Container(New)",
            icon: '<span class="material-icons" unselectable="on" role="presentation" style="font-size: 16px; color: grey; margin-bottom: 0px; padding-bottom: 0px">launch</span>',
            disabled: true
          },
          "separator",
          {
            name: "Manage",
            icon: '<span class="material-icons" unselectable="on" role="presentation" style="font-size: 16px; color: grey; margin-bottom: 0px; padding-bottom: 0px">launch</span>',
            subMenu: [
              {
                name: "Transporter",
                subMenu: [
                  {
                    name: "Assign Transporter",
                    action: () => {
                      this.assignContainers(params.node.data)
                    }
                  },
                  {
                    name: "Assign Transporter(New)",
                    disabled: true
                  },
                  {
                    name: "Remove Transporter",
                    action: () => {
                      this.removeTransporterModal(params.node.data)
                    }
                  },
                  {
                    name: "Remove Transporter(New)",
                    disabled: true

                  }
                ]
              },
              "separator",
              {
                name: "Update Status",
                action: () => {
                  this.updateStatusModal(params.node.data)
                }
              },
              {
                name: "Update Status(New)",
                disabled: true
              },
              "separator",
            ]
          },
          "separator",
          {
            name: "Add to Filter",
            icon: '<span class="ag-icon ag-icon-filter" unselectable="on" role="presentation"></span>',
            action: () => {
              if (params.node && params.node.data) {
                let filter = this.gridApi.getFilterModel();
                if (filter[params.column.colId]) {
                  filter[params.column.colId].values.push(params.value);
                } else {
                  filter[params.column.colId] = {
                    values: [params.value],
                  };
                }
                this.gridApi.setFilterModel(filter);
              } else {
                this.$message.error(
                  "Please select a single row to view the file"
                );
              }
            },
          },
          "separator",
          "autoSizeAll",
          "expandAll",
          "contractAll",
          "resetColumns",
          "copy",
          "copyWithHeaders",
          "export",
          "chartRange",
        ];
      } else {
        result = [
          "autoSizeAll",
          "expandAll",
          "contractAll",
          "resetColumns",
          "copy",
          "copyWithHeaders",
          "export",
          "chartRange",
        ];
      }

      return result;
    },
    postProcessPopup(params) {
      if (params.type !== "columnMenu") {
        return;
      }
      const ePopup = params.ePopup;
      ePopup.style.top = "65px";
      ePopup.style.left =
        params.column.actualWidth / 2 + params.mouseEvent.left + "px";
    },
    assignContainers(item) {
      this.assignToView = true;
      this.chosenContainer = item
    },
    updateStatusModal(item) {
      this.bulkUpdateStatusModal = true;
      this.status = item
    },
    removeTransporterModal(item) {
      this.confirmRemoveTransporter = true;
      this.transporter = item
    },
    filterListener(event) {
      let filter = this.gridApi.getFilterModel();
      this.filterActive = Object.keys(filter).length > 0;
      this.filterSetting = filter;
      localStorage.setItem(`filterModel-${this.tableType}-${this.$store.state.user.id}-${this.$store.state.currentOrg.id}`, JSON.stringify(this.gridApi.getFilterModel()))
    },
    formatDate(date) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "dd-mm-yyyy");
      }
      return result;
    },
    formatDateTime(date) {
      let result = null;
      if (date) {
        result = dateFormat(date, "yyyy-mm-dd HH:MM");
      }
      return result;
    },
    getRowId(params) {
      return params.data.id;
    },
    async load() { },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridApi.addEventListener("filterChanged", this.filterListener);
      this.getContainers();
    },
    async setBulkStatus(status, statusCode) {
      let data = {
        id: this.status.id,
        transportStatus: status,
        transportStatusCode: statusCode,
        intermodalUpdate: true,
      };
      await this.$API.updateContainer(data);
      this.getContainers();
      this.$message.success("Successfully updated!");
      this.bulkUpdateStatusModal = false;
    },
    async resetFilters() {
      this.gridApi.setFilterModel({});
    },
    async removeTransporter(item) {
      let data = {
        id: this.transporter.id,
        transportStatus: "Unallocated",
        transporter: null,
        transporterId: null,
      };

      await this.$API.updateContainer(data);

      this.savingRemoveTransporter = false;
      this.confirmRemoveTransporter = false;
      this.$message.success("Successfully removed allocated transporter!");
      this.getContainers();
    },
    resetTable() {
      this.gridApi.setFilterModel(null);
      this.gridApi.setRowGroupColumns([]);
      this.gridApi.applyColumnState({ state: this.columns });
      this.gridApi.refreshCells({ force: true });
    },
    selectDate(value) {
      this.params.selectedDate = value;
      this.setDateRange(value);
      localStorage.setItem(
        "containerSearchParams_" + this.$store.state.currentOrg.id,
        JSON.stringify(this.params)
      );
    },
    setDateFilter() {
      if (this.params.dates.length == 2) {
        if (this.params.dates[1] < this.params.dates[0]) {
          let dates = [this.params.dates[1], this.params.dates[0]];
          this.params.dates = dates;
        }
      }
      localStorage.setItem(
        "containerSearchParams_" + this.$store.state.currentOrg.id,
        JSON.stringify(this.params)
      );
      this.dateMenu = false;
      this.getContainers();
    },
    updateAssignedContainers(containers) {
      containers.forEach((x) => {
        let find = this.containers.find((y) => y.id == x.id);
        if (find) {
          find = { ...find, ...x };
          if (x.transporter) {
            x.transporterName = x.transporter.alias;
          }
        }
      });
      this.assignToView = false;
      this.selectedContainers = [];
    },
    setDateRange(val) {
      switch (val) {
        case "current_3day":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "past_month":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 30))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "current_week":
          this.params.dates = [
            new Date(
              new Date().setDate(new Date().getDate() - new Date().getDay() + 1)
            )
              .toISOString()
              .substring(0, 10),
            new Date(
              new Date().setDate(new Date().getDate() - new Date().getDay() + 7)
            )
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "current_month":
          this.params.dates = [
            new Date(new Date().setDate(1)).toISOString().substring(0, 10),
            new Date().toISOString().substring(0, 10),
          ];
          break;
        case "past_day":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "past_3day":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 3))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "past_week":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 7))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 30))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "today":
          this.params.dates = [
            new Date().toISOString().substring(0, 10),
            new Date().toISOString().substring(0, 10),
          ];
          break;
        case "tomorrow":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "next_week":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 7))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "2_week":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 14))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "6_week":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 42))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "next_month":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 30))
              .toISOString()
              .substring(0, 10),
          ];
          break;
      }
      this.getContainers();
    },
    viewInterModalContainer(item) {
      this.viewContainer = true
      this.container = item

    }
  },
};
</script>


<style scoped>
.ag-grid-theme-builder {
    --ag-icon-image-color-picker: url("../../../public/icons/view_carousel.svg"); 
    --ag-icon-font-display-color-picker: none;
    --ag-icon-font-color-color-picker: transparent;
}

.ag-grid-theme-builder ::v-deep .ag-icon.ag-icon-color-picker {
    scale: 1.2;
}
</style>