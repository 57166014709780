<template>
    <div v-if="item">
        <v-icon :color="getStatusColor(item)">fiber_manual_record</v-icon>
        {{ item }}
    </div>
</template>

<script>
export default {
    data: () => ({
        group: null,
        item: null,
        transportStatuses: [
            {
                text: 'On Hold',
                value: 'onHold',
                color: 'brown lighten-1'
            },
            {
                text: 'Breakdown',
                value: 'BREAKDOWN',
                color: 'red'
            },
            {
                text: "Unallocated",
                value: "PENDING",
                color: "orange",
            },
            {
                text: 'Turn Load',
                value: 'turnLoad',
                color: 'yellow'
            },
            {
                text: "Allocated",
                value: "ALLOCATED",
                color: "lime darken-3",
            },
            {
                text: 'Pre-Loaded',
                value: 'PRE_LOADED',
                color: 'blue-grey darken-2'
            },
            {
                text: 'Collect Tomorrow',
                value: 'COLLECT_TOMORROW',
                color: 'orange darken-2'
            },
            {
                text: 'Collect Later Today',
                value: 'COLLECT_LATER_TODAY',
                color: 'orange darken-2'
            },
            {
                text: "Instr. Sent",
                value: "INSTRUCTION_SENT",
                color: "blue-grey",
            },
            {
                text: "Empty Picked Up",
                value: "GATE_OUT_EMPTY_PICKUP",
                color: "teal",
            },
            {
                text: "En route LP-1",
                value: "EN_ROUTE_LP1",
                color: "cyan darken-2",
            },
            {
                text: "At LP-1",
                value: "AT_LP1",
                color: "cyan darken-4",
            },
            {
                text: "En route LP-2",
                value: "EN_ROUTE_LP2",
                color: "purple darken-2",
            },
            {
                text: "At LP-2",
                value: "AT_LP2",
                color: "purple darken-4",
            },
            {
                text: "En route Gas",
                value: "EN_ROUTE_GAS",
                color: "green lighten-2",
            },
            {
                text: "At Gassing",
                value: "AT_GASSING",
                color: "green lighten-3",
            },
            {
                text: "En route WB",
                value: "EN_ROUTE_WB",
                color: "blue",
            },
            {
                text: "AT WB",
                value: "AT_WB",
                color: "blue lighten-1",
            },
            {
                text: "En route Depot",
                value: "EN_ROUTE_DEPOT",
                color: "blue darken-4",
            },
            {
                text: "At Depot",
                value: "AT_DEPOT",
                color: "blue darken-5",
            },
            {
                text: 'Overnight Genset',
                value: 'OVERNIGHT_GENSET',
                color: '#363062'
            },
            {
                text: 'En route Plug-in',
                value: 'EN_ROUTE_PLUG_IN',
                color: 'blue lighten-2'
            },
            {
                text: 'Plugged-in',
                value: 'PLUG_IN',
                color: 'blue lighten-3'
            },
            {
                text: "En route Port",
                value: "EN_ROUTE_PORT",
                color: "blue darken-1",
            },
            {
                text: "At Port",
                value: "AT_PORT",
                color: "blue darken-2",
            },
            {
                text: "Gate In",
                value: "FULL_DROP_OFF",
                color: "#183A1D",
            },
            {
                text: 'Stacked',
                value: 'STACKED',
                color: 'success'
            },
            {
                text: 'Preadvise Only',
                value: 'preadviseOnly',
                color: 'pink darken-2'
            },
            {
                text: 'Arrange Plugged-In',
                value: 'preadviseOnly',
                color: '#F4CE14'
            },
            {
                text: 'Cancelled',
                value: 'cancelled',
                color: 'red darken-2'
            }
        ],
    }),
    mounted() {
        this.item = this.params.value;
        if (this.params.node && this.params.node.group && this.params.node.key) {
            this.group = this.params.value;
        }
    },
    methods: {
        getStatusColor(item) {
            const status = this.transportStatuses.find(status => status.text == item ? status.color : '');
            return status.color
        }
    }
};
</script>

<style></style>