<template>
  <div style="height: 100%">
    <v-toolbar flat dense>
      <v-spacer></v-spacer>
      <v-chip class="mr-1 searchChipWrap">
        <v-icon small color="grey">search</v-icon>
        <v-text-field
          placeholder="Search"
          class="mb-1 searchInput"
          hide-details
          rounded
          clearable
          dense
          style="margin-top: 2px; width: 300px"
          v-model="search"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-tooltip top v-if="filterActive">
          <template v-slot:activator="{ on }">
            <v-btn rounded right small icon color="redPop" @click="resetFilters" v-on="on">
              <v-icon small>filter_alt_off</v-icon>
            </v-btn>
          </template>
          <span style="font-size: 12px">Clear Filters</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn icon right small @click="getOrders" v-on="on">
              <v-icon small>autorenew</v-icon>
            </v-btn>
          </template>
          <span style="font-size: 12px">Refresh Booking Requests</span>
        </v-tooltip>
      </v-chip>
    </v-toolbar>
    <ag-grid-vue
      groupDisplayType="multipleColumns"
      class="ag-grid-theme-builder"
      :animateRows="true"
      :rowGroupPanelShow="'always'"
      :autoSizeStrategy="{
        type: 'fitGridWidth',
        defaultMinWidth: 150,
        columnLimits: [{ colId: 'movementType', minWidth: 150 }],
      }"
      style="width: 100%; height: 95%"
      :enableRangeSelection="true"
      :allowContextMenuWithControlKey="true"
      :columnDefs="columns"
      :rowData="orders"
      :suppressAggFuncInHeader="true"
      :defaultColDef="defaultColDef"
      :sideBar="sideBar"
      :statusBar="statusBar"
      :getRowId="getRowId"
      :getContextMenuItems="getContextMenuItems"
      @columnMoved="columnsChanged"
      @columnPivotChanged="columnsChanged"
      @newColumnsLoaded="columnsChanged"
      @columnValueChanged="columnsChanged"
      @columnPinned="columnsChanged"
      @displayedColumnsChanged="columnsChanged"
      @columnResized="columnsChanged"
      @columnRowGroupChanged="columnsChanged"
      @columnPivotModeChanged="columnsChanged"
      @grid-ready="onGridReady"
    >
    </ag-grid-vue>

    <v-dialog
      v-model="previewModal"
      width="1500px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <PreviewBookingRequest
        :bookingRequest="bookingRequestItem"
        :containers="containers"
        :containerTypes="containerTypes"
        @close="previewModal = false"
        @refresh="getOrders"
      />
    </v-dialog>
  </div>
</template>
<script>
/* eslint-disable vue/no-unused-components */
import * as moment from "moment";
import { AgGridVue } from "ag-grid-vue";
import BookingState from "./BookingComponents/BookingState.vue";
import BookingType from "./BookingComponents/BookingType.vue";
import dateFormat from "dateformat"
import MovementType from "./BookingComponents/MovementType.vue";
import UserCell from "../GridComponents/User.vue";
import OrganisationName from "@/components/GridComponents/Organisation.vue";
import PortCell from "@/components/GridComponents/Port.vue";
import TableHeader from "@/components/GridComponents/TableHeader.vue";
import PreviewBookingRequest from "../Bookings/Dialogs/PreviewBookingRequest.vue";
import Templates from "../AgGrid/GridSideBar/Templates.vue";

export default {
  components: {
    AgGridVue,
    BookingState,
    BookingType,
    MovementType,
    OrganisationName,
    PortCell,
    agColumnHeader: TableHeader,
    PreviewBookingRequest,
    UserCell,
    Templates,
  },
  data: () => ({
    bookingRequestItem: {},
    columns: [],
    containers: [],
    sideBar: null,
    tableType: "BookingRequests",
    containerTypes: [],
    defaultColDef: {
      sortable: true,
      resizable: true,
      filter: 'agMultiColumnFilter',
      enableRowGroup: true,
      enablePivot: true,
      flex: 1,
      minWidth: 100,
      cellClass: "d-flex align-center",
      cellStyle: { textAlign: "center", fontSize: "11px" },
    },
    filterActive: false,
    filterSetting: {},
    gridApi: null,
    initialState: {},
    loading: false,
    order: {},
    orderModal: false,
    orders: null,
    organisations: {
      total: 0,
      data: null,
    },
    orderQuery: undefined,
    orderStatus: [
      { name: "Planned", icon: "schedule", color: "#F57C00" },
      { name: "Open", icon: "pending", color: "#2196F3" },
      { name: "Complete", icon: "check_circle_outline", color: "#4CAF50" },
      { name: "Cancelled", icon: "cancel", color: "#F44336" },
    ],
    orderTimeout: undefined,
    previewModal: false,
    search: null,
    searchTimeout: undefined,
    statusBar: {
      statusPanels: [
        {
          statusPanel: "agTotalRowCountComponent",
          align: "left",
        },
        {
          statusPanel: "agFilteredRowCountComponent",
        },
        {
          statusPanel: "agSelectedRowCountComponent",
        },
        {
          statusPanel: "agAggregationComponent",
        },
      ],
    },
  }),
  watch: {
    search(val) {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout(async () => {
        this.gridApi.updateGridOptions({ quickFilterText: val });
      }, 400);
    },
  },
  async mounted() {
    console.log('BookingRequests mounted', new Date().getTime())
    this.containerTypes = await this.$API.listContainerTypes();
    this.sideBar = {
      toolPanels: [
        {
          id: "templates",
          labelDefault: "Templates",
          labelKey: "templates",
          iconKey: 'colorPicker',
          toolPanel: "Templates",
          minWidth: 300,
          maxWidth: 500,
          width: 300,
          toolPanelParams: {
            tableType: this.tableType,
          },
        },
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          minWidth: 300,
          maxWidth: 500,
          width: 300,
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
          minWidth: 300,
          maxWidth: 500,
          width: 300,
        },
      ],
      defaultToolPanel: "ActionPanel",
    };
    this.columns = [
      //   {
      //     headerName: "Action",
      //     field: "actions",
      //   },
      {
        headerName: "Customer",
        field: "customerName",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.customer
            ? {
                organisation: p.data.customer,
                clientStatus: p.data.clientStatus,
              }
            : null;
        },
      },
      {
        headerName: "Order No",
        field: "orderNo",
      },
      {
        headerName: "Week",
        field: "sailingWeek",
      },
      {
        headerName: "Order Status",
        field: "state",
        cellRenderer: "BookingState",
        cellStyle: { textAlign: "center", justifyContent: "center" },
      },
      {
        headerName: "Shipper Ref. No",
        field: "shipperReferenceNo",
      },
      {
        headerName: "Movement Type",
        field: "movementType",
        cellRenderer: "MovementType",
        cellStyle: { textAlign: "center", justifyContent: "center" },
      },
      {
        headerName: "Type",
        field: "type",
        cellRenderer: "BookingType",
        cellStyle: { textAlign: "center", justifyContent: "center" },
      },

      {
        headerName: "User",
        field: "userFullName",
        cellRenderer: "UserCell",
        valueGetter: (p) => { return p.data && p.data.orderUser ? p.data.orderUser.firstname + " " + p.data.orderUser.surname : null},
        cellRendererParams: (p) => {
          return p.data && p.data.orderUser ? {
              name: p.data.orderUser.firstname,
              surname: p.data.orderUser.surname,
              avatar: p.data.orderUser.avatar,
            } : null;
        },
      },

      {
        headerName: "Vessel",
        field: "vesselVoyage",
      },
      {
        headerName: "Regime Code",
        field: "regimeCode",
      },

      {
        headerName: "Shipper",
        field: "shipperName",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.shipper
            ? {
                organisation: p.data.shipper,
              }
            : null;
        },
      },
      {
        headerName: "On Behalf Shipper",
        field: "onBehalfShipperName",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.onBehalfShipper
            ? {
                organisation: p.data.onBehalfShipper,
              }
            : null;
        },
      },
      {
        headerName: "Consignee",
        field: "consigneeName",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.consignee
            ? {
                organisation: p.data.consignee,
              }
            : null;
        },
      },
      {
        headerName: "Carrier",
        field: "shippingLineName",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.shippingLine
            ? {
                organisation: p.data.shippingLine,
              }
            : null;
        },
      },

      {
        headerName: "POL",
        field: "portOfLoadValue",
        cellRenderer: "PortCell",
        cellRendererParams: (p) => {
          return {
            locode: p.data ? p.data.portOfLoadValue : null,
            name: p.data ? p.data.portOfLoadCity : null,
          };
        },
      },
      {
        headerName: "ETD",
        field: "etd",
        valueFormatter: (params) => {
          if (params.value) {
            let date = new Date(params.value);
            if (date.getHours() === 0 && date.getMinutes() === 0) {
              return this.formatDate(params.value);
            } else {
              return this.formatDateTime(params.value);
            }
          } else {
            return "";
          }
        },
      },
      {
        headerName: "POD",
        field: "portOfDischargeValue",
        cellRenderer: "PortCell",
        cellRendererParams: (p) => {
          return {
            locode: p.data ? p.data.portOfDischargeValue : null,
            name: p.data ? p.data.portOfDischargeCity : null,
          };
        },
      },
      {
        headerName: "Dest.",
        field: "finalDestinationValue",
        cellRenderer: "PortCell",
        cellRendererParams: (p) => {
          return {
            locode: p.data ? p.data.finalDestinationValue : null,
            name: p.data ? p.data.finalDestinationCity : null,
          };
        },
      },
      {
        headerName: "ETA",
        field: "eta",
        valueFormatter: (params) => {
          if (params.value) {
            let date = new Date(params.value);
            if (date.getHours() === 0 && date.getMinutes() === 0) {
              return this.formatDate(params.value);
            } else {
              return this.formatDateTime(params.value);
            }
          } else {
            return "";
          }
        },
      },

      {
        headerName: "Consignee Ref. No",
        field: "consigneeReferenceNo",
      },
    ];
  },
  async created() {
    
  },
  methods: {
    columnsChanged() {
      if(this.gridApi) {
          let columnState = this.gridApi.getColumnState()
          localStorage.setItem(`columnState-${this.tableType}-${this.$store.state.user.id}-${this.$store.state.currentOrg.id}`, JSON.stringify(columnState))
          localStorage.setItem(`pivotMode-${this.tableType}-${this.$store.state.user.id}-${this.$store.state.currentOrg.id}`, JSON.stringify({pivotMode: this.gridApi.isPivotMode()}))
      }
    },
    filterWatch() {
      console.log('BookingRequests filterListener', new Date().getTime())
      let filter = this.gridApi.getFilterModel();
      console.log(filter)
      this.filterActive = Object.keys(filter).length > 0;
      this.filterSetting = filter;
      localStorage.setItem(`filterModel-${this.tableType}-${this.$store.state.user.id}-${this.$store.state.currentOrg.id}`, JSON.stringify(this.gridApi.getFilterModel()))
    },
    formatDate(date) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "dd-mm-yyyy");
      }
      return result;
    },
    formatDateTime(date) {
      let result = null;
      if (date) {
        result = dateFormat(date, "yyyy-mm-dd HH:MM");
      }
      return result;
    },
    getContextMenuItems(params) {
      let result = [];
      if (!params.node.group) {
        result = [
          {
            name: "View Request",
            icon: '<span class="material-icons" unselectable="on" role="presentation" style="font-size: 16px; color: grey; margin-bottom: 0px; padding-bottom: 0px">launch</span>',
            action: () => {
              if (params.node && params.node.data && params.node.data.id) {
                this.previewRequest(params.node.data);
              } else {
                this.$message.error(
                  "Please select a single row to view the file"
                );
              }
            },
          },
          "separator",
          {
            name: "Add to Filter",
            icon: '<span class="ag-icon ag-icon-filter" unselectable="on" role="presentation"></span>',
            action: () => {
              if (params.node && params.node.data) {
                let multiFilterInstance = this.gridApi.getFilterInstance(params.column.colId);
                let childFilterInstance = multiFilterInstance.getChildFilterInstance(1);
                if (childFilterInstance && childFilterInstance.filterNameKey == 'setFilter') {
                  let filterModel = childFilterInstance.getModel();
                  let values = [];
                  if (filterModel && filterModel.values) {
                      values = filterModel.values;
                      values.push(params.node.data[params.column.colId]);
                  } else {
                      values = [params.node.data[params.column.colId]];
                  }
                  childFilterInstance.setModel({ filterType: 'set', values: values });
                  this.gridApi.onFilterChanged();
                } else {
                  console.error("Child filter instance not found for column:", params.column.colId);
                }
              } else {
                console.error("Filter instance not found for column:", params.column.colId);
              }
            },
          },
          "separator",
          "autoSizeAll",
          "expandAll",
          "contractAll",
          "resetColumns",
          "copy",
          "copyWithHeaders",
          "export",
          "chartRange",
        ];
      } else {
        result = [
          "autoSizeAll",
          "expandAll",
          "contractAll",
          "resetColumns",
          "copy",
          "copyWithHeaders",
          "export",
          "chartRange",
        ];
      }
      return result;
    },
    getRowId(params) {
      return params.data.id;
    },
    onGridReady(params) {
      console.log('BookingRequests onGridReady', new Date().getTime())
      this.gridApi = params.api;
      this.gridApi.addEventListener("filterChanged", this.filterWatch);
      this.getOrders();
    },
    postProcessPopup(params) {
      if (params.type !== "columnMenu") {
        return;
      }
      const ePopup = params.ePopup;
      ePopup.style.top = "65px";
      ePopup.style.left =
        params.column.actualWidth / 2 + params.mouseEvent.left + "px";
    },
    async resetFilters() {
      this.gridApi.setFilterModel({});
    },
    async getOrders() {
      if (this.orderTimeout) {
        clearTimeout(this.orderTimeout);
      }
      if (this.loading && this.orderQuery) {
        this.orderQuery.abort();
      }
      this.loading = true;
      let params = {};
      params.recordType = "ORDER";
      this.orderTimeout = setTimeout(async () => {
        this.orderQuery = new AbortController();
        const signal = this.orderQuery.signal;
        let result = await this.$API.getBookingRequests({
          params: params,
          signal,
        });
        this.orders = result.data
        this.loading = false;
      }, 500);
    },
    async previewRequest(item) {
      this.bookingRequestItem = await this.$API.getOrderDetail(
        item.systemReference
      );
      this.containers = await this.$API.getOrderContainersBasic(item.id);
      this.previewModal = true;
    },
  },
};
</script>

<style scoped>
.ag-grid-theme-builder {
    --ag-icon-image-color-picker: url("../../../public/icons/view_carousel.svg"); 
    --ag-icon-font-display-color-picker: none;
    --ag-icon-font-color-color-picker: transparent;
}

.ag-grid-theme-builder ::v-deep .ag-icon.ag-icon-color-picker {
    scale: 1.2;
}
</style>