<template>
  <div style="width: 100%">
    <v-list dense style="width: 100%; min-width: 300px">
      <v-subheader style="font-size: 14px">
        <v-icon class="mr-2" color="primary" small>insights</v-icon>
        Actions</v-subheader>
      <v-divider></v-divider>
      <v-list-item @click="exportCSV" style="height: 30px">
        <v-list-item-action class="mx-0 px-0">
          <v-icon small color="grey">file_download</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title> Export data to CSV </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="exportExcel" style="height: 30px">
        <v-list-item-action class="mx-0 px-0">
          <v-icon small color="grey">file_download</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title> Export data to Excel </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="resetTable" style="height: 30px">
        <v-list-item-action class="mx-0 px-0">
          <v-icon small color="grey">autorenew</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title> Reset Table </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-subheader style="font-size: 14px">
        Reports
      </v-subheader>
      <v-list-item @click="clientReportModal = true" style="height: 30px">
        <v-list-item-action class="mx-0 px-0">
          <v-icon small color="grey">autorenew</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title> Client Report </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="pluggedInReport = true" style="height: 30px">
        <v-list-item-action class="mx-0 px-0">
          <v-icon small color="grey">autorenew</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title> Plugged In Report </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-subheader style="font-size: 14px">
        Data
      </v-subheader>
      <!-- <v-list-item @click="resetTable" style="height: 30px">
        <v-list-item-action class="mx-0 px-0">
          <v-icon small color="grey">autorenew</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title> Email CTO</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->
      <!-- <v-list-item @click="resetTable" style="height: 30px">
        <v-list-item-action class="mx-0 px-0">
          <v-icon small color="grey">autorenew</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title> Upload Data </v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->
      <!-- <v-list-item @click="resetTable" style="height: 30px">
        <v-list-item-action class="mx-0 px-0">
          <v-icon small color="grey">autorenew</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Vessel Data</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->
      <v-divider></v-divider>
      <v-list-iem>

      </v-list-iem>
    </v-list>

    <v-dialog v-model="q67CommentModal" width="500px">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Q67</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="(q67CommentModal = false), (q67Comment = null)"><v-icon>close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row justify="center">
            <v-col cols="12" sm="6">
              <v-text-field outlined dense label="PPECB N Code" v-model="q67Request.ppecbNcode"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select outlined dense label="Charge Party" v-model="q67Request.ppecbInv" :items="[
                'shipper',
                'forwarder',
                'onBehalfShipper',
                'onBehalfForwarder',
              ]" item-text="name" item-value="name"></v-select>
            </v-col>
          </v-row>
          <v-textarea v-model="q67Comment" outlined placeholder="Addtional Comments (optional)"></v-textarea>
          <v-row justify="center">
            <v-btn @click="(q67Modal = true), (q67CommentModal = false)">Next</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="q67Modal" width="750px">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Bulk Create Q67 </v-toolbar-title>
          <v-spacer></v-spacer>

          <v-btn text @click="
            (q67Modal = false),
            (finishedProcessingQ67 = false),
            (finishedProcessingMergedQ67 = false),
            (bulkQ67 = null)
            ">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6">
              <v-btn v-if="!finishedProcessingMergedQ67" color="teal" small rounded @click="startProcessingMergedQ67()"
                :loading="processingMergedQ67">Generate Merged Q67</v-btn>
              <v-row v-else-if="finishedProcessingMergedQ67 && bulkQ67">
                <v-col cols="12" class="mb-0">
                  <v-btn small rounded @click="downloadDocument(bulkQ67.q67)" :loading="processingMergedQ67">Download
                    Merged PDF</v-btn>
                </v-col>
                <v-col cols="12" class="mt-0">
                  <v-btn small rounded @click="downloadDocument(bulkQ67.q67Excel)"
                    :loading="processingMergedQ67">Download Merged Excel</v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6">
              <v-btn v-if="!finishedProcessingQ67" color="blue" small rounded @click="startProcessingQ67()"
                :loading="processingQ67">Generate Individually</v-btn>
              <v-btn v-else-if="finishedProcessingQ67" :loading="downloadingBulkQ67" @click="bulkDownloadQ67()" small
                class="mx-1" color="blue darken-2">
                <v-icon small left>download</v-icon> Download All
              </v-btn>
            </v-col>
          </v-row>
          <v-list style="max-height: 50vh; overflow-y: auto" :key="q67UpdateKey" dense>
            <v-list-item v-for="row in selectedRows" :key="row.id">
              <v-list-item-content>
                <v-list-item-title>
                  {{ row.orderNo }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="item.q67">
                  Version: {{ row.q67.version }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-else-if="row.errorMessage">
                  Error: {{ row.errorMessage }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-row justify="center">
                  <v-tooltip top v-if="row.q67">
                    <template v-slot:activator="{ on }">
                      <v-btn icon small @click="viewDocument(row.q67)" v-on="on">
                        <v-icon color="blue" small>launch</v-icon>
                      </v-btn>
                    </template>
                    <span style="font-size: 12px">View Document</span>
                  </v-tooltip>
                  <v-tooltip top v-if="row.q67">
                    <template v-slot:activator="{ on }">
                      <v-btn icon small @click="downloadDocument(row.q67)" v-on="on">
                        <v-icon small>download</v-icon>
                      </v-btn>
                    </template>
                    <span style="font-size: 12px">Download Q67</span>
                  </v-tooltip>
                  <v-tooltip top v-if="row.q67Excel">
                    <template v-slot:activator="{ on }">
                      <v-btn icon small @click="downloadDocument(row.q67Excel)" v-on="on" color="green">
                        <v-icon small>browser_updated</v-icon>
                      </v-btn>
                    </template>
                    <span style="font-size: 12px">Download Excel Q67</span>
                  </v-tooltip>
                  <v-icon v-if="row.errorMessage" color="red">error</v-icon>
                </v-row>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="previewModal" width="1200px" :fullscreen="fullscreen || $vuetify.breakpoint.mobile">
      <v-card v-if="document && document.name">
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            {{ document.name }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="downloadDocument(document)" icon><v-icon>download</v-icon></v-btn>
          <v-btn @click="fullscreen = !fullscreen" icon><v-icon v-if="!fullscreen">fullscreen</v-icon><v-icon
              v-else>fullscreen_exit</v-icon></v-btn>
          <v-btn icon text @click="(previewModal = false), (document = {})">X</v-btn>
        </v-toolbar>
        <v-card-text v-if="document" :style="{
          'max-height': fullscreen ? '90vh' : '75vh',
          'overflow-y': 'auto',
        }">
          <v-row>
            <v-col cols="12">
              <div v-if="document.type && document.type.includes('image/')">
                <v-img :src="document.url" contain style="max-height: 70vh">
                </v-img>
              </div>
              <div v-else-if="document.type == 'application/pdf'">
                <v-row justify="center">
                  <v-col cols="12" sm="9" class="text-center" v-if="document.url">
                    <div ref="pdfBox">
                      <VuePdfEmbed :width="fullscreen ? 1200 : 900" :source="document.url" />
                    </div>
                  </v-col>
                </v-row>
              </div>
              <v-container v-else fill-height>
                <v-row justify="center" height="100%">
                  <div class="text-center">
                    <span>This file is in a format that cannot be previewed.</span>
                    <br /><br />
                    <v-btn color="primary" style="text-transform: none" @click="downloadDocument(document)">
                      <v-icon class="mr-1">download</v-icon> Download</v-btn>
                  </div>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="pluggedInReport" width="500px" persistent>
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Plugged In Report </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="getPluggedInReport" color="secondary" :loading="downloadReport"
            ><v-icon
              small>download</v-icon></v-btn>
          <v-btn text @click="pluggedInReport = false" color="primary">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-card outlined>
            <v-card-title>Date Picker</v-card-title>
            <v-card-text>
              <v-radio-group v-model="dateSelection" row class="ml-15">
                <v-flex xs4>
                  <v-radio label="Default" value="default"></v-radio>
                </v-flex>
                <v-flex xs6>
                  <v-radio label="Manual selection" value="manual"></v-radio>
                </v-flex>
              </v-radio-group>

              <v-date-picker v-model="pluggedInDates" range color="primary" v-if="dateSelection === 'manual'"
                class="ml-15">
              </v-date-picker>
            </v-card-text>
          </v-card>
          <v-autocomplete v-model="selectedPluggInDepot" :items="pluggedInDepots" :item-text="item => item.text"
            item-value="poiId" label="Search for a Plug In Depot" return-object :disabled="!pluggedInDepots">
          </v-autocomplete>
          <v-autocomplete v-model="searchCustomer" :items="customers" :loading="loadingCustomers"
            :item-text="item => item.alias ? item.alias : item.name" item-value="id" label="Search for a Shipper"
            return-object :disabled="!customers">
          </v-autocomplete>
        </v-card-text>
      </v-card>
    </v-dialog>



    <v-dialog v-model="clientReportModal" width="500px">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Client Report </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="getShipperReport" color="secondary" :loading="downloadReport"
            :disabled="!selectedDay && !searchCustomer && !movementType && !selectedPort"><v-icon
              small>download</v-icon></v-btn>
          <v-btn text @click="clientReportModal = false" color="primary">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-layout justify-center>
            <v-radio-group v-model="selectedDay" row>
              <v-flex xs4>
                <v-radio label="Yesterday" value="Yesterday"></v-radio>
              </v-flex>
              <v-flex xs4>
                <v-radio label="Today" value="Today"></v-radio>
              </v-flex>
              <v-flex xs4>
                <v-radio label="Tomorrow" value="Tomorrow"></v-radio>
              </v-flex>
            </v-radio-group>
          </v-layout>
          <v-layout justify-center>
            <v-radio-group v-model="selectedPort" row>
              <v-flex xs3>
                <v-radio label="CPT" value="cpt"></v-radio>
              </v-flex>
              <v-flex xs3>
                <v-radio label="DBN" value="dbn"></v-radio>
              </v-flex>
              <v-flex xs3>
                <v-radio label="PLZ" value="plz"></v-radio>
              </v-flex>
              <v-flex xs3>
                <v-radio label="NAM" value="name"></v-radio>
              </v-flex>
            </v-radio-group>
          </v-layout>
          <v-layout justify-center>
            <v-radio-group v-model="movementType" row>
              <v-flex xs6>
                <v-radio label="Export" value="EXPORT"></v-radio>
              </v-flex>
              <v-flex xs6>
                <v-radio label="Import" value="IMPORT"></v-radio>
              </v-flex>
            </v-radio-group>
          </v-layout>

          <!-- <v-layout justify-center>
            <v-radio-group v-model="fileType" row>
              <v-flex xs6>
                <v-radio label="Excel" value="excel"></v-radio>
              </v-flex>
              <v-flex xs6>
                <v-radio label="PDF" value="pdf"></v-radio>
              </v-flex>
            </v-radio-group>
          </v-layout> -->

          <v-layout justify-center>
            <v-switch v-model="longDistance" label="Long Distance Loads"></v-switch>
          </v-layout>

          <v-autocomplete v-model="searchCustomer" :items="customers" :loading="loadingCustomers"
            :item-text="item => item.alias ? item.alias : item.name" item-value="id" label="Search for a Shipper"
            return-object :disabled="!customers">
            <!-- <template v-slot:item="{ item }">
            <v-list-item>
              <v-list-item-avatar>
                <v-badge>
                  <template v-slot:badge>
                    <v-img :src="item.logo"></v-img>
                  </template>
                </v-badge>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title >{{ item.alias ? item.alias : item.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template> -->
          </v-autocomplete>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { PDFDocument } from "pdf-lib";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";

export default {
  components: {
    VuePdfEmbed,
  },
  data: () => ({
    allTeams: [],
    myTeams: [],
    bulkQ67: null,
    document: null,
    downloadingBulkQ67: false,
    pluggedInReport: false,
    fullscreen: false,
    dateSelection: 'default',
    item: null,
    pluggedInDates: [],
    finishedProcessingQ67: false,
    finishedProcessingMergedQ67: false,
    loadingTeams: false,
    previewModal: false,
    processingQ67: false,
    processingMergedQ67: false,
    q67Request: {
      ppecbNcode: "",
      ppecbInv: "",
    },
    q67CommentModal: false,
    q67Comment: "",
    q67Modal: false,
    q67UpdateKey: 100,
    selectedRows: [],
    selectedTeams: [],
    showCancelled: true,
    clientReportModal: false,
  }),
  mounted() {
    this.item = this.params;
    if (this.params.api) {
      this.params.api.addEventListener(
        "selectionChanged",
        this.rowSelectionChanged
      );
    }
    this.getTeams();
  },
  watch: {
  },
  methods: {
    async bulkDownloadQ67() {
      this.downloadingBulkQ67 = true;
      const combinedPdf = await PDFDocument.create();
      for (let x = 0; x < this.selectedRows.length; x++) {
        const pdfBytes = await fetch(this.selectedRows[x].q67.url).then((res) =>
          res.arrayBuffer()
        );
        const pdfDoc = await PDFDocument.load(pdfBytes);

        const pages = Array.from(
          { length: pdfDoc.getPageCount() },
          (_, i) => i
        );
        const copiedPages = await combinedPdf.copyPages(pdfDoc, pages);

        for (const page of copiedPages) {
          combinedPdf.addPage(page);
        }
      }

      const combinedPdfBytes = await combinedPdf.save();

      // Trigger download for the combined PDF
      const blob = new Blob([combinedPdfBytes], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "combined_q67.pdf";
      link.click();
      this.downloadingBulkQ67 = false;
    },
    async getShipperReport() {
      this.downloadReport = true;
      let response = await this.$API.getShipperLoads({
        shipperId: this.searchCustomer.id,
        startDate: this.startDate,
        endDate: this.endDate,
        longDistance: this.longDistance,
        movementType: this.movementType,
        selectedPort: this.selectedPort,
      });
      if (response && response.dataUri) {
        const link = document.createElement('a');
        link.href = response.dataUri;
        link.setAttribute('download', `${this.searchCustomer.name} Report ${response.date}.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error('No data received from server');
      }
      this.resetForm();
    },

    displayCancelledBookings() {
      let filterModel = this.params.api.getFilterModel();
      console.log("Active Filter", filterModel);
      if (this.showCancelled) {
        if (filterModel && filterModel.state) {
          filterModel.state.values.push('Cancelled')
        } else {
          filterModel.state = {
            type: 'set',
            values: ['Cancelled']
          }
        }
      } else {
        delete filterModel.state
        if (filterModel && filterModel.state) {

          // filterModel.state.values = filterModel.state.values.filter(x => x != 'Cancelled')
        }
      }
      this.params.api.setFilterModel(filterModel);
    },
    async downloadDocument(doc) {
      var hiddenElement = document.createElement("a");
      hiddenElement.href = doc.url;
      hiddenElement.target = "_blank";
      hiddenElement.download = doc.name;
      hiddenElement.click();
    },
    exportCSV() {
      this.params.exportCSV();
    },
    exportExcel() {
      this.params.exportExcel();
    },
    async getTeams() {
      this.loadingTeams = true;
      if (localStorage["teams_" + this.$store.state.currentOrg.id]) {
        this.allTeams = JSON.parse(
          localStorage["teams_" + this.$store.state.currentOrg.id]
        );
      }
      if (localStorage["myTeams_" + this.$store.state.currentOrg.id]) {
        this.myTeams = JSON.parse(
          localStorage["myTeams_" + this.$store.state.currentOrg.id]
        );
      }
      this.allTeams = await this.$API.getTeams();
      localStorage.setItem(
        "teams_" + this.$store.state.currentOrg.id,
        JSON.stringify(this.allTeams)
      );
      this.myTeams = await this.$API.getUserTeams();
      localStorage.setItem(
        "myTeams_" + this.$store.state.currentOrg.id,
        JSON.stringify(this.myTeams)
      );
      if (localStorage["bookingTeams_" + this.$store.state.currentOrg.id]) {
        this.selectedTeams = JSON.parse(
          localStorage["bookingTeams_" + this.$store.state.currentOrg.id]
        );
      }
      this.loadingTeams = false;
    },
    resetTable() {
      this.params.resetTable();
    },
    rowSelectionChanged(event) {
      let rows = this.params.api.getSelectedRows();
      this.selectedRows = rows;
    },
    selectAllTeams() {
      this.selectedTeams = this.allTeams.map((x) => x.id);
      localStorage.setItem(
        "bookingTeams_" + this.$store.state.currentOrg.id,
        JSON.stringify(this.selectedTeams)
      );
      this.params.setTeamFilter(this.selectedTeams, true);
    },
    selectTeam(id) {
      let findIndex = this.selectedTeams.indexOf(id);
      if (findIndex > -1) {
        this.selectedTeams.splice(findIndex, 1);
      } else {
        this.selectedTeams.push(id);
      }
      localStorage.setItem(
        "bookingTeams_" + this.$store.state.currentOrg.id,
        JSON.stringify(this.selectedTeams)
      );
      this.params.setTeamFilter(this.selectedTeams, this.selectedTeams.length == this.allTeams.length);
    },
    async startProcessingMergedQ67() {
      this.processingMergedQ67 = true;
      let result = await this.$API.generateMergedQ67({
        bookings: this.selectedRows.map((x) => x.id),
        comment: this.q67Comment,
        ...this.q67Request,
      });
      this.bulkQ67 = result;
      this.downloadDocument(this.bulkQ67.q67);
      this.q67UpdateKey++;
      this.finishedProcessingMergedQ67 = true;
      this.processingMergedQ67 = false;
    },
    async startProcessingQ67() {
      this.processingQ67 = true;
      for (let i = 0; i < this.selectedRows.length; i++) {
        let result = await this.$API.generateQ67({
          bookingId: this.selectedRows[i].id,
          comment: this.q67Comment,
          ...this.q67Request,
        });
        if (result && result.q67) {
          this.selectedRows[i].q67 = result.q67;
          this.selectedRows[i].q67Id = result.q67Id;
          this.selectedRows[i].q67Excel = result.q67Excel;
          this.selectedRows[i].q67ExcelId = result.q67ExcelId;
        } else if (result && result.message) {
          this.selectedRows[i].errorMessage = result.message;
        }
        let findRow = this.params.api.getRowNode(this.selectedRows[i].id);
        if (findRow) {
          findRow.setData(this.selectedRows[i]);
        }

        this.q67UpdateKey++;
      }
      this.finishedProcessingQ67 = true;
      this.processingQ67 = false;
    },
    viewDocument(item) {
      this.document = item;
      this.previewModal = true;
    },
  },
};
</script>