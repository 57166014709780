<template>
  <v-card
    v-if="container && booking"
    style="
      contain: content;
      border-radius: 0px;
      width: 100%;
      background: var(--v-greyRaised-base) !important;
    "
  >
    <v-toolbar
      flat
      dense
      style="background-color: var(--v-toolbar-lighten1) !important"
    >
      <v-toolbar-title style="font-size: 18px">
        <v-chip style="font-size: 24px" small class="mx-2" :color="containerStatusColor(container)">
          <div>{{ container.status ? container.status : "No Status" }}</div>
        </v-chip>
        <b style="font-size: 18px">{{ title }}</b></v-toolbar-title
      >
      <v-chip class="ml-2" small v-if="container.booking.user"
        >Created By: <v-icon class="ml-1" left small>person</v-icon>
        {{ container.booking.user.firstname }}
        {{ container.booking.user.surname }}</v-chip
      >
      <v-spacer></v-spacer>

      <v-btn
        class="ml-2"
        icon
        color="primary"
        :loading="savingChange"
        @click="updateContainer()"
        ><v-icon small>save</v-icon></v-btn
      >
      <v-btn
        icon
        @click="$emit('close')"
        color="redPop"
        ><v-icon small>close</v-icon></v-btn
      >
    </v-toolbar>
    <v-card-text class="pt-3">
          <v-row>
            <v-col cols="12" sm="6" md="3">
              <v-card class="infoCards">
                <v-card-text class="my-0 py-0">
                  <v-list dense subheader class="ma-0 pa-0">
                    <v-subheader style="font-size: 14px">
                      <v-icon class="mr-2" small> tag </v-icon>
                      Reference Numbers
                    </v-subheader>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title v-if="container.shipmentFile">
                          {{ container.shipmentFile.fileNumber }}
                        </v-list-item-title>
                        <v-list-item-title v-else> - </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          File Number
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-text-field
                      outlined
                      dense
                      label="Customer Ref."
                      hide-details
                      class="my-2"
                      v-model="container.customerContainerRef"
                    >
                      <template v-slot:label>
                        <span style="font-size: 13px"> Customer Ref. </span>
                      </template></v-text-field
                    >

                    <v-text-field
                      outlined
                      dense
                      label="Deck No"
                      hide-details
                      class="my-2"
                      v-if="booking.serviceType == 'BREAKBULK'"
                      v-model="container.containerNo"
                      @input="setChange"
                    >
                      <template v-slot:label>
                        <span style="font-size: 13px"> Deck No </span>
                      </template></v-text-field
                    >
                    <v-text-field
                      outlined
                      dense
                      label="Container No"
                      hide-details
                      class="my-2"
                      v-else
                      v-model="container.containerNo"
                      @input="setChange"
                    >
                      <template v-slot:label>
                        <span style="font-size: 13px"> Container No </span>
                      </template></v-text-field
                    >
                    <v-text-field
                      outlined
                      dense
                      label="Seal No"
                      hide-details
                      class="my-2"
                      v-model="container.sealNo"
                      @input="setChange"
                    >
                      <template v-slot:label>
                        <span style="font-size: 13px"> Seal No </span>
                      </template></v-text-field
                    >
                    <v-list-item style="height: 30px" class="mx-0 px-0" v-if="booking.serviceType != 'BREAKBULK'">
                      <v-list-item-action class="mx-0">
                        <v-icon small>widgets</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title style="font-size: 13px"
                          >Container Type</v-list-item-title
                        >
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-menu offset-y rounded>
                          <template v-slot:activator="{ on }">
                            <v-chip v-on="on" small outlined label>
                              <span style="text-transform: none">
                                {{ container.containerTypeCode }}
                              </span>
                              <v-icon small right>expand_more</v-icon>
                            </v-chip>
                          </template>
                          <v-list
                            dense
                            subheader
                            style="max-height: 500px; overflow-y: auto"
                          >
                            <v-list-item
                              v-for="(containerType, index) in filterContainerTypes"
                              :key="index"
                              @click="
                                (container.containerTypeCode =
                                  containerType.typeCode),
                                  updateContainerDescription()
                              "
                            >
                              <v-list-item-content>
                                <v-list-item-title style="font-size: 12px">{{
                                  containerType.shortDescription
                                }}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item style="height: 30px" class="mx-0 px-0" v-else>
                      <v-list-item-action class="mx-0">
                        <v-icon small>widgets</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title style="font-size: 13px"
                          >Breakbulk</v-list-item-title
                        >
                      </v-list-item-content>
                      </v-list-item>

                    <v-list-item
                      style="height: 30px"
                      class="mx-0 px-0"
                      v-if="booking.movementType == 'EXPORT'"
                    >
                      <v-list-item-action class="mx-0">
                        <v-icon small>scale</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title style="font-size: 13px"
                          >VGM Method</v-list-item-title
                        >
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-menu offset-y rounded>
                          <template v-slot:activator="{ on }">
                            <v-chip v-on="on" small outlined label>
                              <v-icon small left>verified</v-icon>
                              <span style="text-transform: none">
                                {{ container.verificationMethod }}
                              </span>
                              <v-icon small right>expand_more</v-icon>
                            </v-chip>
                          </template>
                          <v-list dense subheader>
                            <v-list-item
                              @click="container.verificationMethod = 'Method 1'"
                            >
                              <v-list-item-content>
                                <v-list-item-title>Method 1</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item
                              @click="container.verificationMethod = 'Method 2'"
                            >
                              <v-list-item-content>
                                <v-list-item-title>Method 2</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-card-text>

                <!-- <v-row>
                
                  <v-col cols="12">
                    <v-row class="mt-2 px-2">
                      <v-col cols="12" sm="4" class="my-0 py-0">
                        <v-select
                          label="Vents"
                          outlined
                          dense
                          hide-details
                          class="pb-1"
                          v-model="container.vents"
                          :items="ventSettings"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="4" class="my-0 py-0">
                        <v-text-field
                          prepend-inner-icon="thermostat"
                          label="Set Temp"
                          hide-details
                          v-model="container.setPointTemp"
                          outlined
                          dense
                          class="pb-1"
                          suffix="°C"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" class="my-0 py-0">
                        <v-text-field
                          label="Humidity"
                          v-model="container.humidity"
                          outlined
                          hide-details
                          dense
                          type="number"
                          suffix="%"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row> -->
              </v-card>
              <v-card class="infoCards mt-2">
                <v-card-text class="my-0 py-0">
                  <v-list dense subheader class="ma-0 pa-0">

                    <v-subheader style="font-size: 14px">
                      <v-icon class="mr-2" small> scale </v-icon>
                      Weights
                      <v-spacer></v-spacer>
                      <v-tooltip
                        top
                        v-if="
                          container.verificationMethod === 'Method 1' &&
                          container.vgmWeight &&
                          container.vgmWeight > 0
                        "
                      >
                        <template v-slot:activator="{ on }">
                          <v-btn
                            :loading="updatingPallets"
                            text
                            icon
                            @click="updatePalletWeights"
                            v-on="on"
                          >
                            <v-icon color="primary">auto_mode</v-icon>
                          </v-btn>
                        </template>
                        <span style="font-size: 12px"
                          >Override Pallet Gross Weights</span
                        >
                      </v-tooltip>
                    </v-subheader>
                    <v-divider></v-divider>
                    <v-text-field
                      hide-details
                      class="my-2"
                      type="number"
                      step="0.001"
                      suffix="KG"
                      v-model="container.nettWeight"
                      outlined
                      dense
                    >
                      <template v-slot:label>
                        <span style="font-size: 13px"> Nett Weight </span>
                      </template>
                    </v-text-field>
                    <v-text-field
                      hide-details
                      class="my-2"
                      type="number"
                      step="0.001"
                      suffix="KG"
                      v-model="container.grossWeight"
                      outlined
                      dense
                    >
                      <template v-slot:label>
                        <span style="font-size: 13px"> Gross Weight </span>
                      </template></v-text-field
                    >
                    <v-text-field
                            prefix="VGM"
                            class="my-1"
                            hide-details
                            suffix="KG"
                            type="number"
                            :step="0.001"
                            v-model="container.vgmWeight"
                            @input="setChange"
                            outlined
                            disabled
                            dense
                          > <template v-slot:label>
                        <span style="font-size: 13px"> VGM Weight </span>
                      </template></v-text-field>
                          <v-text-field
                            prefix="Tare"
                            hide-details
                            class="my-1"
                            type="number"
                            :step="0.001"
                            suffix="KG"
                            v-model="container.tareWeight"
                            @input="setChange"
                            outlined
                            disabled
                            dense
                          > <template v-slot:label>
                        <span style="font-size: 13px"> Tare Weight </span>
                      </template></v-text-field>
                    <v-switch
                            label="Load Out Weight" dense
                            v-model="container.loadOutReceived"
                          ></v-switch>
                     
                    </v-list>
                    </v-card-text>
                    </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="3" class="ml-0 pl-0">
              <v-card class="infoCards mx-0 px-0" flat>
                <v-card-text class="my-0 py-0">
                  <v-list dense subheader class="ma-0 pa-0">
                    <v-subheader style="font-size: 14px">
                      <v-icon class="mr-2" small> settings </v-icon>
                      Cargo Settings
                    </v-subheader>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-action class="mr-1">
                        <v-icon small>code</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          style="font-size: 13px"
                          v-if="booking.regime"
                        >
                          {{ booking.regime.code }}
                        </v-list-item-title>
                        <v-list-item-title v-else> - </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 12px">
                          Regime Code
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-content>
                      <v-list-item-subtitle
                        style="font-size: 11px"
                        v-if="booking.regime && booking.regime.setPointTemp"
                      >
                        <v-icon color="#007d5c" x-small class="mr-1"
                          >thermostat</v-icon
                        >
                        Set Temp:
                        {{ `${booking.regime.setPointTemp}°C` }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                        style="font-size: 11px"
                        v-if="booking.regime && booking.regime.protocol"
                      >
                        <v-icon color="#9d904a" x-small class="mr-1"
                          >thermostat</v-icon
                        >
                        Protocol:
                        {{ `${booking.regime.protocol}°C` }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="booking.serviceType!='BREAKBULK' && booking.regime">
                      <v-list-item-action class="mr-1">
                        <v-icon small>air</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title style="font-size: 13px">
                          Vent Settings
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          Inherited from Booking
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-chip small outlined label v-if="
                            !booking.caProduct ||
                            (booking.caProduct &&
                              booking.caProduct == 'MAXTEND')
                          ">
                              <span style="text-transform: none">
                                {{ booking.vents }}
                              </span>
                              <span v-if="!isNaN(booking.vents)" class="ml-1"
                                >CBM</span
                              >
                              <!-- <v-icon small right>expand_more</v-icon> -->
                            </v-chip>
                       
                        <v-chip v-else small outlined label> Closed </v-chip>
                      </v-list-item-action>
                    </v-list-item>

                    <v-divider></v-divider>
                    <v-subheader style="font-size: 14px">
                      <v-icon class="mr-2" small>ac_unit</v-icon> Reefer
                      Settings</v-subheader
                    >
                    <v-divider></v-divider>
                    <v-list-item style="height: 30px" v-if="booking.serviceType!='BREAKBULK'">
                      <v-list-item-action class="mx-0">
                        <v-icon small>air</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title style="font-size: 13px"
                          >Vent Settings</v-list-item-title
                        >
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-menu offset-y rounded>
                          <template v-slot:activator="{ on }">
                            <v-chip v-on="on" small outlined label>
                              <span style="text-transform: none">
                                {{ container.vents }}
                              </span>
                              <v-icon small right>expand_more</v-icon>
                            </v-chip>
                          </template>
                          <v-list
                            dense
                            subheader
                            style="max-height: 500px; overflow-y: auto"
                          >
                            <v-list-item
                              v-for="(vent, index) in ventSettings"
                              :key="index"
                              @click="container.vents = vent"
                            >
                              <v-list-item-content>
                                <v-list-item-title style="font-size: 12px">{{
                                  vent
                                }}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-list-item-action>
                    </v-list-item>
                    <v-text-field
                      dense
                      outlined
                      v-model="container.setPointTemp"
                      hide-details
                      class="my-2"
                      clearable
                    >
                      <template v-slot:label>
                        <span style="font-size: 13px">Set Temp (°C) </span>
                      </template>
                    </v-text-field>
                    <v-text-field
                      dense
                      outlined
                      v-model="container.humidity"
                      hide-details
                      class="my-2"
                      clearable
                    >
                      <template v-slot:label>
                        <span style="font-size: 13px">Humidity (%) </span>
                      </template>
                    </v-text-field>
                    </v-list>
                    </v-card-text>
                    </v-card> 
                    <v-card class="infoCards mx-0 px-0 mt-2" flat>
                <v-card-text class="my-0 py-0">
                  <v-list dense subheader class="ma-0 pa-0">
                    <v-subheader style="font-size: 14px">
                        <v-icon class="mr-2" small>
                          widgets
                        </v-icon>
                        Cargo/Products <v-spacer></v-spacer>
                        <v-btn
                          icon
                          @click="addContainerProduct(container)"
                          color="primary"
                          ><v-icon>add_circle_outline</v-icon></v-btn
                        >
                      </v-subheader>
                  <v-divider></v-divider>
                      <v-list-item
                        v-for="cargo in container.containerProducts"
                        :key="cargo.id"
                        @click="editContainerProduct(container, cargo)"
                      >
                        <v-list-item-content>
                          <v-list-item-title style="font-size: 14px">
                            <b v-if="cargo.product">{{ cargo.product.name }}</b>
                            <span style="font-size: 12px" v-if="cargo.hsCode">
                              - {{ cargo.hsCode }}</span
                            >
                            <span style="font-size: 12px" v-if="cargo.quantity">
                              - {{ cargo.quantity }}
                              {{ cargo.quantityType }}</span
                            >
                          </v-list-item-title>
                          <v-list-item-subtitle
                            v-if="cargo.description"
                            style="font-size: 12px"
                          >
                            {{ cargo.description }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle
                            style="font-size: 11px"
                            v-if="cargo.nettWeight"
                          >
                            NETT {{ cargo.nettWeight }} KG
                          </v-list-item-subtitle>
                          <v-list-item-subtitle
                            style="font-size: 11px"
                            v-if="cargo.grossWeight"
                          > GROSS
                            {{ cargo.grossWeight }} KG
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-content>
                          <v-container>
                            <v-row justify="center" align="center">
                              <v-tooltip top v-if="cargo.organic">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    color="green"
                                    class="mx-1"
                                  >
                                    spa
                                  </v-icon>
                                </template>
                                <span style="font-size: 12px">Organic</span>
                              </v-tooltip>

                              <v-tooltip top v-if="cargo.industrial">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    color="blue-grey"
                                    class="mx-1"
                                  >
                                    factory
                                  </v-icon>
                                </template>
                                <span style="font-size: 12px"
                                  >Industrial Use</span
                                >
                              </v-tooltip>

                              <v-tooltip top v-if="cargo.hazardous">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    color="orange"
                                    class="mx-1"
                                  >
                                    warning
                                  </v-icon>
                                </template>
                                <span style="font-size: 12px">Hazardous</span>
                              </v-tooltip>
                            </v-row>
                          </v-container>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list dense subheader style="height: 150px">
                    <v-divider></v-divider>
                    <v-subheader style="font-size: 14px">
                      <v-icon small class="mr-2">settings_suggest</v-icon>
                      Features
                    </v-subheader>
                    <v-divider></v-divider>
                    <v-list-item v-for="(feature, index) in containerFeatures" :key="index" style="height: 30px">
                      <v-list-item-action>
                        <v-btn icon
      outlined
      small :color="feature.color">
                        <v-icon small :color="feature.color">{{feature.icon}}</v-icon>
                        </v-btn>
                        </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title style="font-size: 12px">{{feature.name}}
                          </v-list-item-title>
                        </v-list-item-content
                        >
                        <v-list-item-action>
                          <v-switch dense hide-details v-model="selectedFeatures[feature.value]" @change="updateContainerFeature(feature)"></v-switch>
                        </v-list-item-action>
                      </v-list-item>
</v-list>
                      <v-divider></v-divider>
                  <v-subheader style="font-size: 14px" :key="dataSourceKey">
                    <v-icon small class="mr-2">bar_chart</v-icon>
                    Data Source(s)
                    <v-spacer></v-spacer>
                    <!-- <v-btn :disabled="availableSources.length === 0" :loading="savingDataSource" @click="updateDataSource()" color="primary" icon>
                      <v-icon>save</v-icon>
                    </v-btn> -->
                  </v-subheader>
                  <v-divider></v-divider>
                  <v-progress-linear
                    v-if="loadingDataSource"
                    color="primary"
                    indeterminate
                  ></v-progress-linear>
                  <v-list-item
                    v-for="source in availableSources"
                    :key="source.id"
                  >
                    <v-list-item-content
                      @click="viewSource(source)"
                      style="cursor: pointer"
                    >
                      <v-list-item-title>
                        {{ source.type }}
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="source.locationCode">
                        <v-icon class="mr-2" color="grey" small
                          >location_on</v-icon
                        >
                        {{ source.locationCode }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-if="source.transactionTime">
                        <v-icon class="mr-2" color="grey" small
                          >schedule</v-icon
                        >
                        {{ source.transactionTime }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle class="text-wrap">
                        <v-icon class="mr-2" color="grey" small
                          >description</v-icon
                        >{{ source.name }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-switch
                        color="success"
                        v-model="source.active"
                        @change="checkDataSourceChanges()"
                      ></v-switch>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item
                    v-if="!loadingDataSource && availableSources.length == 0"
                  >
                    <v-list-item-content class="text-center">
                      <span style="color: grey; font-size: 12px"
                        >No data sources found</span
                      >
                    </v-list-item-content>
                  </v-list-item>

                    </v-list>
                    </v-card-text>
                    </v-card>
            </v-col>
            
            <v-col cols="12" sm="6" md="3" class="ml-0 pl-0">
              <v-card class="infoCards mx-0 px-0" flat>
                <v-card-text class="ma-0 pa-0">
                  <!-- DATA SOURCES -->
               
                  
            <v-list  dense subheader>
              <v-subheader style="font-size: 14px">
                <v-icon class="mr-2" small>mode_of_travel</v-icon>
                Transport Type</v-subheader>
              <v-divider></v-divider>
              <v-list-item
                v-for="haulier in hauliers"
                :key="haulier.index"
                @click="setHaulier(haulier)" style="height: 30px"
              >
                <v-list-item-action>
                  <v-avatar color="primary" size="30" v-if="container[haulier.flag]">
                    <v-img
                      v-if="haulier.logo"
                      :src="haulier.logo"
                      contain
                    ></v-img>
                    <v-icon v-else small>{{ haulier.icon }}</v-icon>
                  </v-avatar>
                  <v-avatar size="30" v-else>
                    <v-icon small color="grey">{{ haulier.icon }}</v-icon>
                  </v-avatar>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ haulier.name }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon color="success" v-if="container[haulier.flag]"
                    >check_circle_outline</v-icon
                  >
                </v-list-item-action>
              </v-list-item>
            
            <v-subheader style="font-size: 14px">
              <v-icon class="mr-2" small> local_shipping </v-icon>
              Transport Coordinator
            </v-subheader>
            <v-select
              :items="selectableParties"
              :disabled="!container.isPrivateHaulage"
              :placeholder="
                container.isPrivateHaulage
                  ? 'Select Coordinator'
                  : 'Not Applicable'
              "
              item-text="name"
              item-value="id"
              rounded
              style="background-color: #2f2f2f; margin: 1vh 1vw 0.5vh 1vw"
              hide-details
              outlined
              return-object
              v-model="container.transportCoordinator"
              @change="setTransporter"
              clearable
              dense
            >
              <template v-slot:selection="data">
                <v-row align="center" v-if="container.isPrivateHaulage">
                  <v-avatar size="32" class="mr-2" :color="data.item.logo?'white':'secondary'" v-if="data.item">
                    <v-img
                      v-if="data.item.logo"
                      contain
                      :src="data.item.logo"
                    ></v-img>
                    <h3 v-else>
                      {{ data.item.name.charAt(0) }}
                    </h3>
                  </v-avatar>
                  <span v-if="data.item">
                    {{ data.item.name }}
                  </span>
                </v-row>
              </template>
              <template v-slot:item="data">
                <v-list-item-avatar>
                  <v-img
                    contain
                    v-if="data.item.logo"
                    :src="data.item.logo"
                  ></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                </v-list-item-content>
              </template>
            </v-select>

            <v-list-item style="height: 40px"
              dense
              v-if="
                container.isPrivateHaulage && booking.movementType == 'EXPORT'
              "
              class="mt-2"
            >
              <v-list-item-action>
                <v-icon small>article</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> Pre-Advise </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action :key="preadviseKey">
                <v-switch
                  color="success"
                  v-model="container.preAdviseRequired"
                ></v-switch>
              </v-list-item-action>
            </v-list-item>

            <v-list-item style="height: 40px"
              dense
              v-if="
                (container.isPrivateHaulage || container.isCarrierHaulage) &&
                booking.movementType == 'EXPORT'
              "
              class="mt-2"
            >
              <v-list-item-action>
                <v-icon small>electrical_services</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> Plugged-In </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action :key="pluggedInKey">
                <v-switch
                  color="success"
                  v-model="container.pluggedInRequired"
                  :disabled="
                    container.transportCoordinatorId ===
                    $store.state.currentOrg.id
                  "
                ></v-switch>
              </v-list-item-action>
            </v-list-item>

            <v-divider></v-divider>

            <v-subheader
              style="font-size: 14px"
              v-if="booking.movementType == 'EXPORT'"
            >
              <v-icon class="mr-2" small>location_on</v-icon>
              Loading Points
            </v-subheader>
            <v-subheader style="font-size: 14px" v-else>
              <v-icon class="mr-2" small>location_on</v-icon>
              Delivery Points
            </v-subheader>
            <v-divider></v-divider>
              <v-list dense class="pa-0" :key="milestoneKey">
                <!-- <v-divider></v-divider> -->
                <v-list-item
                  v-for="(milestone, index) in availableMilestones.filter(
                    (x) =>
                      x.type == booking.movementType)"
                  :key="index"
                  @click="addMilestone(container, milestone.value)"
                  style="height: 40px"
                >
                  <v-list-item-action class="mr-0 pr-0">
                    <v-avatar
                      size="10"
                      v-if="container[milestone.value]"
                      :color="
                        container[milestone.value].isActual
                          ? 'success'
                          : 'primary'
                      "
                    ></v-avatar>
                    <v-icon v-else color="grey" small>warning</v-icon>
                  </v-list-item-action>
                  <v-list-item-content v-if="container[milestone.value]">
                    <v-list-item-title style="font-size: 12px">{{
                      container[milestone.value].description
                    }}</v-list-item-title>
                    <v-list-item-subtitle style="font-size: 11px">{{
                      formatDateTime(
                        container[milestone.value].date,
                        container[milestone.value].time
                      )
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content v-else>
                    <v-list-item-subtitle style="font-size: 12px">{{
                      milestone.name
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                
               </v-list>
            </v-list>
          </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="3" class="ml-0 pl-0">
              <v-card class="infoCards mx-0 px-0" flat>
                <v-card-text class="ma-0 pa-0">
                  <v-subheader style="font-size: 14px"
                    ><v-icon small class="mr-2">sell</v-icon>Product Sales
                    <v-btn
                      icon
                      color="primary"
                      @click="addContainerProductService"
                    >
                      <v-icon>add_circle_outline</v-icon>
                    </v-btn>
                  </v-subheader>
                  <v-divider></v-divider>
                  <v-list-item
                    v-if="
                      !container.productServiceSales ||
                      container.productServiceSales.length == 0
                    "
                  >
                    <v-list-item-content class="text-center">
                      <span style="color: grey; font-size: 12px"
                        >No products listed</span
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-for="product in container.productServiceSales"
                    :key="product.id"
                  >
                    <v-list-item-action class="mr-1">
                      <v-avatar
                        class="mr-2"
                        size="30"
                        :color="!product.productService.icon ? 'secondary' : ''"
                      >
                        <v-img
                          v-if="product.productService.icon"
                          :src="product.productService.icon"
                          contain
                        ></v-img>
                        <h3 v-else>{{ product.productService.name.charAt(0) }}</h3>
                      </v-avatar>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title
                        style="font-size: 12px"
                        class="text-wrap"
                        >{{ product.productService.name }}</v-list-item-title
                      >
                      <v-list-item-subtitle
                        style="font-size: 11px"
                        v-if="product.productService.type == 'PRODUCT'"
                      >
                        <v-icon color="grey" class="mr-1 mb-1" small
                          >category</v-icon
                        >
                        Product</v-list-item-subtitle
                      >
                      <v-list-item-subtitle style="font-size: 11px" v-else>
                        <v-icon color="grey" class="mr-1 mb-1" small
                          >engineering</v-icon
                        >
                        Service</v-list-item-subtitle
                      >
                      <v-list-item-subtitle class="text-wrap" style="font-size: 11px" v-if="product.productService.productServiceCategory && product.productService.productServiceSubcategory">
                    {{ product.productService.productServiceCategory.name }} <v-icon x-small>chevron_right</v-icon> {{ product.productService.productServiceSubcategory.name }}
                  </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                    <v-chip small>{{ product.quantity }}
                      </v-chip>
                    </v-list-item-action>
                  </v-list-item>
                <v-divider></v-divider>
                <v-subheader style="font-size: 14px">
                  <v-icon class="mr-2" small>article</v-icon>
                  Documents
                </v-subheader>
                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      <span v-if="!container.ctoDocument">-</span>
                      <span v-else
                        >Version: {{ container.ctoDocument.version }}</span
                      >
                    </v-list-item-title>
                    <v-list-item-subtitle style="font-size: 11px"> Container Terminal Order </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action :key="ctoKey">
                    <v-row justify="center">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            icon
                            @click="generateCTO(container.id, container)"
                            :loading="loadingCto"
                            v-on="on"
                          >
                            <v-icon color="teal" small>post_add</v-icon>
                          </v-btn>
                        </template>
                        <span style="font-size: 12px">Generate new CTO</span>
                      </v-tooltip>
                      <v-tooltip top v-if="container.ctoDocument">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            icon
                            @click="viewCTO(container.ctoDocument)"
                            v-on="on"
                          >
                            <v-icon color="blue" small>launch</v-icon>
                          </v-btn>
                        </template>
                        <span style="font-size: 12px">View Document</span>
                      </v-tooltip>
                      <v-tooltip top v-if="container.ctoDocument">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            icon
                            @click="downloadDocument(container.ctoDocument)"
                            v-on="on"
                          >
                            <v-icon small>download</v-icon>
                          </v-btn>
                        </template>
                        <span style="font-size: 12px">Download Container Terminal Order</span>
                      </v-tooltip>
                    </v-row>
                  </v-list-item-action>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      <span v-if="!container.earlyArrivalDocument">-</span>
                      <span v-else
                        >Version:
                        {{ container.earlyArrivalDocument.version }}</span
                      >
                    </v-list-item-title>
                    <v-list-item-subtitle style="font-size: 11px"> Early Arrival Document (Transnet)</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action :key="larKey">
                    <v-row justify="center">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            icon
                            @click="earlyArrivalForm(container)"
                            :loading="loadingLAR"
                            v-on="on"
                          >
                            <v-icon color="teal" small>post_add</v-icon>
                          </v-btn>
                        </template>
                        <span style="font-size: 12px"
                          >Generate new Early Arrival</span
                        >
                      </v-tooltip>
                      <v-tooltip top v-if="container.earlyArrivalDocument">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            icon
                            @click="viewCTO(container.earlyArrivalDocument)"
                            v-on="on"
                          >
                            <v-icon color="blue" small>launch</v-icon>
                          </v-btn>
                        </template>
                        <span style="font-size: 12px">View Document</span>
                      </v-tooltip>
                      <v-tooltip top v-if="container.earlyArrivalDocument">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            icon
                            @click="
                              downloadDocument(container.earlyArrivalDocument)
                            "
                            v-on="on"
                          >
                            <v-icon small>download</v-icon>
                          </v-btn>
                        </template>
                        <span style="font-size: 12px"
                          >Download</span
                        >
                      </v-tooltip>
                    </v-row>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      <span v-if="!container.larDocument">-</span>
                      <span v-else
                        >Version: {{ container.larDocument.version }}</span
                      >
                    </v-list-item-title>
                    <v-list-item-subtitle style="font-size: 11px"> Late Arrival Document (Transnet) </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action :key="larKey">
                    <v-row justify="center">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            icon
                            @click="larForm(container)"
                            :loading="loadingLAR"
                            v-on="on"
                          >
                            <v-icon color="teal" small>post_add</v-icon>
                          </v-btn>
                        </template>
                        <span style="font-size: 12px">Generate new Late Arrival Document</span>
                      </v-tooltip>
                      <v-tooltip top v-if="container.larDocument">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            icon
                            @click="viewCTO(container.larDocument)"
                            v-on="on"
                          >
                            <v-icon color="blue" small>launch</v-icon>
                          </v-btn>
                        </template>
                        <span style="font-size: 12px">View Document</span>
                      </v-tooltip>
                      <v-tooltip top v-if="container.larDocument">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            icon
                            @click="downloadDocument(container.larDocument)"
                            v-on="on"
                          >
                            <v-icon small>download</v-icon>
                          </v-btn>
                        </template>
                        <span style="font-size: 12px">Download</span>
                      </v-tooltip>
                    </v-row>
                  </v-list-item-action>
                </v-list-item>

                <v-list-item v-if="booking.customerId === 3892">
                  <v-list-item-content>
                    <v-list-item-title>
                      <span v-if="!container.packingListDocument">-</span>
                      <span v-else
                        >Version:
                        {{ container.packingListDocument.version }}</span
                      >
                    </v-list-item-title>
                    <v-list-item-subtitle> Packing List </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action :key="packingListKey">
                    <v-row justify="center">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            icon
                            @click="specialInstructionsModal = true"
                            v-on="on"
                            :loading="loadingPackingList"
                          >
                            <v-icon color="teal">post_add</v-icon>
                          </v-btn>
                        </template>
                        <span style="font-size: 12px"
                          >Generate new Packing List</span
                        >
                      </v-tooltip>
                      <v-tooltip top v-if="container.packingListDocument">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            icon
                            @click="
                              viewPackingList(container.packingListDocument)
                            "
                            v-on="on"
                          >
                            <v-icon color="blue">launch</v-icon>
                          </v-btn>
                        </template>
                        <span style="font-size: 12px">View Document</span>
                      </v-tooltip>
                      <v-tooltip top v-if="container.packingListDocument">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            icon
                            @click="
                              downloadDocument(container.packingListDocument)
                            "
                            v-on="on"
                          >
                            <v-icon>download</v-icon>
                          </v-btn>
                        </template>
                        <span style="font-size: 12px"
                          >Download Packing List</span
                        >
                      </v-tooltip>
                    </v-row>
                  </v-list-item-action>
                </v-list-item>

               
                  <v-divider></v-divider>
                  <v-subheader style="font-size: 14px">
                    <v-icon class="mr-2" small> chat </v-icon>
                    Comments
                  </v-subheader>
                  <v-divider></v-divider>
                  <v-card
                    height="30vh"
                    width="inherit"
                    style="
                      overflow-y: auto;
                      background-color: transparent !important;
                    "
                    flat
                    :loading="loadingComments"
                    id="messagesContainer"
                  >
                    <v-list dense>
                      <v-list-item
                        v-if="
                          containerComments && containerComments.length == 0
                        "
                      >
                        <v-list-item-content class="text-center">
                          <span style="font-size: 12px; color: grey">
                            No comments.
                          </span>
                        </v-list-item-content>
                      </v-list-item>
                      <div v-if="containerComments">
                        <v-list-item
                          v-for="(comment, index) in containerComments"
                          :key="comment.id"
                          :id="'comment_' + index"
                          class="py-0 my-1"
                        >
                        <v-list-item-action class="mr-1">
                          <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-avatar
                                  v-bind="attrs"
                                  v-on="on"
                                  style="cursor: pointer"
                                  color="secondary"
                                  size="36"
                                  class="mt-0 pt-0"
                                >
                                  <h3
                                    v-if="!comment.user.avatar"
                                    >{{
                                      $Format.avatarAbbreviation(
                                        comment.user.firstname
                                      )
                                    }}</h3
                                  >
                                  <img
                                    v-else
                                    :src="comment.user.avatar"
                                    referrerpolicy="no-referrer"
                                  />
                                </v-avatar>
                              </template>
                              <span style="font-size: 12px"
                                >{{ comment.user.firstname }}
                                {{ comment.user.surname }}</span
                              >
                            </v-tooltip>
                        </v-list-item-action>
                        <v-list-item-content
                          >
                            <v-list-item-title
                              class="text-wrap"
                              v-if="comment.isDeleted"
                              style="text-decoration: line-through; color: gray"
                            >
                              {{ comment.comment }}
                            </v-list-item-title>
                            <v-list-item-title class="text-wrap" v-else>
                              {{ comment.comment }}
                            </v-list-item-title>

                            <v-list-item-subtitle>
                              <i style="font-size: 12px; color: grey">{{
                                $Format.formatDate(comment.createdAt).dateTimeDefault
                              }}</i>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action
                            v-if="
                              comment.userId == $store.state.user.id &&
                              comment.isDeleted == false
                            "
                          >
                            <div
                              style="
                                display: flex;
                                flex-direction: row;
                                justify-content: start;
                              "
                            >
                              <v-tooltip top>
                                <template v-slot:activator="{ on: tooltip }">
                                  <v-btn
                                    v-on="{ ...tooltip }"
                                    icon small
                                    @click="openDialog(comment)"
                                    ><v-icon small>edit</v-icon></v-btn
                                  >
                                </template>
                                <span>Edit Comment</span>
                              </v-tooltip>

                              <v-tooltip top>
                                <template v-slot:activator="{ on: tooltip }">
                                  <v-btn
                                    :loading="loadingDeleteComment"
                                    v-on="{ ...tooltip }"
                                    icon small
                                    @click="deleteContainerComment(comment.id)"
                                  >
                                    <v-icon small color="red">delete</v-icon>
                                  </v-btn>
                                </template>
                                <span>Remove Comment</span>
                              </v-tooltip>
                            </div>
                          </v-list-item-action>
                        
                        </v-list-item>
                      </div>
                    </v-list>
                  </v-card>
                  <v-card
                    flat
                    height="7vh"
                    width="100%"
                    class="mx-0 px-0"
                    style="
                      background-color: var(--v-toolbar-lighten1) !important;
                      display: flex;
                      align-items: center;
                      overflow-y: hidden;
                    "
                  >
                    <v-textarea
                      :key="chatContainerUpdateKey"
                      persistent-placeholder
                      placeholder="Type a Message"
                      @keydown.enter="commentContainer()"
                      class="ml-2"
                      no-resize
                      hide-details
                      outlined
                      height="6vh"
                      style="width: 80%; contain: content; border-radius: 20px"
                      v-model="containerChatArea"
                      :value="containerChatArea"
                    ></v-textarea>
                    <v-btn
                      :loading="sendingMessage"
                      icon
                      class="ml-3 mr-2"
                      @click="commentContainer()"
                      ><v-icon>send</v-icon></v-btn
                    >
                  </v-card>
                </v-card-text>
              </v-card>
            </v-col>
      </v-row>
    </v-card-text>
    <v-dialog
      v-model="specialInstructionsModal"
      width="550px"
      :fullscreen="fullscreen || $vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title style="font-size: 16px"> Packing List </v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <v-btn @click="generatePackingList(container.id), (specialInstructionsModal = false)" icon ><v-icon
              color="teal">download</v-icon></v-btn> -->

          <v-btn icon text @click="specialInstructionsModal = false" color="red"
            >X</v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-textarea
            v-model="specialInstructions"
            label="Special Instrcutions"
            outlined
            rows="4"
          ></v-textarea>
          <v-row justify="center">
            <v-btn
              @click="
                generatePackingList(container.id),
                  (specialInstructionsModal = false)
              "
              small
              rounded
              color="blue"
              class="mx-1"
            >
              Generate
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="larFormModal"
      width="550px"
      :fullscreen="fullscreen || $vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title style="font-size: 16px"> LAR Arrival Form </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="generateLAR(container.id)" icon :loading="loadingLAR"
            ><v-icon small>download</v-icon></v-btn
          >

          <v-btn
            icon
            @click="(larFormModal = false), (item = {})"
            color="redPop"
            >
            <v-icon small>close</v-icon>
            </v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-text-field
            v-model="reasonForLAR"
            label="Reason for LAR"
          ></v-text-field>
          <v-row>
            <v-col cols="7">
              <v-date-picker
                v-model="arrivalDate"
                label="Arrival Date" color="primary"
                :style="{ marginBottom: '20px' }"
              ></v-date-picker>
              <v-autocomplete
                hide-details
                dense
                :items="timeOptions"
                v-model="arrivalTime"
                outlined
                rounded
                label="Time"
                prepend-inner-icon="schedule"
              ></v-autocomplete>
            </v-col>
            <v-col cols="5">
              <v-radio-group v-model="containerSize">
                <v-subheader>Container Size</v-subheader>
                <v-radio label="6 METRE" value="6 METRE"></v-radio>
                <v-radio label="12 METRE" value="12 METRE"></v-radio>
                <v-radio label="13.7 METRE" value="13.7 METRE"></v-radio>
              </v-radio-group>
              <v-radio-group v-model="containerType">
                <v-subheader>Container Type</v-subheader>
                <v-radio label="GP" value="GP"></v-radio>
                <v-radio label="REEFER" value="REEFER"></v-radio>
                <v-radio label="HAZARDOUS" value="HAZARDOUS"></v-radio>
                <v-radio
                  label="O.O.G CONTAINER"
                  value="O.O.G CONTAINER"
                ></v-radio>
                <v-radio label="HIGH CUBE" value="HIGH CUBE"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="earlyArrivalModal"
      width="550px"
      :fullscreen="fullscreen || $vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title style="font-size: 16px"> Early Arrival Form </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            @click="generateEarlyArrival(container.id)"
            icon
            :loading="loadingEAR" color="primary"
            ><v-icon small>download</v-icon></v-btn
          >

          <v-btn
            icon
            @click="(earlyArrivalModal = false), (item = {})"
            color="redPop"
            >
            <v-icon small>close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-text-field
            v-model="reasonForEarlyArrival"
            label="Reason for Early Arrival"
          ></v-text-field>
          <v-row>
            <v-col cols="7">
              <v-date-picker
                v-model="arrivalDate"
                label="Arrival Date" color="primary"
                :style="{ marginBottom: '20px' }"
              ></v-date-picker>
              <v-autocomplete
                hide-details
                dense
                :items="timeOptions"
                v-model="arrivalTime"
                outlined
                rounded
                label="Time"
                prepend-inner-icon="schedule"
              ></v-autocomplete>
            </v-col>
            <v-col cols="5">
              <v-radio-group v-model="containerSize">
                <v-subheader>Container Size</v-subheader>
                <v-radio label="6 METRE" value="6 METRE"></v-radio>
                <v-radio label="12 METRE" value="12 METRE"></v-radio>
                <v-radio label="13.7 METRE" value="13.7 METRE"></v-radio>
              </v-radio-group>
              <v-radio-group v-model="containerType">
                <v-subheader>Container Type</v-subheader>
                <v-radio label="GP" value="GP"></v-radio>
                <v-radio label="REEFER" value="REEFER"></v-radio>
                <v-radio label="HAZARDOUS" value="HAZARDOUS"></v-radio>
                <v-radio
                  label="O.O.G CONTAINER"
                  value="O.O.G CONTAINER"
                ></v-radio>
                <v-radio label="HIGH CUBE" value="HIGH CUBE"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="previewModal"
      width="1200px"
      :fullscreen="fullscreen || $vuetify.breakpoint.mobile"
    >
      <v-card v-if="item && item.name">
        <v-toolbar flat color="transparent">
          <v-toolbar-title style="font-size: 16px">
            {{ item.name }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="downloadDocument(item)" icon
            ><v-icon small>download</v-icon></v-btn
          >
          <v-btn @click="fullscreen = !fullscreen" icon
            ><v-icon v-if="!fullscreen" small>fullscreen</v-icon
            ><v-icon v-else small>fullscreen_exit</v-icon></v-btn
          >
          <v-btn icon color="redPop" @click="(previewModal = false), (item = {})"
            >
            <v-icon small>close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" :loading="imageLoading">
              <div v-if="item.type && item.type.includes('image/')">
                <v-img
                  :src="item.url"
                  contain
                  style="max-height: 70vh"
                  @load="imageLoading = false"
                >
                </v-img>
              </div>
              <div v-else-if="item.type == 'application/pdf'">
                <v-row justify="center">
                  <v-col cols="12" sm="9" class="text-center" v-if="item.url">
                    <div ref="pdfBox">
                      <VuePdfEmbed
                        :width="fullscreen ? 1200 : 900"
                        :source="item.url"
                        @loaded="imageLoading = false"
                      />
                    </div>
                  </v-col>
                </v-row>
              </div>
              <v-container v-else fill-height>
                <v-row justify="center" height="100%">
                  <div class="text-center">
                    <span
                      >This file is in a format that cannot be previewed.</span
                    >
                    <br /><br />
                    <v-btn
                      color="primary"
                      style="text-transform: none"
                      @click="downloadDocument(item)"
                    >
                      <v-icon class="mr-1">download</v-icon> Download</v-btn
                    >
                  </div>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="addMilestoneModal"
      width="500px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <ManageContainerMilestone
        :item="milestoneItem"
        :bookingType="booking.movementType"
        :key="milestoneKey"
        @close="addMilestoneModal = false"
        @save="milestoneUpdate"
      />
    </v-dialog>

    <v-dialog
      v-model="containerProductModal"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card v-if="item && item.selectedProduct">
        <Products
          @close="(containerProductModal = false), (item = null)"
          :item="item.selectedProduct"
          @deleted="deleteSelectedContainerProduct"
          @chosenProduct="updateSelectedContainerProduct"
        />
      </v-card>
    </v-dialog>

    <v-dialog v-model="editButton" max-width="500px">
      <v-card>
        <v-toolbar flat dense>
          <v-toolbar-title style="font-size: 16px">Edit Comment</v-toolbar-title>
          <v-spacer>
            </v-spacer>
            <v-btn
              :loading="loadingEditComment"
              color="primary" icon
              @click="updateContainerComment(newEditedComment.id)"
              ><v-icon  small>save</v-icon></v-btn
            >
            <v-btn color="redPop" icon @click="closeEditDialog()">
              <v-icon small>close</v-icon>
            </v-btn>
        </v-toolbar>
       
        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          "
        >
          <v-textarea
            v-model="newEditedComment.comment"
            style="width: 80%; contain: content; border-radius: 20px"
            outlined
            rows="4"
          >
          </v-textarea>
        </div>
      </v-card>
    </v-dialog>
    <!-- Data Source Modal -->
    <v-dialog v-model="sourceModal" fullscreen persistent>
      <v-card v-if="sourceModal">
        <v-toolbar dense flat>
          <v-toolbar-title style="font-size: 16px">
            Manage Data Source
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon color="redPop" @click="(sourceModal = false), (selectedContainer = null)"
            >
            <v-icon small>close</v-icon>
            </v-btn
          >
        </v-toolbar>
        <v-card-subtitle>
          {{ sourceItem.name }}
          <br />
          <v-icon class="mr-2" small color="grey">label</v-icon>
          {{ sourceItem.type }}
        </v-card-subtitle>
        <v-card-text>
          <v-row>
            <!-- <v-col cols="12">
            </v-col> -->
            <v-col cols="12" v-if="sourceItem.sourceType == 'stockFile'">
              <v-data-table
                :items="sourceItem.contents"
                :headers="importHeaders"
                height="82vh"
                fixed-header
                dense
                disable-pagination
                hide-default-footer
              ></v-data-table>
            </v-col>
            <v-col cols="12" v-else>
              <v-row>
                <v-col cols="12" sm="2">
                  <v-list dense>
                    <v-subheader style="font-size: 16px"
                      >Containers</v-subheader
                    >
                    <v-divider></v-divider>
                    <v-list-item
                      v-for="container in sourceItem.stockFileContainers"
                      :key="container.id"
                      @click="selectedContainer = container"
                      :style="{
                        'border-left':
                          selectedContainer &&
                          selectedContainer.id == container.id
                            ? '3px solid var(--v-primary-base)'
                            : '',
                      }"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ container.containerNo }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="12" sm="10">
                  <v-data-table
                    v-if="selectedContainer"
                    :items="selectedContainer.contents"
                    :headers="importHeaders"
                    height="82vh"
                    fixed-header
                    dense
                    disable-pagination
                    hide-default-footer
                  ></v-data-table>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="productServiceModal" width="1500px">
      <!-- <ProductServiceModal @close="productServiceModal=false" @selected="setProductSelection" :level="productServiceItem.level"/> -->
      <ProductServiceModal @close="productServiceModal=false" :openKey="productKey" :enableSave="true" :properties="{bookingId: booking.id, bookingContainerId: container.id}" @selected="setProductSelection" :level="productServiceItem.level" :preselected="container.productServiceSales"/>

      </v-dialog>
  </v-card>
</template>
  
<script>
import axios from "axios";
import dateFormat from "dateformat";
// import GmapCustomMarker from "vue2-gmap-custom-marker";
import ManageContainerMilestone from "./ManageContainerMilestone.vue";
import Products from "./ProductsDialog.vue";
import ProductServiceModal from "../../ProductServices/ProductServiceModal.vue";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
import isContainer from "../../../utils/containerNo";
export default {
  props: ["booking", "container", "containerTypes"],
  components: {
    // GmapCustomMarker,
    ManageContainerMilestone,
    Products,
    ProductServiceModal,
    VuePdfEmbed,
  },
  data: () => ({
    updatingPallets: false,
    loadingLAR: false,
    loadingEAR: false,
    addAdditionalProduct: false,
    addMilestoneModal: false,
    availableMilestones: [
      { value: "EMPTY_PICKUP", type: "EXPORT", name: "Empty Pickup" },
      { value: "LOADING_POINT_1", type: "EXPORT", name: "Loading Point 1" },
      { value: "LOADING_POINT_2", type: "EXPORT", name: "Loading Point 2" },
      { value: "GASSING", type: "EXPORT", name: "Gassing" },
      { value: "WEIGH_BRIDGE", type: "EXPORT", name: "Weigh Bridge" },
      { value: "FULL_DROP_OFF", type: "EXPORT", name: "Port Holding" },

      { value: "FULL_PICKUP", type: "IMPORT", name: "Full Pickup" },
      { value: "DELIVERY_POINT_1", type: "IMPORT", name: "Delivery Point 1" },
      { value: "DELIVERY_POINT_2", type: "IMPORT", name: "Delivery Point 2" },
      { value: "DELIVERY_POINT_3", type: "IMPORT", name: "Delivery Point 3" },
      { value: "EMPTY_DROP_OFF", type: "IMPORT", name: "Empty Drop Off" },

      { value: "FULL_PICKUP", type: "LOCAL", name: "Full Pickup" },
      { value: "DELIVERY_POINT_1", type: "LOCAL", name: "Delivery Point 1" },
      { value: "DELIVERY_POINT_2", type: "LOCAL", name: "Delivery Point 2" },
      { value: "DELIVERY_POINT_3", type: "LOCAL", name: "Delivery Point 3" },
      { value: "EMPTY_DROP_OFF", type: "LOCAL", name: "Empty Drop Off" },
    ],
    center: { lat: 0.0, lng: 0.0 },
    change: false,
    contractors: [],
    containerStatus: [
      { name: "Unallocated", icon: "warning", color: "orange darken-2" },
      { name: "Ghost", icon: "category", color: "blue-grey" },
      { name: "Moved", icon: "exit_to_app", color: "blue" },
      { name: "Allocated", icon: "task_alt", color: "success" },
      { name: "Cancelled", icon: "cancel", color: "red" },
      { name: "Claim", icon: "attach_money", color: "indigo" },
    ],
    containerComments: [],
    containerProductModal: false,
    ctoKey: 550,
    regime: null,
    loadingCto: false,
    editButton: false,
    milestones: [],
    fullscreen: false,
    loadingContractors: false,
    loadingDetails: true,
    loadingComments: true,
    loadingEditComment: false,
    loadingDeleteComment: false,
    sendingMessage: false,
    newEditedComment: "",
    containerChatArea: "",
    chatContainerUpdateKey: 0,
    containerFeatures: [],
    imageLoading: true,
    item: {},
    legs: [],
    loadedDistance: 0,
    haulierKey: 750,
    key: 50,
    milestoneItem: {},
    milestoneKey: 100,
    productKey: 500,
    reasonForLAR: "",
    reasonForEarlyArrival: "",
    arrivalDate: null,
    containerSize: "12 METRE",
    timezone: "",
    containerType: "REEFER",
    mapStyle: {
      scrollwheel: true,
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      styles: [
        { elementType: "geometry", stylers: [{ color: "#283a54" }] },
        { elementType: "labels.text.stroke", stylers: [{ color: "#283a54" }] },
        { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
        {
          featureType: "administrative.locality",
          elementType: "labels.text.fill",
          stylers: [{ color: "#d59563" }],
        },
        {
          featureType: "poi",
          elementType: "labels.text.fill",
          stylers: [{ color: "#d59563" }],
        },
        {
          featureType: "poi.park",
          elementType: "geometry",
          stylers: [{ color: "#263c3f" }],
        },
        {
          featureType: "poi.park",
          elementType: "labels.text.fill",
          stylers: [{ color: "#6b9a76" }],
        },
        {
          featureType: "road",
          elementType: "geometry",
          stylers: [{ color: "#38414e" }],
        },
        {
          featureType: "road",
          elementType: "geometry.stroke",
          stylers: [{ color: "#212a37" }],
        },
        {
          featureType: "road",
          elementType: "labels.text.fill",
          stylers: [{ color: "#9ca5b3" }],
        },
        {
          featureType: "road.highway",
          elementType: "geometry",
          stylers: [{ color: "#746855" }],
        },
        {
          featureType: "road.highway",
          elementType: "geometry.stroke",
          stylers: [{ color: "#1f2835" }],
        },
        {
          featureType: "road.highway",
          elementType: "labels.text.fill",
          stylers: [{ color: "#f3d19c" }],
        },
        {
          featureType: "transit",
          elementType: "geometry",
          stylers: [{ color: "#2f3948" }],
        },
        {
          featureType: "transit.station",
          elementType: "labels.text.fill",
          stylers: [{ color: "#d59563" }],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [{ color: "#212121" }],
        },
        {
          featureType: "water",
          elementType: "labels.text.fill",
          stylers: [{ color: "#515c6d" }],
        },
        {
          featureType: "water",
          elementType: "labels.text.stroke",
          stylers: [{ color: "#17263c" }],
        },
      ],
    },
    packingListKey: 5000,
    specialInstructions: "",
    milestoneTypes: [
      {
        name: "Empty Pickup",
        eventType: "TRANSPORT",
        modeOfTransport: "TRUCK",
        value: "EMPTY_PICKUP",
      },
      {
        name: "Stuff Date",
        eventType: "EQUIPMENT",
        modeOfTransport: "TRUCK",
        value: "STUFF_DATE",
      },
      {
        name: "Loading Point 1",
        eventType: "TRANSPORT",
        modeOfTransport: "TRUCK",
        value: "LOADING_POINT_1",
      },
      {
        name: "Loading Point 2",
        eventType: "TRANSPORT",
        modeOfTransport: "TRUCK",
        value: "LOADING_POINT_2",
      },
      {
        name: "Weigh Bridge",
        eventType: "TRANSPORT",
        modeOfTransport: "TRUCK",
        value: "WEIGH_BRIDGE",
      },
      {
        name: "Plugged In",
        eventType: "TRANSPORT",
        modeOfTransport: "TRUCK",
        value: "PLUG_IN",
      },
      {
        name: "Unplugged",
        eventType: "TRANSPORT",
        modeOfTransport: "TRUCK",
        value: "PLUG_OUT",
      },
      {
        name: "Drop Off Point",
        eventType: "TRANSPORT",
        modeOfTransport: "TRUCK",
        value: "FULL_DROP_OFF",
      },
    ],
    loadingPackingList: false,
    pluggedInKey: 120,
    milestoneContainer: {},
    previewModal: false,
    productView: false,
    statuses: [
      { id: 4, state: "AT", color: "#006bc3", location: null },
      { id: 3, state: "ASSIGNED", color: "#0094db", location: null },
      { id: 8, state: "COMPLETED", color: "#008a61", location: null },
      { id: 6, state: "DONE LOADING", color: "#2cc194", location: null },
      { id: 5, state: "EN ROUTE TO", color: "#ccb50a", location: null },
      { id: 7, state: "STOPPED", color: "#e55000", location: null },
      { id: 9, state: "CANCELLED", color: "#ff6666", location: null },
      { id: 10, state: "ISSUE", color: "#7f0000", location: null },
      { id: 2, state: "PENDING", color: "#657583", location: null },
      { id: 1, state: "TO BE ALLOCATED", color: "#b4bbc1", location: null },
    ],
    statusLocations: [
      { name: "LOADING POINT 1", value: "LP1" },
      { name: "LOADING POINT 2", value: "LP2" },
      { name: "EMPTY DEPOT", value: "EMPTY" },
      { name: "PORT OF LOADING", value: "POL" },
      { name: "DROP OFF", value: "DROPOFF" },
      { name: "YARD", value: "YARD" },
      { name: "FUEL STATION", value: "FUEL STATION" },
    ],
    arrivalTime: null,
    preadviseKey: 850,
    savingChange: false,
    statusFilters: {
      at: false,
      assigned: false,
      completed: false,
      "done Loading": false,
      "en Route To": false,
      stopped: false,
      cancelled: false,
      issue: false,
      pending: false,
      "to Be Allocated": false,
    },
    temporaryProductsView: [
      { icon: "bolt", color: "#D6D46D", name: "Genset" },
      { icon: "watch_later", color: "#BB2525", name: "Late Arrival" },
      { icon: "watch_later", color: "orange", name: "Early Arrival" },
      { icon: "router", color: "#18e00d", name: "TelemPlus" },
      { icon: "shopping_bag", color: "#F1DBBF", name: "Dunnage Bags" },
      { icon: "air", color: "#6A9C89", name: "Otflow" },
      { icon: "sanitizer", color: "#5b93c7", name: "Zoono" },
      { icon: "cleaning_services", color: "#BB2525", name: "Fumigation" },
      { icon: "power", color: "#D6D46D", name: "Void Plugs" },
      { icon: "ac_unit", color: "#13a7d4", name: "Blast Freezing" },
      { icon: "settings_overscan", color: "#EEF296", name: "ContainerLiners" },

      { icon: "pivot_table_chart", color: "#508D69", name: "Latches" },
      { icon: "settings_input_component", color: "#EA906C", name: "Ratches" },
      { icon: "filter_alt", color: "#EE12CA", name: "Ethylene Filters" },

      { icon: "video_stable", color: "#5F6F52", name: "Stabling bars" },
      { icon: "note", color: "#C5E898", name: "Kraft paper" },
      { icon: "backpack", color: "#DCBFFF", name: "Absorb Bags" },
      { icon: "emergency", color: "#FFBFBF", name: "SteriInsurance" },
      { icon: "smart_screen", color: "#FFBFFF", name: "SmartFresh" },
      { icon: "scale", color: "#000072", name: "HeavyLoad" },
      // { icon: "monitor_heart", color: "#F1DBBF", name: "Port Monitoring" },
    ],
    portMonitoringKey: 0,
    lateArrivalKey: 0,
    earlyArrivalKeyA: 0,
    blastFreezingKey: 0,
    voidPlugKey: 0,
    otflowKey: 0,
    zoonoKey: 0,
    industrialKey: 0,
    gensetToggleKey: 0,
    temporaryProductModal: false,
    bulkType: null,
    productServiceItem: {},
    productServiceModal: false,
    availableDevices: [
      {
        product: "TelemPlus",
        variation: "2G GEO",
        count: 0,
        monitoringService: "",
        checked: false,
      },
      {
        product: "TelemPlus",
        variation: "4G PROBE",
        count: 0,
        monitoringService: "",
        checked: false,
      },
      {
        product: "TelemPlus",
        variation: "STD PROBE",
        count: 0,
        monitoringService: "",
        checked: false,
      },
      // {
      //   product: "TelemPlus",
      //   variation: "3G GEO",
      //   count: 0,
      //   monitoringService: "",
      //   checked: false,
      // },
      {
        product: "TelemPlus",
        variation: "T4",
        count: 0,
        monitoringService: "",
        checked: false,
      },
      {
        product: "TelemPlus",
        variation: "RF",
        count: 0,
        monitoringService: "",
        checked: false,
      },
      {
        product: "TelemPlus",
        variation: "4G GEO",
        count: 0,
        monitoringService: "",
        checked: false,
      },
      // {
      //   product: "TelemPlus",
      //   variation: "GEO EAGLE",
      //   count: 0,
      //   monitoringService: "",
      //   checked: false,
      // },
      {
        product: "TelemPlus",
        variation: "MOST",
        count: 0,
        monitoringService: "",
        checked: false,
      },
      {
        product: "TelemPlus",
        variation: "2G TIVE",
        count: 0,
        monitoringService: "",
        checked: false,
      },
      {
        product: "TelemPlus",
        variation: "5G TIVE",
        count: 0,
        monitoringService: "",
        checked: false,
      },
      {
        product: "TelemPlus",
        variation: "Escavox",
        count: 0,
        monitoringService: "",
        checked: false,
      },
      {
        product: "Dunnage Bags",
        variation: "Standard",
        count: 0,
        // monitoringService: "",
        checked: false,
      },
      {
        product: "Blast Freezing",
        variation: "Standard",
        count: 0,
        // monitoringService: "",
        checked: false,
      },
      {
        product: "Kraft paper",
        variation: "Standard",
        count: 0,
        // monitoringService: "",
        checked: false,
      },
      {
        product: "Absorb Bags",
        variation: "Standard",
        count: 0,
        // monitoringService: "",
        checked: false,
      },
      {
        product: "Stabling bars",
        variation: "Standard",
        count: 0,
        // monitoringService: "",
        checked: false,
      },
      {
        product: "Latches",
        variation: "Standard",
        count: 0,
        // monitoringService: "",
        checked: false,
      },
      {
        product: "Ratches",
        variation: "Standard",
        count: 0,
        // monitoringService: "",
        checked: false,
      },
      {
        product: "Ethylene Filters",
        variation: "Standard",
        count: 0,
        checked: false,
      },
    ],
    settingShipmentProfile: false,
    specialInstructionsModal: false,
    availableProducts: {
      Genset: false,
      Otflow: false,
      Zoono: false,
      // Dunnage_Bags: false,
      Void_Plugs: false,
      Late_Arrival: false,
      Early_Arrival: false,
      Fumigation: false,
      monitoringService: null,
      ContainerLiners: false,
      SteriInsurance: false,
      SmartFresh: false,
      HeavyLoad: false,
    },
    timeOptions: [],
    lateDescription: null,
    earlyDescription: null,
    saveProducts: false,
    addServiceModal: false,
    larKey: 200,
    earlyArrivalKey: 8000,
    larFormModal: false,
    transportStatuses: [
      {
        text: "Pending",
        value: "PENDING",
        color: "orange",
      },
      // {
      //     text: 'Booking Confirmed',
      //     value: 'BOOKING_CONFIRMED',
      //     color: 'green lighten-1'
      // },
      {
        text: "Pre-Allocated",
        value: "PRE_ALLOCATED",
        color: "green darken-2",
      },
      {
        text: "Allocated",
        value: "ALLOCATED",
        color: "lime darken-3",
      },
      {
        text: "Instr. Sent",
        value: "INSTRUCTION_SENT",
        color: "blue-grey",
      },
      {
        text: "Empty Out",
        value: "EMPTY_PICKUP",
        color: "teal",
      },
      {
        text: "En route LP-1",
        value: "EN_ROUTE_LP1",
        color: "cyan darken-2",
      },
      {
        text: "At LP-1",
        value: "AT_LP1",
        color: "cyan darken-4",
      },
      {
        text: "En route LP-2",
        value: "EN_ROUTE_LP2",
        color: "purple darken-2",
      },
      {
        text: "At LP-2",
        value: "AT_LP2",
        color: "purple darken-4",
      },
      {
        text: "En route WB",
        value: "EN_ROUTE_WB",
        color: "blue",
      },
      {
        text: "AT WB",
        value: "AT_WB",
        color: "blue lighten-1",
      },
      {
        text: "En route Depot",
        value: "EN_ROUTE_DEPOT",
        color: "blue darken-4",
      },
      {
        text: "At Depot",
        value: "AT_DEPOT",
        color: "blue darken-5",
      },
      {
        text: "En route Port",
        value: "EN_ROUTE_PORT",
        color: "blue darken-1",
      },
      {
        text: "At Port",
        value: "AT_PORT",
        color: "blue darken-2",
      },
      {
        text: "Gate In",
        value: "FULL_DROP_OFF",
        color: "success",
      },
    ],
    rules: {
      containerNo(value) {
        if (/\b[A-Z]{4}\d{7}\b/.test(value)) {
          return true;
        }
        return "Container number needs to be in the format: ABCD1234567";
      },
    },
    selectableParties: [],
    params: {
      search: "",
      filter: {},
    },
    ventSettings: [
      "Closed",
      "5",
      "10",
      "15",
      "20",
      "25",
      "28",
      "30",
      "35",
      "40",
      "50",
      "Fully Open",
      "CA",
      "MA",
      "Auto",
    ],
    activeSources: [],
    availableSources: [],
    dataSourceChange: false,
    dataSourceKey: 4000,
    savingDataSource: false,
    selectedFeatures: {},
    sourceItem: {},
    sourceModal: false,
    loadingDataSource: false,
    earlyArrivalModal: false,
  }),
  async created() {
    this.timeOptions = this.getTimeOptions();
    this.containerFeatures = await this.$API.getContainerFeatures()
  },
  watch: {
    "container.pluggedInRequired": function (newVal) {
    if(this.container){
      if (newVal) {
        this.container.transportStatus = "Arrange Plugged In";
      } else {
        this.container.transportStatus = "Unallocated";
      }
    }
    },
    "container.id": {
      immediate: true,
      handler(val) {
        if (val) {
          this.getVentSettings();
          this.getContainerDetails();
          this.getContainerComments(val);
          this.getAvailableSources();
         
        }
      },
    },
  },
  mounted() {
    this.setSelectableParties();
  },
  computed: {
    filterContainerTypes(){
      if(this.booking && this.booking.modeOfTransport == 'AIR'){
        return this.containerTypes.filter(x=>x.level == 'air')
      } else {
        return this.containerTypes.filter(x=>!x.level || x.level != 'air')
      }
    },
    title() {
      if (this.container.containerNo) {
        return `${this.container.ctoNo} (${this.container.containerNo})`;
      }
      return this.container.ctoNo;
    },
    hauliers() {
      let result = [
        // {
        //     index: 1,
        //     type: 'Organisation',
        //     name: this.$store.state.currentOrg.name,
        //     logo: this.$store.state.currentOrg.logo,
        //     id: this.$store.state.currentOrg.id,
        //     icon: null,
        // },
        {
          index: 2,
          type: "Carrier Haulage",
          name: "Carrier Haulage",
          logo: null,
          icon: "directions_boat",
          flag: "isCarrierHaulage",
        },
        {
          index: 4,
          type: "Merchant Haulage",
          name: "Merchant Haulage",
          icon: "local_shipping",
          logo: null,
          flag: "isPrivateHaulage",
        },
        {
          index: 3,
          type: "Railage",
          name: "Rail",
          icon: "train",
          logo: null,
          flag: "isRailage",
        },
      ];
      // if (this.booking.shippingLine) {
      //     result[1].name = this.booking.shippingLine.name
      //     result[1].logo = this.booking.shippingLine.logo
      // }
      return result;
    },
    hasProductsCount() {
      return Object.values(this.availableProducts).some((x) => x === true);
    },
    hasDevicesWithCount() {
      return this.availableDevices.some((device) => device.count > 0);
    },
    hasDevices() {
      let devices = this.availableDevices.filter((device) => device.count > 0);
      return devices;
    },
    hasProducts() {
      let finalProducts = [];
      let products = Object.keys(this.availableProducts).filter(
        (x) => this.availableProducts[x] === true
      );
      products = products.map((x) => x.replace(/_/g, " "));
      products.forEach((product) => {
        let productObject = {
          product: product,
          selected: true,
        };
        finalProducts.push(productObject);
      });
      return finalProducts;
    },
    uniqueProducts() {
      const unique = new Set();
      const result = [];
      for (const device of this.availableDevices) {
        // console.log('device',device)
        if (!unique.has(device.product)) {
          unique.add(device);
          result.push(device);
        }
      }
      return result;
    },
  },
  methods: {
    openDialog(comment) {
      this.loadingEditComment = false;
      this.newEditedComment = comment;
      console.log(comment);
      this.editButton = true;
    },
    closeEditDialog() {
      this.editButton = false;
    },
    getTimeOptions() {
      let start = new Date();
      start.setHours(0, 0, 0, 0);
      let end = new Date();
      end.setHours(23, 45, 0, 0);
      let timeOptions = [];
      while (start <= end) {
        let hour = start.getHours();
        let minute = start.getMinutes();
        if (hour < 10) {
          hour = "0" + hour;
        }
        if (minute < 10) {
          minute = "0" + minute;
        }
        timeOptions.push(hour + ":" + minute);
        start.setMinutes(start.getMinutes() + 15);
      }
      return timeOptions;
    },
    toggleCheckbox(product) {
      product.count = product.checked ? 1 : 0;
    },
    addServices() {
      this.addServiceModal = true;
    },
    updateProductCount(product) {
      //check the devices for the product and varaition
      let count = parseInt(product.count);
      this.availableDevices.forEach((x) => {
        if (x.product == product.name && x.variation == product.variation) {
          x.count = count;
        }
        if (x.count < 1) {
          x.checked = 0;
        }
      });
    },
    saveTemporaryProducts() {
      this.saveProducts = true;
      let newTemporaryProducts = [];
      this.availableDevices.forEach((device) => {
        if (device.count > 0) {
          // let variation = device.variation.split(' ')[0]
          newTemporaryProducts.push({
            product: device.product,
            variation: device.variation,
            count: device.count,
            //check if this.availableProducts.monitoringService is not null. availableProducts is an object
            monitoringService: this.availableProducts.monitoringService
              ? this.availableProducts.monitoringService
              : "",
          });
        }
      });

      Object.keys(this.availableProducts).forEach((product) => {
        if (this.availableProducts[product] === true) {
          product = product.replace(/_/g, " ");
          newTemporaryProducts.push({
            product: product,
            variation: "Standard",
            count: 1,
            monitoringService: "",
          });
        }
      });
      // console.log('this.availableProducts.monitoringService',this.availableProducts.monitoringService)
      // if(this.availableProducts.monitoringService == 'Port Monitoring'){
      //   newTemporaryProducts.push({
      //     product: 'Port Monitoring',
      //     variation: 'Standard',
      //     count: 1,
      //     monitoringService: this.availableProducts.monitoringService
      //   })
      // }
      let monitoringService = this.availableProducts.monitoringService;

      if (monitoringService) {
        newTemporaryProducts.push({
          product: "Monitoring Service",
          variation: monitoringService,
          count: 1,
          monitoringService: monitoringService,
        });
      }
      if (
        newTemporaryProducts.find(
          (product) => product.product === "Late Arrival"
        )
      ) {
        newTemporaryProducts.find(
          (product) => product.product === "Late Arrival"
        ).variation = this.lateDescription;
      }

      if (
        newTemporaryProducts.find(
          (product) => product.product === "Early Arrival"
        )
      ) {
        newTemporaryProducts.find(
          (product) => product.product === "Early Arrival"
        ).variation = this.earlyDescription;
      }
      this.container.temporaryProducts = [...newTemporaryProducts];
      this.saveProducts = false;
      this.temporaryProductModal = false;
    },
    updateAvailableDevices(temporaryProducts) {
      temporaryProducts.forEach((tempProduct) => {
        const devices = this.availableDevices.find(
          (device) =>
            device.product === tempProduct.product &&
            device.variation === tempProduct.variation
        );

        if (devices) {
          devices.count = tempProduct.count;
          devices.checked = true;
          devices.monitoringService = tempProduct.monitoringService;
        }

        if (tempProduct.monitoringService) {
          this.availableProducts.monitoringService =
            tempProduct.monitoringService;
        }
      });
    },
    
    addMilestone(item, type = null, autosave = true) {
      if (type && item[type]) {
        this.milestoneItem = item[type];
        this.milestoneItem.autosave = autosave;
        this.milestoneContainer = item;
      } else {
        this.milestoneItem = {
          type,
          bookingContainerId: item.id,
          pol: this.booking.portOfLoadValue,
          // date: new Date(),
          autosave,
          isActual: false,
        };
        this.milestoneContainer = item;
      }

      this.addMilestoneModal = true;
      this.$nextTick(() => {
        this.milestoneKey++;
      });
    },
    addContainerProductService() {
      if (!this.container.productServiceSales) {
        this.container.productServiceSales = [];
      }
      this.productServiceItem = {
        index: this.container.productServiceSales.length,
        level: "CONTAINER",
      };
      // this.containerProductServiceModal = true;
      this.productKey ++
      this.productServiceModal = true;
    },
    addContainerProduct(item) {
      this.item = item;
      this.item.selectedProduct = {};
      let findContainerType = this.containerTypes.find(
        (x) => x.typeCode == this.container.containerTypeCode
      );
      if (findContainerType) {
        this.item.selectedProduct.volume = findContainerType.teu * 25;
      }

      this.containerProductModal = true;
    },
    async larForm() {
      try {
        this.larFormModal = true;
      } catch (e) {
        this.$message.error("An error ocurred", e);
      }
    },
    async earlyArrivalForm() {
      try {
        this.earlyArrivalModal = true;
      } catch (e) {
        this.$message.error("An error ocurred", e);
      }
    },
    async generateLAR(id) {
      try {
        this.loadingLAR = true;
        let obj = {
          containerId: id,
          reasonForLAR: this.reasonForLAR ? this.reasonForLAR : "",
          arrivalDate: this.arrivalDate
            ? this.arrivalDate + " " + this.arrivalTime
            : "",
          containerSize: this.containerSize,
          containerType: this.containerType,
          // movementType:this.container
        };
        let result = await this.$API.generateLAR(obj);
        this.container.larDocument = result;
        this.container.larDocumenId = result.id;

        this.larKey++;
        this.larFormModal = false;
        this.loadingLAR = false;
      } catch (e) {
        this.$message.error("An error ocurred", e);
        this.loadingLAR = false;
      }
    },
    async generateEarlyArrival(id) {
      try {
        this.loadingEAR = true;
        let obj = {
          containerId: id,
          reasonForEAR: this.reasonForEarlyArrival
            ? this.reasonForEarlyArrival
            : "",
          arrivalDate: this.arrivalDate
            ? this.arrivalDate + " " + this.arrivalTime
            : "",
          containerSize: this.containerSize,
          containerType: this.containerType,
        };
        let result = await this.$API.generateEarlyArrival(obj);
        this.container.earlyArrivalDocument = result;
        this.container.earlyArrivalDocumentId = result.id;

        this.earlyArrivalKey++;
        this.earlyArrivalModal = false;
        this.loadingEAR = false;
      } catch (e) {
        this.$message.error("An error ocurred", e);
        this.loadingLAR = false;
      }
    },

    async generatePackingList(id) {
      try {
        this.loadingPackingList = true;
        // console.log('packing list container', id);
        let obj = {
          containerIds: [id],
          movementType: this.booking.movementType,
          specialInstructions: this.specialInstructions,
        };
        // console.log('obj', obj)
        let result = await this.$API.generatePackingList(obj);

        // console.log(result);

        if (result && result.length === 1) {
          this.container.packingListDocument.url = result[0].url;
          this.container.packingListDocument.name = result[0].name;
          this.container.packingListDocument.version++;
          this.container.packingListDocumentId = result[0].id;
        }

        this.specialInstructions = "";
        this.loadingPackingList = false;
        this.packingListKey++;
      } catch (e) {
        this.$message.error("An error ocurred :(");
        this.loadingCto = false;
      }
    },
    async generateCTO(id) {
      try {
        console.log("contauiner", this.booking);
        this.loadingCto = true;
        let obj = {
          containerId: id,
          movementType: this.booking.movementType,
        };
        let result = await this.$API.generateCTO(obj);
        if (result.error) {
          this.$message.error(result.error);
          this.loadingCto = false;
          this.ctoKey++;

          return;
        } else {
          this.container.ctoDocument = result;
          this.container.ctoDocumentId = result.id;
          this.loadingCto = false;
          this.ctoKey++;
        }
      } catch (e) {
        this.$message.error("An error ocurred :(");
        this.loadingCto = false;
      }
    },
    editContainerProduct(item, product) {
      this.item = item;
      this.item.selectedProduct = product;
      this.containerProductModal = true;
    },
    async getContainerDetails() {
      this.loadingDetails = true;
      let body = {
        regimeCode: this.booking.regimeCode,
        containerId: this.container.id,
        bookingId: this.booking.id,
        regimeId: this.booking.regimeId,
      };
      let result = await this.$API.getContainer(body.containerId);
      if (result) {
        this.regime = result.regime;
        this.milestones = result.containerMilestones;
        this.legs = result.legs ?? [];
        if (this.legs.length > 0) {
          this.center = this.legs[0].start;
          let findLoad = this.legs.findIndex(
            (leg) => leg.start.type == "LOADING_POINT_1"
          );
          let endLoad = this.legs.findIndex(
            (leg) => leg.start.type == "FULL_DROP_OFF"
          );
          if (findLoad > -1 && endLoad > -1) {
            let loadedLegs = this.legs.slice(findLoad, endLoad + 1);
            this.loadedDistance = 0;
            loadedLegs.forEach((leg) => {
              this.loadedDistance += parseFloat(leg.distance);
            });
            this.loadedDistance = this.loadedDistance.toFixed(2);
          }
        }
      }
          if(result.containerFeatures){
            for(let feature of result.containerFeatures){
              this.selectedFeatures[feature.value] = true
            }
          }
      this.key++;
      this.loadingDetails = false;
    },
    async getContainerComments(containerId) {
      this.loadingComments = true;
      let result = await this.$API.getContainerComments(containerId);
      if (result) {
        this.containerComments = result;

        this.loadingComments = false;
        this.$nextTick(() => {
          let objDiv = document.getElementById("messagesContainer");
          if (objDiv) {
            objDiv.scrollTop = objDiv.scrollHeight;
          }
        });
      }
    },
    async getVentSettings() {
      if (this.booking.regimeId) {
        let ventSettings = await this.$API.getRegimeVent(this.booking.regimeId);
        if (ventSettings.length > 0) {
          this.ventSettings = [
            "Closed",
            "5",
            "10",
            "15",
            "20",
            "25",
            "28",
            "30",
            "35",
            "40",
            "50",
            "Fully Open",
            "CA",
            "MA",
            "Auto",
          ].filter((x) => ventSettings.map((y) => y.ventCode).includes(x));
        } else {
          this.ventSettings = [
            "Closed",
            "5",
            "10",
            "15",
            "20",
            "25",
            "28",
            "30",
            "35",
            "40",
            "50",
            "Fully Open",
            "CA",
            "MA",
            "Auto",
          ];
        }
      } else {
        this.ventSettings = [
          "Closed",
          "5",
          "10",
          "15",
          "20",
          "25",
          "28",
          "30",
          "35",
          "40",
          "50",
          "Fully Open",
          "CA",
          "MA",
          "Auto",
        ];
      }
    },
    async deleteContainerComment(id) {
      this.loadingDeleteComment = true;
      let result = await this.$API.deleteContainerComment({
        id: id,
        isDeleted: true,
        isActive: false,
        bookingContainerId: this.container.id,
      });

      if (result) {
        this.loadingDeleteComment = false;
        this.getContainerComments(this.container.id);
        this.containerComments = result;
      }
    },
    async updateContainerComment(id) {
      this.loadingEditComment = true;
      let result = await this.$API.updateContainerComment({
        id: id,
        isDeleted: true,
        isActive: false,
        bookingContainerId: this.container.id,
        organisationId: this.newEditedComment.organisationId,
        userId: this.newEditedComment.userId,
        comment: this.newEditedComment.comment,
      });

      if (result) {
        this.loadingEditComment = false;
        this.containerComments = result;
        this.closeEditDialog();
        this.getContainerComments(this.container.id);
      }
    },
    async commentContainer() {
      if (this.containerChatArea != "") {
        this.containerChatArea.trim();
        this.chatContainerUpdateKey++;
        // check if last 2 characters are not spaces or new line characters
        this.sendingMessage = true;
        let obj = {
          comment: this.containerChatArea,
          bookingContainerId: this.container.id,
          user: this.$store.state.user,
        };
        let result = await this.$API.commentOnContainer(obj);
        if (result) {
          result.user = this.$store.state.user;
          this.containerComments.push(result);
          this.containerChatArea = "";
          this.sendingMessage = false;
        } else {
          this.$message({
            type: "error",
            message: `Message could not go through. Please check your connection and try again.`,
          });
        }
      }
    },
    formatDate(date) {
      let isoFormat = null;
      let fullDate = null;
      let dateTime = null;
      let dayOfWeek = null;
      let shorterDate = null;
      let time = null;
      if (date) {
        isoFormat = dateFormat(new Date(date), "dd-mm-yyyy");
        fullDate = dateFormat(new Date(date), "dddd, mmmm dS, yyyy");
        dateTime = dateFormat(new Date(date), "dddd, mmmm dS, yyyy HH:MM");
        time = dateFormat(new Date(date), "HH:MM");
        dayOfWeek = dateFormat(new Date(date), "ddd");
        shorterDate = dateFormat(new Date(date), "mmm dS, yyyy");
      }
      return { isoFormat, fullDate, dateTime, dayOfWeek, shorterDate, time };
    },
    formatDateTime(date, time) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "dd-mm-yyyy");
        if (time) {
          result += " " + time;
        }
      }
      return result;
    },
    milestoneType(type) {
      let find = this.milestoneTypes.find((x) => x.value == type);
      return find ? find.name : null;
    },
    checkContainer(value) {
      const check = isContainer();
      if(check.isValid(value)){
        return true
      }
    },
    async downloadDocument(doc) {
      var hiddenElement = document.createElement("a");
      hiddenElement.href = doc.url;
      hiddenElement.target = "_blank";
      hiddenElement.download = doc.name;
      hiddenElement.click();
    },
    async getContractors() {
      this.loadingContractors = true;
      let result = await this.$API.getContractors();
      if (result) {
        this.contractors = result;
        this.loadingContractors = false;
      }
    },
    getStatusColor(status) {
      let find = this.transportStatuses.find((x) => x.text == status);
      return find ? find.color : "grey";
    },
    containerStatusColor(item) {
      if (item.isDeleted) {
        return "red";
      } else if (item.bookingId && item.bookingId != this.booking.id) {
        return "blue";
      } else if (item.status) {
        let find = this.containerStatus.find(
          (x) => x.name &&x.name.toLowerCase() == item.status.toLowerCase()
        );
        if (find) {
          return find.color;
        } else {
          return "orange darken-2";
        }
      } else {
        return "orange darken-2";
      }
    },
    matchColor(itemStatus) {
      let hex = "";
      let result = this.statuses.find((status) => status.state === itemStatus);
      if (result) {
        hex = result.color;
      }
      return hex;
    },
    closeDialog() {
      this.change = false;
      this.milestones = [];
      this.legs = [];
      this.regime = null;
      (this.availableDevices = [
        {
          product: "TelemPlus",
          variation: "Escavox",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        {
          product: "TelemPlus",
          variation: "2G GEO",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        {
          product: "TelemPlus",
          variation: "4G PROBE",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        {
          product: "TelemPlus",
          variation: "STD PROBE",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        // {
        //   product: "TelemPlus",
        //   variation: "3G GEO",
        //   count: 0,
        //   monitoringService: "",
        //   checked: false,
        // },
        {
          product: "TelemPlus",
          variation: "T4",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        {
          product: "TelemPlus",
          variation: "RF",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        {
          product: "TelemPlus",
          variation: "4G GEO",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        // {
        //   product: "TelemPlus",
        //   variation: "GEO EAGLE",
        //   count: 0,
        //   monitoringService: "",
        //   checked: false,
        // },
        {
          product: "TelemPlus",
          variation: "MOST",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        {
          product: "TelemPlus",
          variation: "2G TIVE",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        {
          product: "TelemPlus",
          variation: "5G TIVE",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        {
          product: "Dunnage Bags",
          variation: "Standard",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        {
          product: "Blast Freezing",
          variation: "Standard",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        {
          product: "Kraft paper",
          variation: "Standard",
          count: 0,
          // monitoringService: "",
          checked: false,
        },
        {
          product: "Absorb Bags",
          variation: "Standard",
          count: 0,
          // monitoringService: "",
          checked: false,
        },
        {
          product: "Stabling bars",
          variation: "Standard",
          count: 0,
          // monitoringService: "",
          checked: false,
        },
        {
          product: "Latches",
          variation: "Standard",
          count: 0,
          // monitoringService: "",
          checked: false,
        },
        {
          product: "Ratches",
          variation: "Standard",
          count: 0,
          // monitoringService: "",
          checked: false,
        },
        {
          product: "Ethylene Filters",
          variation: "Standard",
          count: 0,
          checked: false,
        },
      ]),
        (this.availableProducts = {
          Genset: false,
          Otflow: false,
          Zoono: false,
          // Dunnage_Bags: false,
          Void_Plugs: false,
          Late_Arrival: false,
          Early_Arrival: false,
          Fumigation: false,
          ContainerLiners: false,
          monitoringService: null,
          SteriInsurance: false,
          SmartFresh: false,
          HeavyLoad: false,
        }),
        this.$emit("close");
    },
    setChange() {
      this.change = true;
    },
    async updateContainer() {
      this.savingChange = true;
      if (this.dataSourceChange) {
        await this.updateDataSource();
      }
      let status = this.container.transportStatus;
      await this.$API.updateContainer(this.container);
      this.$message.success("Successfully updated!");
      this.savingChange = false;
      (this.availableDevices = [
        {
          product: "TelemPlus",
          variation: "Escavox",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        {
          product: "TelemPlus",
          variation: "2G GEO",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        {
          product: "TelemPlus",
          variation: "4G PROBE",
          count: 0,
          // monitoringService: "",
          checked: false,
        },
        {
          product: "TelemPlus",
          variation: "STD PROBE",
          count: 0,
          // monitoringService: "",
          checked: false,
        },
        // {
        //   product: "TelemPlus",
        //   variation: "3G GEO",
        //   count: 0,
        //   monitoringService: "",
        //   checked: false,
        // },
        {
          product: "TelemPlus",
          variation: "T4",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        {
          product: "TelemPlus",
          variation: "RF",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        {
          product: "TelemPlus",
          variation: "4G GEO",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        // {
        //   product: "TelemPlus",
        //   variation: "GEO EAGLE",
        //   count: 0,
        //   monitoringService: "",
        //   checked: false,
        // },
        {
          product: "TelemPlus",
          variation: "MOST",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        {
          product: "TelemPlus",
          variation: "2G TIVE",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        {
          product: "TelemPlus",
          variation: "5G TIVE",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        {
          product: "Dunnage Bags",
          variation: "Standard",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        {
          product: "Blast Freezing",
          variation: "Standard",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        {
          product: "Kraft paper",
          variation: "Standard",
          count: 0,
          // monitoringService: "",
          checked: false,
        },
        {
          product: "Absorb Bags",
          variation: "Standard",
          count: 0,
          // monitoringService: "",
          checked: false,
        },
        {
          product: "Stabling bars",
          variation: "Standard",
          count: 0,
          // monitoringService: "",
          checked: false,
        },
        {
          product: "Latches",
          variation: "Standard",
          count: 0,
          // monitoringService: "",
          checked: false,
        },
        {
          product: "Ratches",
          variation: "Standard",
          count: 0,
          // monitoringService: "",
          checked: false,
        },
      ]),
        (this.availableProducts = {
          Genset: false,
          Otflow: false,
          Zoono: false,
          // Dunnage_Bags: false,
          Void_Plugs: false,
          Late_Arrival: false,
          Early_Arrival: false,
          Fumigation: false,
          monitoringService: null,
          ContainerLiners: false,
          SteriInsurance: false,
          SmartFresh: false,
          HeavyLoad: false,
        }),
        this.closeDialog();
    },
    setSelectableParties() {
      let keys = [];
      if (this.booking.movementType == "EXPORT") {
        keys = [
          "forwarder",
          "shipper",
          "firstNotifyParty",
          "secondNotifyParty",
        ];
      } else {
        keys = [
          "forwarder",
          "consignee",
          "shipper",
          "buyer",
          "firstNotifyParty",
          "secondNotifyParty",
        ];
      }
      let result = [];
      for (let i = 0; i < keys.length; i++) {
        if (this.booking[keys[i] + "Id"]) {
          result.push(this.booking[keys[i]]);
        }
      }
      if (this.booking.customerId) {
        let findCustomer = result.find((x) => x.id == this.booking.customerId);
        if (!findCustomer) {
          result.unshift(this.booking.customer);
        }
      }
      let findCurrentOrg = result.find(
        (x) => x.id == this.$store.state.currentOrg.id
      );
      if (!findCurrentOrg) {
        result.unshift(this.$store.state.currentOrg);
      }
      this.selectableParties = result;
    },
    setTransporter() {
      if (this.container.transportCoordinator) {
        this.container.transportCoordinatorId =
          this.container.transportCoordinator.id;
      } else {
        this.container.transportCoordinatorId = null;
      }
      //   if (
      //     this.booking.transportCoordinatorId == this.$store.state.currentOrg.id &&
      //     this.booking.movementType == "EXPORT"
      //   ) {
      //     this.booking.preAdvise = true;
      //   }
    },
    milestoneUpdate(data) {
      this.milestoneContainer.containerMilestones = data;
      console.log("Milestones", data);
      let types = [
        {
          name: "Empty Pickup",
          eventType: "TRANSPORT",
          modeOfTransport: "TRUCK",
          value: "EMPTY_PICKUP",
          type: ["EXPORT"],
        },
        {
          name: "Full Pickup",
          eventType: "TRANSPORT",
          modeOfTransport: "TRUCK",
          value: "FULL_PICKUP",
          type: ["IMPORT"],
        },
        {
          name: "Stuff Date",
          eventType: "EQUIPMENT",
          modeOfTransport: "TRUCK",
          value: "STUFF_DATE",
          type: ["EXPORT"],
        },
        {
          name: "Loading Point 1",
          eventType: "TRANSPORT",
          modeOfTransport: "TRUCK",
          value: "LOADING_POINT_1",
          type: ["EXPORT"],
        },
        {
          name: "Loading Point 2",
          eventType: "TRANSPORT",
          modeOfTransport: "TRUCK",
          value: "LOADING_POINT_2",
          type: ["EXPORT"],
        },
        {
          name: "Delivery Point 1",
          eventType: "TRANSPORT",
          modeOfTransport: "TRUCK",
          value: "DELIVERY_POINT_1",
          type: ["IMPORT"],
        },
        {
          name: "Delivery Point 2",
          eventType: "TRANSPORT",
          modeOfTransport: "TRUCK",
          value: "DELIVERY_POINT_2",
          type: ["IMPORT"],
        },
        {
          name: "Delivery Point 3",
          eventType: "TRANSPORT",
          modeOfTransport: "TRUCK",
          value: "DELIVERY_POINT_3",
          type: ["IMPORT"],
        },
        {
          name: "Weigh Bridge",
          eventType: "TRANSPORT",
          modeOfTransport: "TRUCK",
          value: "WEIGH_BRIDGE",
          type: ["EXPORT"],
        },
        {
          name: "Plugged In",
          eventType: "TRANSPORT",
          modeOfTransport: "TRUCK",
          value: "PLUG_IN",
          type: ["EXPORT"],
        },
        {
          name: "Unplugged",
          eventType: "TRANSPORT",
          modeOfTransport: "TRUCK",
          value: "PLUG_OUT",
          type: ["EXPORT"],
        },
        {
          name: "Port Holding",
          eventType: "TRANSPORT",
          modeOfTransport: "TRUCK",
          value: "FULL_DROP_OFF",
          type: ["EXPORT"],
        },
        {
          name: "Drop Off Empty",
          eventType: "TRANSPORT",
          modeOfTransport: "TRUCK",
          value: "EMPTY_DROP_OFF",
          type: ["IMPORT"],
        },
      ];
      for (let point of types) {
        let find = data.find((x) => x.type == point.value);
        if (find) {
          this.container[point.value] = find;
        } else {
          this.container[point.value] = null;
        }
      }
      this.addMilestoneModal = false;
      this.milestoneKey++;
    },
    async deleteSelectedContainerProduct() {
      let find = this.item.containerProducts.findIndex(
        (x) => x.id == this.item.selectedProduct.id
      );
      if (find > -1) {
        this.item.containerProducts.splice(find, 1);
      }
      this.containerProductModal = false;
      this.$message.success("Successfully removed product!");
    },
    async updateSelectedContainerProduct(item) {
      this.containerProductModal = false;
      if (item.hazardous) {
        this.booking.isHazardous = true;
        this.bookingChange = true;
      } else {
        this.booking.isHazardous = this.booking.bookingContainers
          .filter(
            (x) =>
              !x.isDeleted &&
              (x.status == "Allocated" || x.status == null) &&
              x.bookingId == this.booking.id
          )
          .some((x) => x.containerProducts.some((y) => y.hazardous == true));
      }
      if (item.id) {
        await this.$API.updateContainerProduct(item);

        let findIndex = this.container.containerProducts.findIndex(
          (x) => x.id == item.id
        );
        this.container.containerProducts[findIndex] = item;
        //get all container.containerProducts.pallets and add together to get bookingContainer.totalPallets
        console.log(
          "container.containerProducts",
          this.container.containerProducts
        );
        let totalPallets = 0;
        this.container.containerProducts.forEach((product) => {
          totalPallets += parseInt(product.pallets);
        });
        this.container.totalPallets = totalPallets;
      } else {
        item = {
          ...item,
          bookingId: this.booking.id,
          bookingContainerId: this.item.id,
          productId: item.product.id,
        };
        let result = await this.$API.createContainerProduct(item);
        result = { ...result, ...item };
        this.container.containerProducts.push(result);
        // this.contain
      }
      this.item = null;
      this.$message.success("Successfully updated container product!");
      this.$emit("refreshContainers");
    },

    viewPackingList(item) {
      this.item = item;
      if (
        this.item.type &&
        (this.item.type.includes("image/") ||
          this.item.type.includes("application/pdf"))
      ) {
        this.imageLoading = true;
      }
      this.previewModal = true;
    },
    viewCTO(item) {
      this.item = item;

      if (
        this.item.type &&
        (this.item.type.includes("image/") ||
          this.item.type.includes("application/pdf"))
      ) {
        this.imageLoading = true;
      }
      this.previewModal = true;
    },
    setHaulier(haulier) {
      let types = ["isPrivateHaulage", "isCarrierHaulage", "isRailage"];
      if (haulier.flag) {
        let setFalse = types.filter((x) => x != haulier.flag);
        for (let i = 0; i < setFalse.length; i++) {
          this.container[setFalse[i]] = false;
        }
        this.container[haulier.flag] = true;
        this.container.transportCoordinator = null;
        this.container.transportCoordinatorId = null;
      } else {
        for (let i = 0; i < types.length; i++) {
          this.container[types[i]] = false;
        }
        this.container.transportCoordinator = haulier;
        this.container.transportCoordinatorId = haulier.id;
        // this.booking.preAdvise = true
      }
      this.haulierKey++;
      // this.haulierModal = false
    },
    updateContainerDescription() {
      let find = this.containerTypes.find(
        (x) => x.typeCode == this.container.containerTypeCode
      );
      if (find) {
        this.container.containerType = find.shortDescription;
      }
    },
    async updateContainerFeature(feature) {
      if(this.selectedFeatures[feature.value]){
        let features = await this.$API.updateContainerFeature(this.container.id, feature.value, {
          name: feature.name,
          value: feature.value,
          isActive: true, isDeleted: false
        })
        this.container.containerFeatures  = features
        this.$message.success("Successfully updated container feature!");
      } else {
        let features = await this.$API.updateContainerFeature(this.container.id, feature.value, {
          isActive: false, isDeleted: true
        })
        this.container.containerFeatures  = features
        this.$message.success("Successfully updated container feature!");
      }
    },
    // DATA SOURCES
    async updateDataSource() {
      try {
        this.savingDataSource = true;
        let active = this.availableSources.filter((x) => x.active);
        console.log(active);
        let obj = active.map((x) => ({
          source: x.type,
          type: x.type,
          id: x.id,
          sourceType: x.sourceType,
          contents:
            x.sourceType == "stockFileContainer"
              ? x.stockFileContainers[0].contents
              : x.contents,
        }));
        await this.$API.updateDocumentSource({
          bookingContainerId: this.container.id,
          source: obj,
        });
        this.dataSourceChange = false;
        this.savingDataSource = false;
        // this.$emit("refreshContainers");
        // this.load();
        this.$message.success("Successfully updated data source!");
      } catch (e) {
        console.log(e);
        this.$message({
          type: "error",
          message: "There was an error changing the document source.",
        });
        this.savingDataSource = false;
      }
      // });
    },
    checkDataSourceChanges() {
      this.dataSourceChange = false;
      for (let i = 0; i < this.availableSources.length; i++) {
        let find = this.activeSources.findIndex(
          (x) => x.stockFileId == this.availableSources[i].id
        );
        if (this.availableSources[i].active && find == -1) {
          this.dataSourceChange = true;
          break;
        } else if (!this.availableSources[i].active && find > -1) {
          this.dataSourceChange = true;
          break;
        }
      }
      this.dataSourceKey++;
    },
    async getAvailableSources() {
      this.loadingDataSource = true;
      let result = await this.$API.getAvailableStockSources(this.container.id);
      this.activeSources = await this.$API.getActiveStockSources(
        this.container.id
      );
      for (let i = 0; i < result.length; i++) {
        let find = this.activeSources.find(
          (x) => x.source == result[i].type && x.stockFileId == result[i].id
        );
        result[i].active = find ? true : false;
        if (result[i].sourceType == "stockFile") {
          if (typeof result[i].contents == "string") {
            let data = await axios.get(result[i].contents);
            if (data && data.data) {
              result[i].contents = data.data;
            }
          }
        } else if (result[i].sourceType == "stockFileContainer") {
          for (let j = 0; j < result[i].stockFileContainers.length; j++) {
            if (typeof result[i].stockFileContainers[j].contents == "string") {
              let data = await axios.get(
                result[i].stockFileContainers[j].contents
              );
              if (data && data.data) {
                result[i].stockFileContainers[j].contents = data.data;
              }
            }
          }
        }
      }
      this.availableSources = result;
      this.loadingDataSource = false;
    },
    setProductSelection(selection){
      if(this.productServiceItem.level == 'SHIPMENT'){
        this.booking.productServiceSales = selection
      } else if(this.productServiceItem.level == 'CONTAINER'){
        this.container.productServiceSales = selection
      }
    },
    async updatePalletWeights() {
      this.updatingPallets = true;
      let res = await this.$API.updatePalletWeights({
        id: this.container.id,
        vgmWeight: this.container.vgmWeight,
        tareWeight: this.container.tareWeight,
      });
      if (res && res.grossWeight) {
        this.container.grossWeight = res.grossWeight;
      }
      this.$message.success("Successfully updated weights!");
      this.updatingPallets = false;
    },
    // matchLocation(itemStatus) {
    //     let result = this.statusLocations.find(status => status.name === itemStatus).value
    //     return result
    // },
  },
};
</script>
  
<style scoped>
.localClass .v-timeline-item__body {
  --timeline-right-slot: 27vw;
  min-width: var(--timeline-right-slot - 30%);
  max-width: var(--timeline-right-slot);
  width: var(--timeline-right-slot);
}

.localClass.v-timeline--dense ::v-deep .v-timeline-item__opposite {
  --timeline-left-slot: 9vw;
  display: inline-block;
  text-align: end;
  flex: none;
  width: var(--timeline-left-slot);
  min-width: var(--timeline-left-slot);
  max-width: var(--timeline-left-slot);
}

.v-application--is-ltr
  .localClass.v-timeline--dense:not(.v-timeline--reverse)::before,
.v-application--is-rtl .v-timeline--reverse.v-timeline--dense::before {
  left: calc(9vw + ((60px - 2px) / 2));
  width: 2px;
  height: 200%;
  right: auto;
}

.localClass ::v-deep .v-timeline-item__divider {
  min-width: 60px;
}

.map-marker::after {
  position: absolute;
  content: "";
  width: 0px;
  height: 0px;
  /* top: -5px; */
  bottom: -20px;
  left: 10px;
  border: 10px solid transparent;
  border-top: 17px solid black;
}
* {
  font-size: 12px;
  --darkLayer: var(--v-greyDarker-base) !important;
  --lighterLayer: var(--v-greyRaised-lighten1) !important;
}
.tabContent ::v-deep .v-tabs-bar__content {
  background: var(--darkLayer) !important;
}
.infoCards {
  background: var(--lighterLayer) !important ;
  contain: content;
  border-radius: 10px;
}
</style>